import * as React from 'react'
import { debounce } from 'ts-debounce'
import {
  ShareDataStore,
  SharedDataStoreContext,
  DataSignature,
  ShareDataSourceRecord,
  SharedDataSourceRecordSubscriber
} from './Context'
import { executeReducers } from './reducers'

interface Props {
  children?: React.ReactNode | React.ReactNodeArray
}

interface State {
  contextValue: ShareDataStore
}

export class SharedDataStoreProvider extends React.Component<Props, State> {
  public state: State = {
    contextValue: {}
  }

  private pendingState: State = {
    contextValue: {}
  }

  public render() {
    return (
      <SharedDataStoreContext.Provider
        value={{
          store: this.state.contextValue,
          subscribeToDataRecord: this.subscribeToDataRecord,
          getFromSignature: (signature) =>
            this.getRecordFromSignature(signature)?.data
        }}
      >
        {this.props.children}
      </SharedDataStoreContext.Provider>
    )
  }

  private getKeyFromSignature = (signature: DataSignature<any>) =>
    `${signature.type}/${signature.identifier}`

  private getRecordFromSignature = (signature: DataSignature<any>) =>
    this.pendingState.contextValue[this.getKeyFromSignature(signature)] ||
    this.state.contextValue[this.getKeyFromSignature(signature)]

  private subscribeToDataRecord = (signature: DataSignature<any>) => {
    const persistedRecord = this.getRecordFromSignature(signature)
    let record = persistedRecord
    if (!persistedRecord) {
      record = this.createRecordFromSignature(signature)
    }

    const subscription: SharedDataSourceRecordSubscriber = {
      unsubscribe: null
    }

    subscription.unsubscribe = this.unsubscribe.bind(this, record, subscription)
    record.subscribers.push(subscription)

    this.enqueueStateUpdate((state) => ({
      contextValue: {
        ...state.contextValue,
        [this.getKeyFromSignature(signature)]: record
      }
    }))

    // Newly generated
    if (record !== persistedRecord) {
      this.triggerDataLookup(record)
    }

    return subscription.unsubscribe
  }

  private unsubscribe(
    record: ShareDataSourceRecord<any>,
    subscription: SharedDataSourceRecordSubscriber
  ) {
    const stateRecord = this.getRecordFromSignature(record.dependencySignature)
    if (!stateRecord) {
      return
    }

    const stateSubscription = stateRecord.subscribers.find(
      (x) => x === subscription
    )

    const shouldPurge =
      stateSubscription && stateRecord.subscribers.length === 1

    this.enqueueStateUpdate(
      shouldPurge
        ? (state) => {
            const keys = Object.keys(state.contextValue)
            const contextValue = keys
              .filter(
                (key) =>
                  key !==
                  this.getKeyFromSignature(stateRecord.dependencySignature)
              )
              .reduce<ShareDataStore>((contextValue, key) => {
                contextValue[key] = state.contextValue[key]
                return contextValue
              }, {})
            return {
              contextValue
            }
          }
        : (state) => {
            const {
              subscribers: prevSubscribers,
              ...carriedRecord
            } = stateRecord
            return {
              contextValue: {
                ...state.contextValue,
                [this.getKeyFromSignature(stateRecord.dependencySignature)]: {
                  ...carriedRecord,
                  subscribers: prevSubscribers.filter(
                    (x) => x !== stateSubscription
                  )
                }
              }
            }
          }
    )
  }

  private createRecordFromSignature = (
    signature: DataSignature<any>
  ): ShareDataSourceRecord<any> => {
    return {
      dependencySignature: signature,
      data: null,
      subscribers: []
    }
  }

  private enqueueStateUpdate = (reducer: (state: State) => State) => {
    this.pendingState = reducer({ ...this.state, ...this.pendingState })
    this.flushPendingState()
  }

  private flushPendingState = debounce(
    () => {
      this.setState(
        this.pendingState,
        () => (this.pendingState = { contextValue: this.state.contextValue })
      )
    },
    300,
    { maxWait: 2000 }
  )

  private async triggerDataLookup(record: ShareDataSourceRecord<any>) {
    try {
      const data = await executeReducers(record)

      const stateRecord = this.getRecordFromSignature(
        record.dependencySignature
      )
      if (!stateRecord) {
        // ignore no update required
        return
      }

      this.enqueueStateUpdate((state) => {
        return {
          contextValue: {
            ...state.contextValue,
            [this.getKeyFromSignature(stateRecord.dependencySignature)]: {
              ...stateRecord,
              data
            }
          }
        }
      })
    } catch (e) {
      console.error(e)
    }
  }
}
