import * as React from 'react'
import { Route, HashRouter, Switch } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { InspectionsContextProvider } from './providers/Inspections/Provider'
import { CollectionsContextProvider } from './providers/Collections/Provider'
import { SharedDataStoreProvider } from './providers/SharedDataStore/Provider'
import { InspectionFullPage } from './pages/InspectionFullPage'
import { PrintFullPage } from './pages/PrintFullPage'
import { CollectionPage } from './pages/CollectionPage'
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import ExportPage from './pages/ExportPage'

const DashboardAsync = React.lazy(() => import('./pages/DashboardPage'))

const App: React.FC = () => (
  <DndProvider
    backend={TouchBackend}
    options={{ enableMouseEvents: true, enableHoverOutsideTarget: true }}
  >
    <SharedDataStoreProvider>
      <CollectionsContextProvider>
        <InspectionsContextProvider>
          <HashRouter>
            <React.Suspense fallback={<LoadingOutlined />}>
              <Switch>
                <Route path="/" exact component={DashboardAsync} />
                <Route path="/dashboard" component={DashboardAsync} />
                <Route path="/inspections/:id" component={InspectionFullPage} />
                <Route path="/print/inspection/:id" component={PrintFullPage} />
                <Route
                  path="/collection/view/:collectionId"
                  component={CollectionPage}
                />
                <Route path="/export" exact component={ExportPage} />
              </Switch>
            </React.Suspense>
          </HashRouter>
        </InspectionsContextProvider>
      </CollectionsContextProvider>
    </SharedDataStoreProvider>
  </DndProvider>
)

export default App
