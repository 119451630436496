import * as React from 'react'

const initialTitle = document.title

export function usePageTitleEffect(title: string, replace?: boolean) {
  React.useEffect(() => {
    document.title = replace ? title : `${initialTitle}: ${title}`
    return () => {
      document.title = initialTitle
    }
  })
}
