import { Query } from '@/database/interface'
import { Collection } from '@/models/Collection'
import * as React from 'react'

export interface ExpandedCollection {
  collection: Collection
}

export interface StatefulCollectionsContext {
  loading: boolean
  collections?: ExpandedCollection[]
}

export interface CollectionsContext extends StatefulCollectionsContext {
  create(collection: Omit<Collection, '_id' | 'archivedOn' | 'createdOn'>): void
  populate(query?: Query): void
  remove(collection: Collection): void
  import(collections: Collection[]): void
}

export const CollectionsContext = React.createContext<CollectionsContext>(null)
