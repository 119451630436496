import * as React from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { InspectionLoader } from '@/components/Inspection/InspectionLoader'
import { MissingPiecesLoader } from '../components/Inspection/MissingPiecesLoader'
import { MissingPiecesList } from '../components/Inspection/MissingPiecesList'
import { printInspection } from '@/services/analytics'
import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons'
import { FullPage } from '@/components/layouts/FullPage'
import { usePageTitleEffect } from '@/components/usePageTitleEffect'
import { Inspection, SetRecord } from '@/models'

interface InspectionRouteMatch {
  id: string
}

function print() {
  window.print()
  printInspection()
}

const MissingPiecePage: React.FC<{
  Inspection?: Inspection
  Set?: SetRecord
}> = ({ Set, Inspection }) => {
  usePageTitleEffect(`${Set.set_number} ${Set.name}`, true)
  return (
    <MissingPiecesLoader
      Set={Set}
      Inspection={Inspection}
      render={(props) => {
        return <MissingPiecesList {...props} />
      }}
    ></MissingPiecesLoader>
  )
}

export function PrintFullPage(
  props: RouteComponentProps<InspectionRouteMatch>
) {
  const history = useHistory()

  const id = props.match.params.id
  return (
    <FullPage
      menuItems={[
        history.length && (
          <a onClick={() => history.goBack()}>
            <ArrowLeftOutlined />
            Back
          </a>
        ),
        <a onClick={() => print()} type="ghost">
          <PrinterOutlined /> Print
        </a>
      ].filter((x) => !!x)}
    >
      <div style={{ width: '100%', minHeight: 'calc(100vh - 64px)' }}>
        <InspectionLoader
          id={id}
          render={(props) => <MissingPiecePage {...props} />}
        />
      </div>
    </FullPage>
  )
}
