import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Inspection } from '@/models/Inspection'
import {
  InspectionInventory,
  InspectionInventoryStatus
} from '@/models/InspectionInventory'
import SetPieceService, { SetPieceOperations } from '@/services/set_pieces'
import { SetPiece } from '@/models'
import { executeFilterSort, SortMethods } from './inventoryQueueFilterSort'
import { getActiveInventoryIdFromRoute } from './inventoryItemUtils'
import { InventoryList } from './InventoryList'
import { InventoryItemDetails } from './InventoryItemDetails'
import { SortItemProps, defaultSortOrder } from './SortDrawer'
import { InventoryQueueMenu } from './InventoryQueueMenu'
import { InspectionInventoryLoader } from '@/loaders/InspectionInventoryLoader'

export interface InventoryQueueProps extends RouteComponentProps {
  ActiveQueue: InspectionInventoryStatus
  Inspection: Inspection
  Inventory: InspectionInventory[]
}

export interface InventoryQueueState {
  FilterPaneOpen: boolean
  SortPaneOpen: boolean
  ColorIds: number[]
  colorFilters: number[]
  setPieces: SetPiece[]
  nameFilter: string
  list: InspectionInventory[]
  hiddenItems?: number
  filteredList: InspectionInventory[]
  sortOrder: SortItemProps[]
}

function filterForQueue(
  activeQueue: InspectionInventoryStatus,
  sortOption: SortItemProps
) {
  const isUnprovisioned = activeQueue === InspectionInventoryStatus.Unknown
  if (isUnprovisioned && sortOption.method === SortMethods.SortByUpdated) {
    return false
  }
  return true
}

export class inventoryQueue extends React.Component<
  InventoryQueueProps,
  InventoryQueueState
> {
  constructor(props: InventoryQueueProps) {
    super(props)
    this.state = {
      FilterPaneOpen: false,
      SortPaneOpen: false,
      ColorIds: [],
      colorFilters: [],
      setPieces: [],
      nameFilter: '',
      list: props.Inventory,
      sortOrder: defaultSortOrder,
      filteredList: []
    }
    this.isSetPiecesForSetId = this.isSetPiecesForSetId.bind(this)
    this.extractFilters = this.extractFilters.bind(this)
  }

  isSetPiecesForSetId(payload: number) {
    return payload === this.props.Inspection.setId
  }

  extractFilters(payload: SetPiece[]) {
    const distinctColorIds = payload.reduce((color_ids: number[], setPiece) => {
      if (color_ids.indexOf(setPiece.color_id) === -1) {
        color_ids.push(setPiece.color_id)
      }
      return color_ids
    }, [])
    this.setState({
      setPieces: payload,
      ColorIds: distinctColorIds,
      colorFilters: distinctColorIds.slice(0),
      ...executeFilterSort(
        this.props,
        {
          ...this.state,
          setPieces: payload,
          ColorIds: distinctColorIds,
          colorFilters: distinctColorIds.slice(0)
        },
        this.getSortOrder()
      )
    })
  }

  filterByName(input: string) {
    this.setState({
      nameFilter: input,
      ...executeFilterSort(
        this.props,
        {
          ...this.state,
          nameFilter: input
        },
        this.getSortOrder()
      )
    })
  }

  getSortOrder(methods?: SortItemProps[]) {
    methods = methods || this.state.sortOrder
    return methods
      .filter((opt) => filterForQueue(this.props.ActiveQueue, opt))
      .map((s) => s.method)
      .reverse()
  }

  public componentDidMount() {
    SetPieceService.on(
      SetPieceOperations.GetBySetId,
      this.isSetPiecesForSetId,
      this.extractFilters
    )
    SetPieceService.sendRequest(
      SetPieceOperations.GetBySetId,
      this.props.Inspection.setId
    )
  }

  public componentDidUpdate(prevProps: InventoryQueueProps) {
    const { _id: id } = this.props.Inspection
    const { _id: old_id } = prevProps.Inspection
    if (id !== old_id) {
      SetPieceService.sendRequest(
        SetPieceOperations.GetBySetId,
        this.props.Inspection.setId
      )
    }

    const listChanged = this.inventoryListIsDifferent(
      this.props.Inventory,
      prevProps.Inventory
    )
    if (listChanged) {
      this.setState(
        (state) => ({
          ...executeFilterSort(
            this.props,
            {
              ...state
            },
            this.getSortOrder()
          )
        }),
        () => {
          this.tryRouteToFirst()
        }
      )
    }
  }

  inventoryListIsDifferent(
    listA: InspectionInventory[],
    listB: InspectionInventory[]
  ) {
    return JSON.stringify(listA) !== JSON.stringify(listB)
  }

  private tryRouteToFirst() {
    const inventoryItemId = getActiveInventoryIdFromRoute(this.props)
    const firstItem = this.state.list[0] || ({} as InspectionInventory)
    const activeExists = this.state.list.find(
      (inv) => inv._id === inventoryItemId
    )
    if (firstItem._id && !activeExists) {
      const { history, location } = this.props
      history.replace(location.pathname + '?inventory_id=' + firstItem._id)
    }
  }

  componentWillUnmount() {
    SetPieceService.off(
      SetPieceOperations.GetBySetId,
      this.isSetPiecesForSetId,
      this.extractFilters
    )
  }

  render() {
    const activeInventoryId = getActiveInventoryIdFromRoute(this.props)
    let activeInventoryIndex = 0
    let activeInventory = this.state.list[activeInventoryIndex]
    if (activeInventory) {
      const matchingInventoryIndex = this.state.list.findIndex(
        (inv) => inv._id === activeInventoryId
      )
      if (matchingInventoryIndex > -1) {
        activeInventoryIndex = matchingInventoryIndex
        activeInventory = this.state.list[matchingInventoryIndex]
      }
    }
    const nextInventory = this.state.list[activeInventoryIndex + 1]

    return (
      <div
        style={{
          overflow: 'none',
          height: 'calc(100vh - 111px)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            flexShrink: 0,
            backgroundColor: 'rgba(24,144,255,.1)',
            minHeight: '251px'
          }}
        >
          {activeInventory && (
            <InspectionInventoryLoader
              inventory_item_id={activeInventory._id}
              inventory={activeInventory}
            >
              {(data) => (
                <InventoryItemDetails
                  {...this.props}
                  {...data}
                  submitOnBlur={
                    this.props.ActiveQueue ===
                    InspectionInventoryStatus.Incomplete
                  }
                  nextInventory={nextInventory}
                />
              )}
            </InspectionInventoryLoader>
          )}
        </div>
        <InventoryQueueMenu
          activeQueue={this.props.ActiveQueue}
          currentFilterName={this.state.nameFilter}
          resetFilters={() => this.resetFilters()}
          hiddenItems={this.state.hiddenItems}
          Inspection={this.props.Inspection}
          filterByName={(search) => this.filterByName(search)}
          ColorIds={this.state.ColorIds}
          colorFilters={this.state.colorFilters}
          onColorFilterChange={(colorId, checked) =>
            this.colorFilterChange(colorId, checked)
          }
          sortOrder={this.state.sortOrder.filter((opt) =>
            filterForQueue(this.props.ActiveQueue, opt)
          )}
          onSortChange={(sortOrder) => {
            const rest = this.state.sortOrder.filter(
              (currentStateSortOrder) => {
                return (
                  sortOrder.findIndex(
                    (newOrder) => newOrder.key === currentStateSortOrder.key
                  ) === -1
                )
              }
            )
            rest.forEach((pastStateSortOrder) => {
              const insertIndex = this.state.sortOrder.indexOf(
                pastStateSortOrder
              )
              sortOrder.splice(insertIndex, 0, pastStateSortOrder)
            })
            this.setState((state) => ({
              sortOrder,
              ...executeFilterSort(
                this.props,
                {
                  ...state
                },
                this.getSortOrder(sortOrder)
              )
            }))
          }}
        />
        <div
          style={{
            flex: '1 1',
            backgroundColor: '#fcfcfc'
          }}
        >
          <InventoryList
            inventoryListIndex={this.props.ActiveQueue}
            inventory={this.state.list}
          />
        </div>
      </div>
    )
  }

  colorFilterChange(colorId: number, checked: boolean) {
    const allEnabled =
      this.state.ColorIds &&
      this.state.ColorIds.length === this.state.colorFilters.length
    if (allEnabled) {
      this.setState({
        colorFilters: [colorId],
        ...executeFilterSort(
          this.props,
          {
            ...this.state,
            colorFilters: [colorId]
          },
          this.getSortOrder()
        )
      })
      return
    }

    const currentIndex = this.state.colorFilters.indexOf(colorId)
    if (checked && currentIndex === -1) {
      let newState = this.state.colorFilters.concat([colorId])
      this.setState({
        colorFilters: newState,
        ...executeFilterSort(
          this.props,
          {
            ...this.state,
            colorFilters: newState
          },
          this.getSortOrder()
        )
      })
    } else if (currentIndex > -1) {
      let newState = this.state.colorFilters.slice(0)
      newState.splice(currentIndex, 1)
      if (newState.length <= 0) {
        newState = this.state.ColorIds || []
      }
      this.setState({
        colorFilters: newState,
        ...executeFilterSort(
          this.props,
          {
            ...this.state,
            colorFilters: newState
          },
          this.getSortOrder()
        )
      })
    }
  }

  resetFilters() {
    const colorFilters = this.state.ColorIds || []
    const nameFilter = ''
    this.setState({
      nameFilter,
      colorFilters,
      ...executeFilterSort(
        this.props,
        {
          ...this.state,
          colorFilters,
          nameFilter
        },
        this.getSortOrder()
      )
    })
  }
}

export const InventoryQueue = withRouter(inventoryQueue)
