import * as React from 'react'
import { Menu, Drawer } from 'antd'
import { ColorFilter } from './ColorFilter'
import { Color } from '@/models'
import ColorService from '@/services/color'
const { Item } = Menu

export interface FilterDrawerProps {
  visible?: boolean
  onClose?: () => void
  colorOptions: number[]
  colorFilters: number[]
  onColorFilterChange: (colorId: number, checked: boolean) => void
}

interface FilterDrawerState {
  colors: Color[]
}

export class FilterDrawer extends React.Component<
  FilterDrawerProps,
  FilterDrawerState
> {
  public state: FilterDrawerState = {
    colors: []
  }

  public constructor(props: FilterDrawerProps) {
    super(props)
  }

  public render() {
    const allSelected =
      this.props.colorFilters.length === this.props.colorOptions.length
    return (
      <Drawer
        title="Filter Options"
        placement="right"
        closable={true}
        visible={this.props.visible}
        onClose={this.props.onClose}
      >
        <Menu mode="vertical" selectable={false}>
          {this.state.colors.map((color) => {
            return (
              <Item key={`colorFilter-${color}`}>
                <ColorFilter
                  allSelected={allSelected}
                  ColorId={color.id}
                  Color={color}
                  selectedColors={this.props.colorFilters}
                  onChange={this.props.onColorFilterChange}
                />
              </Item>
            )
          })}
        </Menu>
      </Drawer>
    )
  }

  public componentDidMount() {
    this.mapToColors()
  }

  public componentDidUpdate(pastProps: FilterDrawerProps) {
    this.mapToColors(pastProps.colorOptions)
  }

  private async mapToColors(pastColors: number[] = []) {
    const current = this.props.colorOptions
    const {
      add,
      carry,
      removed
    }: {
      add: number[]
      carry: Color[]
      removed: number[]
    } = current.reduce(
      (grouping, color_id) => {
        if (pastColors.indexOf(color_id) === -1) {
          grouping.removed.push(color_id)
        }
        const found = this.state.colors.find((color) => color.id === color_id)
        if (found) {
          grouping.carry.push(found)
        } else {
          grouping.add.push(color_id)
        }
        return grouping
      },
      { add: [], carry: [], removed: [] }
    )

    if (add.length === 0 && removed.length === 0) {
      return
    }

    const colors = await Promise.all(
      add.map((color_id) => ColorService.get(color_id))
    )
    this.setState({
      colors: colors.concat(carry).sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (b.name > a.name) {
          return -1
        }
        return 0
      })
    })
  }
}
