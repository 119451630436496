import { usePageTitleEffect } from '@/components/usePageTitleEffect'
import { ExpandedCollection } from '@/providers/Collections/Context'
import { PrinterOutlined } from '@ant-design/icons'
import { Button, Col, PageHeader, Row, Switch } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import { useHistory } from 'react-router'
import { DownloadBricklinkWantedList } from './DownloadBricklinkWantedList'
import { ReportMissingPieceList } from './MissingPieceList'
import { MissingPieceSummary } from './MissingPieceSummary'
import { ReportDataLoader } from './ReportDataLoader'

interface Props {
  collectionRecord: ExpandedCollection
}

export const CollectionReportPage: React.FC<Props> = ({ collectionRecord }) => {
  const [customerView, updateCustomerView] = React.useState<boolean>(false)
  const [sortByInspection, setSortByInspection] = React.useState<boolean>(false)
  const history = useHistory()
  usePageTitleEffect(
    `Sell Submission ${collectionRecord.collection.sellSubmissionId} Report`,
    true
  )
  return (
    <Content>
      <ReportDataLoader
        sortByInspection={sortByInspection}
        collectionRecord={collectionRecord}
      >
        {(data) => (
          <>
            <PageHeader
              className="printHide"
              onBack={() =>
                history.length > 0
                  ? history.goBack()
                  : history.push(
                      '/collection/view/' + collectionRecord.collection._id
                    )
              }
              title={`Sell Submission ${collectionRecord.collection.sellSubmissionId}`}
              extra={
                <Row gutter={24}>
                  <Col style={{ minWidth: '160px' }}>
                    Sort By{' '}
                    <Switch
                      checkedChildren="Inspection"
                      unCheckedChildren="Part"
                      checked={sortByInspection}
                      onChange={(value) => setSortByInspection(value)}
                    />
                  </Col>
                  <Col>
                    Customer View{' '}
                    <Switch onChange={(value) => updateCustomerView(value)} />
                  </Col>
                  <Col>
                    <Button
                      className="printHide"
                      type="primary"
                      onClick={() => print()}
                      icon={<PrinterOutlined />}
                    >
                      Print
                    </Button>
                  </Col>
                  <Col>
                    <DownloadBricklinkWantedList
                      selLSubmissionId={
                        collectionRecord.collection.sellSubmissionId
                      }
                      loadedInventorySetPieces={data}
                    />
                  </Col>
                </Row>
              }
            />
            <MissingPieceSummary
              customerViewEnabled={customerView}
              loadedInventorySetPieces={data}
            />
            <ReportMissingPieceList
              customerViewEnabled={customerView}
              loadedInventorySetPieces={data}
            />
          </>
        )}
      </ReportDataLoader>
    </Content>
  )
}
