import * as React from 'react'
import { ImageUpdater } from './ImageUpdater'
import { Spin } from 'antd'
import PieceImageService, {
  PieceImageOperations
} from '@/services/piece_images'
import { PieceImage } from '@/models/PieceImage'
import { Piece } from '@/models'

interface Props {
  style?: React.CSSProperties
  pieceId: number
  hexColor: string
  src?: string
  onUpdate?: (pieces: Piece[]) => void
}

interface State {
  loading: boolean
  src?: string
}

export class PieceImageViewer extends React.Component<Props, State> {
  public state: State = { loading: !this.props.src, src: this.props.src }

  public constructor(props: Props) {
    super(props)
    this.ifPieceId = (input: any) =>
      input.pieceId === this.props.pieceId.toString()
    this.updatePieceImage = (payload: PieceImage[]) => {
      const [image] = payload
      if (image && this.state.src !== image.src) {
        this.setState({
          src: image.src
        })
      }
    }
    PieceImageService.on(
      PieceImageOperations.Get,
      this.ifPieceId,
      this.updatePieceImage
    )
  }

  public render() {
    return (
      <div
        className="inventoryItemThumbnail inventoryItemThumbnail--large"
        style={{
          borderColor: `#${this.props.hexColor}`,
          position: 'relative'
        }}
      >
        {this.state.loading ? <Spin /> : <img src={this.state.src} />}
        <ImageUpdater
          onUpdate={this.props.onUpdate}
          style={{ position: 'absolute', top: 0, right: 0 }}
          piece_id={this.props.pieceId}
        />
      </div>
    )
  }

  public componentDidMount() {
    if (!this.state.src) {
      PieceImageService.getPieceImage(this.props.pieceId.toString()).then(
        (image) => {
          this.setState({
            loading: false,
            src: image.src
          })
        }
      )
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.pieceId !== this.props.pieceId) {
      if (this.props.src) {
        this.setState({
          loading: false,
          src: this.props.src
        })
        return
      }
      this.setState({
        loading: true
      })
      PieceImageService.getPieceImage(`${this.props.pieceId}`).then(() => {
        this.setState({
          loading: false
        })
      })
    }
  }

  componentWillUnmount() {
    PieceImageService.off(
      PieceImageOperations.Get,
      this.ifPieceId,
      this.updatePieceImage
    )
  }

  private ifPieceId: (payload: any) => boolean
  private updatePieceImage: (payload: PieceImage[]) => void
}
