import {
  Inspection,
  InspectionInventory,
  SetPiece,
  InspectionInventoryStatus,
  InventoryCollection,
  Color,
  Part,
  Piece
} from '@/models'
import api from '@/services/api'
import SetPieceService, {
  default as set_pieces,
  SetPieceOperations
} from '@/services/set_pieces'
import {
  default as InsInvSvc,
  InspectionInventoryOperations
} from '@/services/inspection_inventory'
import { default as PieceSvc, PieceOperations } from '@/services/pieces'
import { default as ColorSvc, ColorOperations } from '@/services/color'
import { default as PartSvc, PartOperations } from '@/services/parts'
import { createInspectionInventory } from '@/models/InspectionInventory'
import { computeRelyingSetPieces } from './computeRelyingSetPieces'

function valuesToArray<T>(input: Map<any, T>) {
  const array: T[] = []
  const entries = input.values()
  for (let entry of entries) {
    array.push(entry)
  }
  return array
}

export async function initializeInventory(
  inspection: Inspection,
  force: boolean = false
): Promise<any> {
  const collection: InventoryCollection = {
    setPieces: new Map<number, SetPiece>(),
    inspectionInventories: new Map<string, InspectionInventory>(),
    colors: new Map<number, Color>(),
    parts: new Map<number, Part>(),
    pieces: new Map<number, Piece>()
  }
  if (!force) {
    // See if we can get it from local cache of set pieces
    const cachedSetPieces = await new Promise<SetPiece[]>((resolve) => {
      const ifSetId = (setId: number) => setId === inspection.setId
      set_pieces.once(SetPieceOperations.GetBySetId, ifSetId, resolve)
      set_pieces.sendRequest(SetPieceOperations.GetBySetId, inspection.setId)
    })
    const pieceIds = new Set<number>()
    const partIds = new Set<number>()
    const colorIds = new Set<number>()

    cachedSetPieces.forEach((sp) => {
      pieceIds.add(sp.piece_id)
      partIds.add(sp.part_id)
      colorIds.add(sp.color_id)
    })

    const [pieces, parts, colors] = await Promise.all([
      PieceSvc.getManyById(setToArray(pieceIds)),
      PartSvc.getManyById(setToArray(partIds)),
      ColorSvc.getManyByid(setToArray(colorIds))
    ])

    collection.pieces = new Map(pieces.map((x) => [x.id, x]))
    collection.parts = new Map(parts.map((x) => [x.id, x]))
    collection.colors = new Map(colors.map((x) => [x.id, x]))
    collection.setPieces = new Map(cachedSetPieces.map((x) => [x.id, x]))
  }

  if (force) {
    const serverData = await api.fetchInventory(inspection.setNumber, force)

    collection.colors = serverData.colors
    collection.parts = serverData.parts
    collection.pieces = serverData.pieces
    collection.setPieces = serverData.setPieces
  }

  computeRelyingSetPieces(collection)

  for (let setPiece of collection.setPieces.values()) {
    if (
      !setPiece.parent_piece_id ||
      !collection.pieces.has(setPiece.parent_piece_id)
    ) {
      const inspInv = createInspectionInventory(inspection._id, setPiece)
      collection.inspectionInventories.set(inspInv._id, inspInv)
    }
  }

  await Promise.all([
    ColorSvc.sendRequest(
      ColorOperations.BulkAdd,
      valuesToArray(collection.colors)
    ),
    PartSvc.sendRequest(
      PartOperations.BulkAdd,
      valuesToArray(collection.parts)
    ),
    PieceSvc.sendRequest(
      PieceOperations.BulkAdd,
      valuesToArray(collection.pieces)
    ),
    SetPieceService.sendRequest(
      SetPieceOperations.BulkAdd,
      valuesToArray(collection.setPieces)
    ),
    InsInvSvc.sendRequest(
      InspectionInventoryOperations.BulkAdd,
      valuesToArray(collection.inspectionInventories)
    )
  ])
  return Promise.resolve()
}

function setToArray<T>(input: Set<T>) {
  const output: T[] = []
  for (let entry of input.values()) {
    output.push(entry)
  }
  return output
}
