import {
  CollectionsContext,
  ExpandedCollection
} from '@/providers/Collections/Context'
import { useCollectionsContext } from '@/providers/Collections/hooks'
import { LoadingOutlined } from '@ant-design/icons'
import * as React from 'react'

interface Props {
  children: (
    collection: ExpandedCollection
  ) => React.ReactNode | React.ReactNodeArray
  collectionId: string
  context: CollectionsContext
}

class _CollectionLoader extends React.Component<Props> {
  public render() {
    const collection = this.props.context.collections?.find(
      (x) => x.collection._id == this.props.collectionId
    )
    if (!collection) {
      return <LoadingOutlined />
    }
    return this.props.children(collection)
  }

  public componentDidMount() {
    this.props.context.populate()
  }
}

export const CollectionLoader: React.FC<Omit<Props, 'context'>> = (props) => {
  const context = useCollectionsContext()
  return <_CollectionLoader {...props} context={context} />
}
