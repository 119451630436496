import { Inspection, SetRecord } from '@/models'
import { Progress } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import * as React from 'react'
import { SetImageAvatar } from '../SetImageAvatar'

interface Props {
  set: SetRecord
  inspection: Inspection
}

export const InspectionMeta: React.FC<Props> = ({ inspection, set }) => {
  const screens = useBreakpoint()
  let percentComplete = 0
  let percentMissing = 0

  if (inspection) {
    const { statusQty, totalQty, missingQty } = inspection
    percentComplete = Math.round((statusQty / totalQty) * 100)
    percentMissing = Math.round((missingQty / totalQty) * 100)
  }
  return set ? (
    <div style={{ display: 'flex', width: '100%' }}>
      {screens.md && (
        <div style={{ padding: '4px' }}>
          <SetImageAvatar {...set} size={48} />
        </div>
      )}
      <div style={{ flex: '1' }}>
        <div>
          {set.set_number} {set.name}
        </div>
        <div>
          <Progress
            type="line"
            success={{ percent: percentComplete - percentMissing }}
            percent={percentComplete}
            strokeColor="#fa8c16"
          />
        </div>
      </div>
    </div>
  ) : null
}
