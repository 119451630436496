import { Inspection, SetRecord } from '@/models'
import { Button, Card, Col, Progress, Row } from 'antd'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { InspectionContextMenu } from './InspectionContextMenu'
import { useDrag } from 'react-dnd'
import { InspectionDropItem } from './CollectionCard'
import { SetImage } from './SetImage'
import { PlusSquareOutlined } from '@ant-design/icons'
import { CollectionsModal } from './CollectionsModal'
import { InspectionCardGhost } from './InspectionCardGhost'

const { Meta } = Card

interface Props {
  inspection: Inspection
  set: SetRecord
  onCollectionDrop: (
    inspection: Inspection,
    collectionId: string
  ) => void | Promise<void>
}

export const InspectionCard: React.FC<Props> = ({
  inspection,
  set,
  onCollectionDrop
}) => {
  const [dragStyle, dragRef] = useDrag<
    InspectionDropItem,
    any,
    React.CSSProperties
  >(
    () => ({
      type: 'InspectionCard',
      item: {
        inspection,
        onDrop: (collectionId) => onCollectionDrop(inspection, collectionId)
      },
      collect: (monitor) => {
        if (!monitor.isDragging()) {
          return undefined
        }
        return {
          opacity: 0.75
        }
      }
    }),
    []
  )
  const [
    showCollectionsModal,
    setShowCollectionsModal
  ] = React.useState<boolean>(false)
  const history = useHistory()
  const inspectionRoute = `/inspections/${inspection._id}`
  const isStarted = inspection.statusQty > 0
  const { statusQty, totalQty, missingQty } = inspection
  const percentComplete = Math.round((statusQty / totalQty) * 100)
  const percentMissing = Math.ceil((missingQty / totalQty) * 100)
  const done = totalQty > 0 && statusQty >= totalQty
  let actionText = done ? 'Review' : isStarted ? 'Continue' : 'Start'
  return (
    <div ref={dragRef}>
      <InspectionCardGhost inspection={inspection} set={set} />
      <Card
        key={`inspection-card-${inspection._id}`}
        style={dragStyle}
        actions={[
          <Button
            type={isStarted ? 'default' : 'primary'}
            onClick={() => history.push(inspectionRoute)}
          >
            {actionText}
          </Button>,
          <InspectionContextMenu
            inspection={inspection}
            extraContextItems={[
              <a role="button" onClick={(evt) => setShowCollectionsModal(true)}>
                <Row justify="space-between">
                  <Col style={{ paddingRight: '10px' }}>Add To Collection</Col>
                  <Col>
                    <PlusSquareOutlined />
                  </Col>
                </Row>
              </a>
            ]}
          />
        ]}
      >
        <Meta
          avatar={<SetImage setId={inspection.setId} />}
          title={`${set.set_number}: ${set.name}`}
          description={
            isStarted && (
              <Progress
                type="line"
                success={{ percent: percentComplete - percentMissing }}
                percent={percentComplete}
                strokeColor="#fa8c16"
              />
            )
          }
        ></Meta>
      </Card>
      {showCollectionsModal && (
        <CollectionsModal
          currentCollectionId={inspection.collectionId}
          onCancel={() => setShowCollectionsModal(false)}
          onSelect={(collectionId) =>
            onCollectionDrop(inspection, collectionId)
          }
        />
      )}
    </div>
  )
}
