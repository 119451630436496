import * as React from 'react'
import { Row, Col, InputNumber, Button, Tag, Dropdown } from 'antd'
import { SetPiece, InspectionInventory } from '@/models'
import { InventoryItemThumbnail } from './InventoryItemThumbnail'
import {
  titleCase,
  normalizeName,
  getTypeTag,
  getNormalizedColorName
} from './inventoryItemUtils'
import { MissingSetPieceMenu } from './MissingSetPieceMenu'
import { SetPieceItem, SetPieceLoader } from '@/loaders/SetPieceLoader'
import { DownOutlined } from '@ant-design/icons'

interface Props {
  setPiece: SetPiece
  inspectionInventory?: InspectionInventory
  tryQueue(setPieceId: number, qty: number): void
}

interface State {
  missingQuantity: number
}

export class SubPieceMissingItem extends React.Component<Props, State> {
  public state = {
    missingQuantity: this.props.inspectionInventory
      ? this.props.inspectionInventory.missing_quantity
      : 0
  }

  render() {
    return (
      <SetPieceLoader
        set_piece_id={this.props.setPiece.id}
        setPiece={this.props.setPiece}
      >
        {this.renderRow}
      </SetPieceLoader>
    )
  }

  private renderRow = (ctx: SetPieceItem) => {
    const colorName = getNormalizedColorName(ctx.color.common)
    const tag = getTypeTag(ctx.setPiece.type)

    return (
      <Row
        style={{
          backgroundColor:
            this.state.missingQuantity > 0 ? 'rgba(255, 0, 0, .1)' : 'inherit'
        }}
        className="inventoryItemRow"
        role="row"
      >
        <Col role="gridcell" tabIndex={-1}>
          <InventoryItemThumbnail {...ctx} />
        </Col>
        <Col className="inventoryItemQuantity" role="gridcell" tabIndex={-1}>
          &times;
          {ctx.setPiece.quantity}
        </Col>
        <Col
          flex="auto"
          style={{ maxWidth: 'calc(100% - 190px)' }}
          role="gridcell"
          tabIndex={-1}
        >
          <div>
            <h4>
              {titleCase(colorName)} {normalizeName(ctx.part.name)}{' '}
              {tag.include && <Tag color={tag.color}>{tag.text}</Tag>}
            </h4>
          </div>
          <Row align="middle" gutter={10} style={{ width: '100%' }}>
            <Col>
              {ctx.setPiece.quantity > 1 ? (
                <Dropdown
                  overlay={
                    <MissingSetPieceMenu
                      SetPiece={ctx.setPiece}
                      onClick={(i) => this.handleMissingQuantityChange(i)}
                    />
                  }
                >
                  <Button danger>
                    Missing <DownOutlined />
                  </Button>
                </Dropdown>
              ) : (
                <Button
                  danger
                  onClick={() => this.handleMissingQuantityChange(1)}
                >
                  Missing
                </Button>
              )}
            </Col>
            <Col>
              <InputNumber
                value={this.state.missingQuantity}
                onChange={(i) => this.handleMissingQuantityChange(i)}
                min={0}
                max={ctx.setPiece.quantity}
                step={1}
                placeholder="Qty Needed"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  private handleMissingQuantityChange(i: number) {
    this.setState({
      missingQuantity: i
    })
    this.props.tryQueue(this.props.setPiece.id, i)
  }
}
