import { onValueChangeEffect } from '@/hooks/useOnValueChangeEffect'
import { InspectionInventory } from '@/models'
import { Collection } from '@/models/Collection'
import { Inspection } from '@/models/Inspection'
import { useCollectionsContext } from '@/providers/Collections/hooks'
import { CollectionsDB } from '@/services/collections'
import { InspectionsDb } from '@/services/inspections'
import { InspectionInventoryDB } from '@/services/inspection_inventory'
import { CheckCircleTwoTone, InboxOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Progress, Row } from 'antd'
import { RcFile } from 'antd/lib/upload'
import Dragger from 'antd/lib/upload/Dragger'
import * as React from 'react'
import { useAsyncCallback } from 'react-async-hook'

export const ImportLoader: React.FC = () => {
  const collectionContext = useCollectionsContext()
  const [file, setFile] = React.useState<RcFile | undefined>()
  const [importCounter, setImportCounter] = React.useState(0)

  const [exportFileData, setExportFileData] = React.useState<
    ParsedExportData | undefined
  >()

  React.useEffect(() => {
    if (!file) {
      return
    }
    const reader = new FileReader()
    const onLoad: (
      this: FileReader,
      ev: ProgressEvent<FileReader>
    ) => any = () => {
      if (typeof reader.result === 'string') {
        const importFile = JSON.parse(reader.result)
        if (isExportFile(importFile)) {
          const staged = uncompactImportFile(importFile)
          setExportFileData(staged)
        }
      }
    }
    reader.addEventListener('load', onLoad, false)
    reader.readAsText(file)
    return () => reader.removeEventListener('load', onLoad)
  }, [file])

  onValueChangeEffect(() => {
    setImportCounter(0)
  }, file)

  const importMethod = useAsyncCallback(async (data: ParsedExportData) => {
    for (const inspection of data.inspections) {
      await InspectionsDb.put(inspection)
      setImportCounter((x) => x + 1)
    }

    for (const collection of data.collections) {
      await CollectionsDB.put(collection)
      setImportCounter((x) => x + 1)
    }

    for (const inspectionInventory of data.inspection_inventories) {
      await InspectionInventoryDB.put(inspectionInventory)
      setImportCounter((x) => x + 1)
    }

    collectionContext.import(data.collections)

    return true
  })

  return (
    <>
      <Dragger
        name="file"
        accept="application/json"
        customRequest={() => {}}
        showUploadList={!exportFileData}
        beforeUpload={(file) => {
          setFile(file)
          return true
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag export file to this area
        </p>
      </Dragger>
      {!!file && (
        <Alert
          type="warning"
          message="Importing data may result in lost progress since inspection progress may be over-written."
        />
      )}
      {!!file && (
        <Row style={{ paddingTop: '1rem' }}>
          <Col flex={1}>{file.name}</Col>
          <Col>
            {importMethod.result ? (
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            ) : (
              <Button
                disabled={!exportFileData}
                onClick={() => importMethod.execute(exportFileData)}
              >
                Start Import
              </Button>
            )}
          </Col>
        </Row>
      )}
      {importMethod.loading && (
        <Progress
          percent={Math.round(
            (importCounter /
              (exportFileData.collections.length +
                exportFileData.inspections.length +
                exportFileData.inspection_inventories.length)) *
              100
          )}
          status="active"
        />
      )}
    </>
  )
}

function isExportFile(input: any): input is ExportFileParsed {
  return (
    typeof input?.headers === 'object' &&
    typeof input?.inspections === 'object' &&
    typeof input?.collections === 'object' &&
    typeof input?.inspection_inventories === 'object'
  )
}

interface ParsedExportData {
  inspections: Inspection[]
  collections: Collection[]
  inspection_inventories: InspectionInventory[]
}

interface ExportFileParsed {
  headers: Record<string, number>
  inspections: Record<string, any>[]
  collections: Record<string, any>[]
  inspection_inventories: Record<string, any>[]
}

function uncompactImportFile(file: ExportFileParsed) {
  const headerLookup: Record<string, string> = {}
  for (const [key, value] of Object.entries(file.headers)) {
    headerLookup[value.toString(10)] = key
  }

  return {
    inspections: file.inspections.map((x) =>
      mapRecord<Inspection>(headerLookup, x)
    ),
    collections: file.collections.map((x) =>
      mapRecord<Collection>(headerLookup, x)
    ),
    inspection_inventories: file.inspection_inventories.map((x) =>
      mapRecord<InspectionInventory>(headerLookup, x)
    )
  } as ParsedExportData
}

function mapRecord<T>(
  headerLookup: Record<string, string>,
  x: Record<string, any>
) {
  const record: Record<string, any> = {}
  for (const key in x) {
    const propertyName = headerLookup[key.toString()]
    record[propertyName] = x[key]
  }
  return record as T
}
