import * as React from 'react'
import { Button, Skeleton } from 'antd'
import { useIncludePricesRouteParam } from '@/hooks/useIncludePrices'
import { LoadedInventorySetPieceWithPriceGuides } from './ReportDataLoader'
import { ExportOutlined } from '@ant-design/icons'
import { setPieceTypeToBricklinkCatalogType } from '@/components/Inspection/MissingPieceListItem'
import { useAsync } from 'react-async-hook'
import ColorService from '@/services/color'
import { Color, SetRecord } from '@/models'
import SetService from '@/services/sets'

interface Props {
  selLSubmissionId?: string | number
  loadedInventorySetPieces: LoadedInventorySetPieceWithPriceGuides[]
}

export const DownloadBricklinkWantedList: React.FC<Props> = ({
  selLSubmissionId,
  loadedInventorySetPieces
}) => {
  const missingInventorySetPieces = React.useMemo(() => {
    return loadedInventorySetPieces.filter(
      (x) => x.Inventory.missing_quantity > 0
    )
  }, [loadedInventorySetPieces])
  const prices = useIncludePricesRouteParam()
  const linkRef = React.useRef<HTMLAnchorElement>(null)
  const [linkDataUri, setLinkDataUri] = React.useState('')
  const colorIds = React.useMemo(() => {
    const distinctColorIds: number[] = []
    if (!prices) {
      return distinctColorIds
    }
    for (const item of missingInventorySetPieces) {
      if (distinctColorIds.indexOf(item.SetPiece.color_id) === -1) {
        distinctColorIds.push(item.SetPiece.color_id)
      }
    }
    return distinctColorIds
  }, [prices, missingInventorySetPieces])

  const setIds = React.useMemo(() => {
    const distincSetIds: number[] = []
    if (!prices) {
      return distincSetIds
    }
    for (const item of missingInventorySetPieces) {
      if (distincSetIds.indexOf(item.SetPiece.set_id) === -1) {
        distincSetIds.push(item.SetPiece.set_id)
      }
    }
    return distincSetIds
  }, [prices, missingInventorySetPieces])

  const asyncColorsMap = useAsync(
    async (_colorIds: number[]) => {
      if (!_colorIds.length) {
        new Map<number, Color>()
      }
      const colors = await ColorService.getManyByid(_colorIds)
      return new Map<number, Color>(colors.map((x) => [x.id, x]))
    },
    [colorIds]
  )

  const asyncSetsMap = useAsync(
    async (_setIds: number[]) => {
      if (!_setIds.length) {
        new Map<number, SetRecord>()
      }
      const sets = await SetService.getManyByIds(_setIds)
      return new Map<number, SetRecord>(sets.map((x) => [x.id, x]))
    },
    [setIds]
  )

  const loading = asyncColorsMap.loading || asyncSetsMap.loading

  React.useEffect(() => {
    if (!prices || loading) {
      return
    }
    const uri = makeDataUri(
      missingInventorySetPieces,
      asyncColorsMap.result,
      asyncSetsMap.result,
      selLSubmissionId
    )
    setLinkDataUri(uri)
    return () => URL.revokeObjectURL(uri)
  }, [
    prices,
    missingInventorySetPieces,
    selLSubmissionId,
    loading,
    setLinkDataUri
  ])

  if (!prices) {
    return null
  }

  if (loading) {
    return <Skeleton.Button active style={{ width: '150px' }} />
  }

  return (
    <>
      <Button
        icon={<ExportOutlined />}
        onClick={() => {
          if (linkRef.current) {
            linkRef.current.click()
          }
        }}
      >
        Export Bricklink Wanted List
      </Button>
      {linkDataUri && (
        <a
          ref={linkRef}
          href={linkDataUri}
          download={`${selLSubmissionId}-Wanted-List.txt`}
        ></a>
      )}
    </>
  )
}

function makeDataUri(
  data: Props['loadedInventorySetPieces'],
  colorsMap: Map<number, Color>,
  setsMap: Map<number, SetRecord>,
  selLSubmissionId: string | number
) {
  const documentContent = `<INVENTORY>${templateItems(
    data,
    colorsMap,
    setsMap,
    selLSubmissionId
  )}
</INVENTORY>`
  const blob = new Blob([documentContent], {
    type: 'text/xml'
  })
  return URL.createObjectURL(blob)
}

interface WantedItem {
  itemType: string
  itemId: string
  color: number
  maxPrice: number
  minQty: number
  remarks: string
}

function templateItems(
  data: Props['loadedInventorySetPieces'],
  colorsMap: Map<number, Color>,
  setsMap: Map<number, SetRecord>,
  selLSubmissionId: string | number
) {
  let output = ''
  const wantedPieceMap = new Map<number, WantedItem>()

  for (const item of data) {
    const { SetPiece } = item
    const bricklinkType = setPieceTypeToBricklinkCatalogType(SetPiece.type)

    const wantedItem: WantedItem = {
      itemType: bricklinkType,
      itemId: item.part.code,
      color: colorsMap.get(item.SetPiece.color_id)?.bl_id || 0,
      /** @todo round this number to something that makes more sense */
      maxPrice: item.guides?.avg_price || 0,
      minQty: item.Inventory.missing_quantity || 1,
      remarks: `Set ${
        setsMap.get(item.SetPiece.set_id)?.set_number
      } from ${selLSubmissionId} (${item.Inventory.missing_quantity}/${
        item.inspection.missingQty
      })`
    }

    if (wantedPieceMap.has(item.SetPiece.piece_id)) {
      wantedPieceMap.set(
        item.SetPiece.piece_id,
        mergeWantedItem(wantedPieceMap.get(item.SetPiece.piece_id), wantedItem)
      )
    } else {
      wantedPieceMap.set(item.SetPiece.piece_id, wantedItem)
    }
  }
  for (const value of wantedPieceMap.values()) {
    output += `
    <ITEM>
        <ITEMTYPE>${value.itemType}</ITEMTYPE>
        <ITEMID>${value.itemId}</ITEMID>
        <COLOR>${value.color}</COLOR>
        <MAXPRICE>${roundPrice(value.maxPrice).toFixed(3)}</MAXPRICE>
        <MINQTY>${value.minQty}</MINQTY>
        <REMARKS>${value.remarks}</REMARKS>
        <NOTIFY>N</NOTIFY>
    </ITEM>`
  }
  return output
}

function mergeWantedItem(item1: WantedItem, item2: WantedItem): WantedItem {
  return {
    ...item1,
    minQty: item1.minQty + item2.minQty,
    remarks: `${item1.remarks}, ${item2.remarks}`
  }
}

function roundPrice(input: number) {
  const standardAdjustment = 1.15 * input
  if (standardAdjustment < 0.1) {
    return 0.1
  } else if (standardAdjustment < 0.15) {
    return 0.15
  } else if (standardAdjustment < 0.25) {
    return 0.25
  } else if (standardAdjustment < 0.5) {
    return 0.5
  } else if (standardAdjustment < 1) {
    return 1
  } else if (standardAdjustment < 4) {
    return Math.ceil(standardAdjustment * 4) / 4
  } else {
    return Math.ceil(standardAdjustment)
  }
}
