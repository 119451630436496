import { Query } from '@/database/interface'
import { SetRecord } from '@/models'
import InspectionService, { InspectionsDb } from '@/services/inspections'
import SetService from '@/services/sets'
import * as React from 'react'
import {
  defaulInspectionsContext,
  InspectionsContext,
  StatefulInspectionsContext
} from './Context'

interface Props {
  children?: React.ReactNode | React.ReactNodeArray
}

export class InspectionsContextProvider extends React.Component<
  Props,
  StatefulInspectionsContext
> {
  public state: StatefulInspectionsContext = defaulInspectionsContext
  public render() {
    return (
      <InspectionsContext.Provider
        value={{
          ...this.state,
          populate: this.populate,
          reset: this.reset,
          delete: this.delete
        }}
      >
        {this.props.children}
      </InspectionsContext.Provider>
    )
  }

  public populate = async (query: Query, forceReload = false) => {
    this.setState((state) => ({
      loading: true,
      inspections: forceReload ? undefined : state.inspections
    }))
    try {
      const { docs } = await InspectionsDb.find(query)
      const setIds = docs.map((x) => x.setId)
      const setsResult = await SetService.getManyByIds(setIds)

      const setMap: { [key: string]: SetRecord } = setsResult.reduce(
        (carry, set) => {
          carry[set._id] = set
          return carry
        },
        {} as { [key: string]: SetRecord }
      )

      const awaitables: Promise<any>[] = []

      const inspections = docs.map((x) => {
        const set = setMap[x.setId]
        if (!set) {
          const updateSet = SetService.getById(x.setId).then(([set]) => {
            const inspection = inspections.find(
              (inspection) => inspection.inspection.setId === x.setId
            )
            inspection.set = set
          })
          awaitables.push(updateSet)
        }

        return {
          inspection: x,
          set
        }
      })

      await Promise.all(awaitables)

      this.setState({
        inspections,
        loading: false
      })
    } catch (error) {
      if (
        error.message ===
        "Failed to execute 'bound' on 'IDBKeyRange': The parameter is not a valid key."
      ) {
        this.setState({
          inspections: [],
          loading: false
        })
      }
      console.error(error.message)
    }
  }

  public reset = () => {
    this.setState({ inspections: null, loading: false })
  }

  public delete = async (inspectionId: string) => {
    await InspectionService.delete(inspectionId)
    this.setState((current) => {
      return {
        ...current,
        inspections: current.inspections.filter(
          (x) => x.inspection._id !== inspectionId
        )
      }
    })
  }
}
