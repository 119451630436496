import { useIncludePricesRouteParam } from '@/hooks/useIncludePrices'
import { SetPieceType } from '@/models'
import { Card, Col, Divider, Row, Statistic } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import { LoadedInventorySetPieceWithPriceGuides } from './ReportDataLoader'

interface Props {
  loadedInventorySetPieces: LoadedInventorySetPieceWithPriceGuides[]
  customerViewEnabled: boolean
}

export const MissingPieceSummary: React.FC<Props> = ({
  customerViewEnabled,
  loadedInventorySetPieces
}) => {
  const includePriceGuides = useIncludePricesRouteParam()
  if (customerViewEnabled) {
    return null
  }

  const {
    missingFigures,
    missingInstructions,
    missingStickeredParts,
    missingStickerSheets,
    totalPieces,
    missingPieces,
    estimatedExpense,
    piecesWithOutPriceGuides
  } = React.useMemo(() => {
    const counters = {
      missingFigures: 0,
      missingInstructions: 0,
      missingStickerSheets: 0,
      missingStickeredParts: 0,
      totalPieces: 0,
      missingPieces: 0,
      estimatedExpense: 0,
      piecesWithOutPriceGuides: 0
    }

    loadedInventorySetPieces.forEach((loaded) => {
      const missingQuantity = loaded.Inventory.missing_quantity
      if (missingQuantity && loaded.SetPiece.type === SetPieceType.Minifigure) {
        counters.missingFigures = counters.missingFigures + missingQuantity
      }

      if (missingQuantity && loaded.SetPiece.type === SetPieceType.Stickered) {
        counters.missingStickeredParts =
          counters.missingStickeredParts + missingQuantity
      }

      if (missingQuantity && loaded.SetPiece.type === SetPieceType.Sticker) {
        counters.missingStickerSheets =
          counters.missingStickerSheets + missingQuantity
      }

      if (
        missingQuantity &&
        loaded.SetPiece.type === SetPieceType.Instructions
      ) {
        counters.missingInstructions =
          counters.missingInstructions + missingQuantity
      }

      counters.totalPieces += loaded.SetPiece.quantity

      if (missingQuantity) {
        counters.missingPieces = counters.missingPieces + missingQuantity
      }

      if (includePriceGuides) {
        const priceGuide = loaded.guides
        if (priceGuide && missingQuantity) {
          const baseReplacementCost =
            (missingQuantity > 1
              ? priceGuide.qty_avg_price
              : priceGuide.avg_price) || priceGuide.avg_price
          const totalReplacementCost = missingQuantity * baseReplacementCost
          counters.estimatedExpense += totalReplacementCost
        } else if (missingQuantity) {
          counters.piecesWithOutPriceGuides++
        }
      }
    })

    return counters
  }, [loadedInventorySetPieces])

  const completionPercentage = Math.round(
    ((totalPieces - missingPieces) / totalPieces) * 100
  )

  const completionColor =
    completionPercentage > 95
      ? '#73d13d'
      : completionPercentage > 85
      ? '#faad14'
      : '#f5222d'

  return (
    <Content style={{ padding: '1rem' }} className="printHide">
      <Row justify="center" gutter={48}>
        <Col>
          <Card>
            <Statistic
              valueStyle={{ color: missingFigures ? '#cf1322' : null }}
              title="Missing Figures"
              value={missingFigures}
            />
          </Card>
        </Col>
        <Col>
          <Card>
            <Statistic
              valueStyle={{ color: missingInstructions ? '#cf1322' : null }}
              title="Missing Instructions"
              value={missingInstructions}
            />
          </Card>
        </Col>
        <Col>
          <Card>
            <Statistic
              valueStyle={{ color: missingStickerSheets ? '#cf1322' : null }}
              title="Missing Sticker Sheets"
              value={missingStickerSheets}
            />
          </Card>
        </Col>
        <Col>
          <Card>
            <Statistic
              valueStyle={{ color: missingStickeredParts ? '#cf1322' : null }}
              title="Missing Stickered Parts"
              value={missingStickeredParts}
            />
          </Card>
        </Col>
        <Col>
          <Card>
            <Statistic title="Total Pieces" value={totalPieces} />
          </Card>
        </Col>
        <Col>
          <Card>
            <Statistic
              valueStyle={{ color: missingPieces ? '#cf1322' : null }}
              title="Missing Pieces Qty"
              value={missingPieces}
            />
          </Card>
        </Col>
        <Col>
          <Card>
            <Statistic
              valueStyle={{ color: completionColor }}
              title="Unweighted Completion %"
              value={completionPercentage}
              suffix="%"
            />
          </Card>
        </Col>
      </Row>
      {includePriceGuides && (
        <Row justify="center" gutter={48} style={{ marginTop: '48px' }}>
          <Col>
            <Card>
              <Statistic
                valueStyle={{ color: missingFigures ? '#cf1322' : null }}
                title="Known Average Replacement Cost (net shipping)"
                value={`$${estimatedExpense.toFixed(2)}`}
              />
            </Card>
          </Col>
          <Col>
            <Card>
              <Statistic
                title="Pieces Without Known Replacement Cost"
                value={piecesWithOutPriceGuides}
              />
            </Card>
          </Col>
        </Row>
      )}
      <Divider />
    </Content>
  )
}
