import { Inspection } from '@/models'
import { Collection } from '@/models/Collection'
import { useCollectionsContext } from '@/providers/Collections/hooks'
import { DeleteOutlined } from '@ant-design/icons'
import { Button, Card, Popconfirm } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import * as React from 'react'
import { useDrop } from 'react-dnd'
import { Link } from 'react-router-dom'
import { CollectionSetsPreview } from './CollectionSetsPreview'

interface CollectionCardProps {
  collection: Collection
}

export interface InspectionDropItem {
  inspection: Inspection
  onDrop?: (collectionId: string) => void | Promise<void>
}

export const CollectionCard: React.FC<CollectionCardProps> = ({
  collection
}) => {
  const breakpoint = useBreakpoint()
  const [token, updateToken] = React.useState<number>(0)
  const context = useCollectionsContext()
  const [dropStyle, drop] = useDrop<
    InspectionDropItem,
    void,
    React.CSSProperties
  >(() => ({
    accept: 'InspectionCard',
    collect: (monitor) => {
      if (!monitor.canDrop()) {
        return undefined
      }

      const isOver = monitor.isOver()

      return {
        boxShadow: `0px 0px 0px ${isOver ? 4 : 1}px rgb(64, 169, 255)`
      }
    },
    drop: async (item) => {
      if (item.onDrop) {
        await item.onDrop(collection._id)
        updateToken(token + 1)
      }
    }
  }))
  const title =
    breakpoint.sm || breakpoint.md || breakpoint.xs
      ? `Sell: ${collection.sellSubmissionId}`
      : `Sell Submission ${collection.sellSubmissionId}`

  return (
    <div ref={drop} style={dropStyle}>
      <Card
        title={title}
        actions={[
          <Link to={`/collection/view/${collection._id}`}>View</Link>,
          <Popconfirm
            title="Are you sure you want to delete this collection and all inspections contained?"
            onConfirm={() => context.remove(collection)}
          >
            <Button type="text" icon={<DeleteOutlined />} />
          </Popconfirm>
        ]}
      >
        <CollectionSetsPreview
          collectionId={collection._id}
          updateToken={token}
        />
      </Card>
    </div>
  )
}
