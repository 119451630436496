import { Menu } from 'antd'
import Layout from 'antd/lib/layout/layout'
import * as React from 'react'
import { TopBar } from '../Navigation/TopBar'

const { Item } = Menu

interface Props {
  menuItems?: React.ReactNode[]
}

export const FullPage: React.FC<Props> = ({ children, menuItems }) => {
  return (
    <Layout>
      <TopBar>
        <Menu mode="horizontal" theme="dark">
          {menuItems?.map((item, index) => (
            <Item key={`menu-item-${index}`}>{item}</Item>
          ))}
        </Menu>
      </TopBar>
      <div style={{ minHeight: 'calc(100vh - 64px)' }}>{children}</div>
    </Layout>
  )
}
