import { Color } from '@/models/Color'
import { OfflineService, CacheResolver } from '@/services/interfaces'
import { createIndexForProperty } from '@/services/indexer'
import { KeyStore } from './keystore'
import { IDatabase } from '@/database/interface'
import { Database } from '@/database/indexedDB'
import api from './api'

export enum ColorOperations {
  Get = 'Get',
  GetManyByIds = 'GetManyByIds',
  Add = 'Add',
  BulkAdd = 'BulkAdd'
}

class ColorResolver implements CacheResolver<Color> {
  db: IDatabase<Color>
  store: KeyStore<number, Color> = new KeyStore<number, Color>()

  constructor() {
    this.db = new Database<Color>('colors', {
      revs_limit: 1,
      install: (store) => {
        createIndexForProperty(store, 'id')
      }
    })
  }

  async resolve(operation: string, payload: any): Promise<Color[]> {
    if (operation === ColorOperations.Get) {
      const color_id = payload as number
      return this.store.get(color_id).catch(() => {
        return this.db
          .find({
            selector: {
              id: color_id
            }
          })
          .then((data) => data.docs.map((d) => d as Color))
      })
    }

    if (operation === ColorOperations.GetManyByIds) {
      const colorIds = payload as number[]
      return this.db
        .find({
          selector: {
            id: {
              $in: colorIds
            }
          }
        })
        .then((data) => data.docs.map((d) => d as Color))
    }

    if (operation === ColorOperations.Add) {
      const color = payload as Color
      await this.cache(ColorOperations.Get, color.id, [color])
    }

    if (operation === ColorOperations.BulkAdd) {
      const colors = payload as Color[]
      await this.cache(ColorOperations.BulkAdd, null, colors)
    }

    return Promise.resolve([])
  }

  async cache(operation: string, payload: any, data: Color[]) {
    if (operation === ColorOperations.BulkAdd) {
      const documents = data.map((color) => {
        color._id = color.id.toString()
        color._rev = color._rev || '1'
        return color
      })
      await this.db.bulkDocs(documents)
    }

    if (operation === ColorOperations.Get) {
      for (let color of data) {
        const color_id = payload as number
        this.store.set(color_id, color)
        color._id = color_id.toString()
        color._rev = color._rev || '1'
        this.db.put(color)
      }
    }

    if (operation === ColorOperations.GetManyByIds) {
      for (let color of data) {
        this.store.set(color.id, color)
        const docs = await this.db
          .find({
            selector: {
              id: color.id
            }
          })
          .then((data) => data.docs)

        const [existing] = docs

        if (existing) {
          color._id = existing._id
          color._rev = existing._rev
        } else {
          color._id = color.id.toString()
        }

        this.db.put(color)
      }
    }
  }
}

class Colors extends OfflineService<Color> {
  constructor() {
    super(new ColorResolver())
    this.name = 'ColorService'
    this.resolveWith((cache, live) => {
      if (cache instanceof Array && cache.length <= live.length) {
        return live
      }
      return cache
    })
    this.registerSource(ColorOperations.Get, async (color_id: number) => {
      const color = await api.getColorById(color_id)
      return [color]
    })
    this.registerSource(ColorOperations.GetManyByIds, (colorIds: number[]) =>
      api.getColorsByIds(colorIds)
    )
  }

  public get(color_id: number) {
    return ColorService.sendRequest(ColorOperations.Get, color_id).then(
      ([color]) => color
    )
  }

  public getManyByid(colorIds: number[]) {
    return ColorService.sendRequest(ColorOperations.GetManyByIds, colorIds)
  }
}

const ColorService = new Colors()

export default ColorService
