import { Collection } from '@/models/Collection'
import { Form, FormInstance, Input } from 'antd'
import * as React from 'react'

interface Props {
  form: FormInstance<Omit<Collection, '_id' | 'archivedOn' | 'createdOn'>>
}

export const AddCollectionForm: React.FC<Props> = ({ form }) => {
  return (
    <Form form={form} labelCol={{ span: 8 }} name="add-collection-form">
      <Form.Item
        name="sellSubmissionId"
        label="Sell Submission ID"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="sellerName" label="Seller's Name">
        <Input />
      </Form.Item>
      <Form.Item name="trelloLink" label="Trello URL">
        <Input type="url" />
      </Form.Item>
    </Form>
  )
}
