import * as React from 'react'
import { SortMethods } from './inventoryQueueFilterSort'
import { List, Drawer, Row, Col } from 'antd'
import { MinusOutlined } from '@ant-design/icons'
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
  SortableHandle,
  arrayMove
} from 'react-sortable-hoc'
import { InspectionInventoryStatus } from '@/models'
const { Item } = List

interface SortDrawerProps {
  activeQueue?: InspectionInventoryStatus
  order: SortItemProps[]
  visible?: boolean
  onClose?: () => void
  onChange(order: SortItemProps[]): void
}

export interface SortItemProps {
  key: string
  method: SortMethods
  title: string
}

export const defaultSortOrder = [
  {
    key: 'sort-method-' + SortMethods.SortByUpdated,
    method: SortMethods.SortByUpdated,
    title: 'By Last Updated'
  },
  {
    key: 'sort-method-' + SortMethods.SortByType,
    method: SortMethods.SortByType,
    title: 'By Inventory Category'
  },
  {
    key: 'sort-method-' + SortMethods.SortByColor,
    method: SortMethods.SortByColor,
    title: 'By Color'
  },
  {
    key: 'sort-method-' + SortMethods.SortBySize,
    method: SortMethods.SortBySize,
    title: 'By Size'
  },
  {
    key: 'sort-method-' + SortMethods.SortByName,
    method: SortMethods.SortByName,
    title: 'By Name'
  }
] as SortItemProps[]

interface SortListProps {
  items: SortItemProps[]
}
const SortHandle = SortableHandle(() => <MinusOutlined />)

const SortItem = SortableElement<SortItemProps>(({ title }: SortItemProps) => (
  <Item>
    <Row align="middle" className="sortRow">
      <Col className="sortHandle">
        <SortHandle />
      </Col>
      <Col>{title}</Col>
    </Row>
  </Item>
))
const SortableList = SortableContainer<SortListProps>(
  ({ items }: SortListProps) => {
    return (
      <div className="ant-list ant-list-split">
        {items.map((value, index) => (
          <SortItem key={`item-${index}`} index={index} {...value} />
        ))}
      </div>
    )
  }
)

export function SortDrawer(props: SortDrawerProps) {
  const emitChange: SortEndHandler = ({ oldIndex, newIndex }) => {
    const sorted = arrayMove(props.order, oldIndex, newIndex)
    props.onChange(sorted)
  }

  return (
    <Drawer
      title="Sorting Options"
      placement="right"
      closable={true}
      visible={props.visible}
      onClose={props.onClose}
    >
      <p>You may drag and resort the list to your own preferences.</p>
      <SortableList
        lockAxis="y"
        helperClass="sorting"
        items={props.order}
        onSortEnd={emitChange}
      />
    </Drawer>
  )
}
