import * as React from 'react'
import { Button, Spin, message } from 'antd'
import PieceImageService from '@/services/piece_images'
import PieceService from '@/services/pieces'
import { Piece } from '@/models'
import { SyncOutlined } from '@ant-design/icons'

const ImageSources = ['rebrickable', 'bricklink', 'bricklink_thumbnail']

interface Props {
  style?: React.CSSProperties
  piece_id?: number
  onUpdate?: (pieces: Piece[]) => void
}

interface State {
  loading: boolean
  nextImageSource: string
}

export class ImageUpdater extends React.Component<Props, State> {
  public state: State = {
    loading: false,
    nextImageSource: ImageSources[0]
  }

  public render() {
    return (
      <Button
        shape="circle"
        style={this.props.style}
        onClick={() => this.tryGetNewImage()}
      >
        {this.state.loading ? <Spin /> : <SyncOutlined />}
      </Button>
    )
  }

  private async tryGetNewImage() {
    this.setState({
      loading: true
    })
    await PieceImageService.getPieceImage(
      `${this.props.piece_id}`,
      this.state.nextImageSource
    )
      .then((image) => {
        PieceService.update(this.props.piece_id, { image_url: image.src }).then(
          this.props.onUpdate
        )
      })
      .catch((e) => {
        if (e.src) {
          message.info('Could not cache image')
          // Update the Piece data
          PieceService.update(this.props.piece_id, { image_url: e.src })
        } else {
          message.error('Could not refresh image.')
        }
      })
    const nextImageId = ImageSources.indexOf(this.state.nextImageSource) + 1
    this.setState({
      loading: false,
      nextImageSource: ImageSources[nextImageId] || ImageSources[0]
    })
  }
}
