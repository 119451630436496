import * as React from 'react'
import { Inspection } from '@/models/Inspection'
import {
  InspectionInventory,
  InspectionInventoryStatus
} from '@/models/InspectionInventory'
import {
  default as InsInvSvc,
  InspectionInventoryOperations
} from '@/services/inspection_inventory'
import { initializeInventory } from '@/services/initializeInventory'
import { InventoryQueue } from './InventoryQueue'
import { Tabs, Badge, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
const { TabPane } = Tabs

interface FilteredQueues {
  [InspectionInventoryStatus.Unknown]: InspectionInventory[]
  [InspectionInventoryStatus.Complete]: InspectionInventory[]
  [InspectionInventoryStatus.Incomplete]: InspectionInventory[]
}

interface InventoryPaneProps {
  Inspection: Inspection
}
interface InventoryPaneState {
  updating: boolean
  Inventory: InspectionInventory[]
  ActiveQueue: InspectionInventoryStatus
}

export class InventoryPane extends React.Component<
  InventoryPaneProps,
  InventoryPaneState
> {
  ifInspection: (payload: any) => boolean
  updateInventory: (payload: InspectionInventory[]) => void

  constructor(props: InventoryPaneProps) {
    super(props)

    const state: InventoryPaneState = {
      updating: false,
      Inventory: [],
      ActiveQueue: InspectionInventoryStatus.Unknown
    }
    this.state = state

    this.ifInspection = (payload: string) =>
      payload === this.props.Inspection._id
    this.updateInventory = (payload: InspectionInventory[]) => {
      this.setState({
        Inventory: payload
      })
    }
  }

  componentDidMount() {
    this.getInventory(this.props.Inspection._id)
  }

  componentDidUpdate(prevProps: InventoryPaneProps) {
    const { _id: id } = this.props.Inspection
    const { _id: oldId } = prevProps.Inspection
    if (id !== oldId) {
      this.setState({
        Inventory: []
      })
      this.getInventory(id)
    }
  }

  private async getInventory(inspectionId: string) {
    try {
      InsInvSvc.off(
        InspectionInventoryOperations.GetByInspectionId,
        this.ifInspection,
        this.updateInventory
      )
      this.setState({
        updating: true
      })
      let Inventory = await InsInvSvc.sendRequest(
        InspectionInventoryOperations.GetByInspectionId,
        inspectionId
      )
      if (Inventory.length === 0) {
        await initializeInventory(this.props.Inspection)
        await InsInvSvc.sendRequest(InspectionInventoryOperations.Merge, {
          startedOn: Date.now()
        } as Partial<Inspection>).catch((e) => e)
        Inventory = await InsInvSvc.sendRequest(
          InspectionInventoryOperations.GetByInspectionId,
          inspectionId
        )
      }
      this.setState({
        updating: false,
        Inventory,
        ActiveQueue: InspectionInventoryStatus.Unknown
      })
      InsInvSvc.on(
        InspectionInventoryOperations.GetByInspectionId,
        this.ifInspection,
        this.updateInventory
      )
    } catch (e) {
      console.error(e)
      message.error('Could not load inventory.')
    }
  }

  componentWillUnmount() {
    InsInvSvc.off(
      InspectionInventoryOperations.GetByInspectionId,
      this.ifInspection,
      this.updateInventory
    )
  }

  setCurrentTab(key: InspectionInventoryStatus) {
    this.setState({
      ActiveQueue: key
    })
  }

  render() {
    const grouping: FilteredQueues = {
      [InspectionInventoryStatus.Unknown]: [],
      [InspectionInventoryStatus.Complete]: [],
      [InspectionInventoryStatus.Incomplete]: []
    }
    const queues = this.state.Inventory.reduce((accum, item) => {
      accum[item.status].push(item)
      return accum
    }, grouping)
    const queue = queues[this.state.ActiveQueue]
    return (
      <>
        <Tabs
          activeKey={this.state.ActiveQueue}
          onChange={(key) =>
            this.setCurrentTab(key as InspectionInventoryStatus)
          }
          tabBarStyle={{
            marginBottom: '0px'
          }}
        >
          <TabPane
            tab={
              <>
                Current{' '}
                <Badge
                  style={{
                    backgroundColor: '#e6e6e6',
                    color: '#999999',
                    fontSize: '10px'
                  }}
                  count={queues[InspectionInventoryStatus.Unknown].length}
                />
              </>
            }
            key={InspectionInventoryStatus.Unknown}
          />
          <TabPane
            tab={
              <>
                Complete{' '}
                <Badge
                  style={{ backgroundColor: '#52c41a', fontSize: '10px' }}
                  count={queues[InspectionInventoryStatus.Complete].length}
                />
              </>
            }
            key={InspectionInventoryStatus.Complete}
          />
          <TabPane
            tab={
              <>
                Missing{' '}
                <Badge
                  style={{ fontSize: '10px' }}
                  count={queues[InspectionInventoryStatus.Incomplete].length}
                />
              </>
            }
            key={InspectionInventoryStatus.Incomplete}
          />
        </Tabs>
        {this.state.updating ? (
          <LoadingOutlined />
        ) : (
          <InventoryQueue
            {...this.props}
            ActiveQueue={this.state.ActiveQueue}
            Inventory={queue}
          />
        )}
      </>
    )
  }
}
