import * as React from 'react'
import { Modal, Input, Tag } from 'antd'
import {
  titleCase,
  normalizeName,
  getTypeTag,
  getNormalizedColorName
} from './inventoryItemUtils'
import { InspectionInventoryItem } from '@/loaders/InspectionInventoryLoader'

const { TextArea } = Input

interface ModalProps extends InspectionInventoryItem {
  initialNote: string
  visible: boolean
  onNoteChange(output: string): void
  onCancel(evt: any): void
}

interface ModalState {
  note: string
}

export class InventoryNoteModal extends React.Component<
  ModalProps,
  ModalState
> {
  constructor(props: ModalProps) {
    super(props)
    this.state = {
      note: this.props.initialNote
    }
  }

  handleOk() {
    if (this.state.note !== this.props.initialNote) {
    }
  }

  render() {
    const colorName = getNormalizedColorName(this.props.color.common)
    const tag = getTypeTag(this.props.inventory.type)
    return (
      <Modal
        title={this.props.initialNote ? 'Edit Note' : 'Add Note'}
        visible={this.props.visible}
        onOk={() => this.props.onNoteChange(this.state.note)}
        onCancel={(evt: any) => this.props.onCancel(evt)}
      >
        <p>
          {titleCase(colorName)} {normalizeName(this.props.part.name)}{' '}
          {tag.include && <Tag color={tag.color}>{tag.text}</Tag>}
        </p>
        <TextArea
          value={this.state.note}
          onChange={(evt) =>
            this.setState({
              note: evt.target.value
            })
          }
        />
      </Modal>
    )
  }
}
