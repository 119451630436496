export enum SetPieceType {
  Regular = 'reg',
  Extra = 'extra',
  Alternate = 'alternate',
  Counter = 'counter',
  Assembly = 'assembly',
  Stickered = 'stickered',
  Instructions = 'instructions',
  Minifigure = 'minifig',
  Sticker = 'sticker',
  Unknown = 'unknown'
}

const commonStickerIndicators = [
  '&#40;stickers&#41;',
  '&#40;stickers&#41;',
  '(stickers)',
  '(stickers)'
]

export function parseSetPieceType(
  type: string,
  partCode: string,
  partName: string
): SetPieceType {
  type = type.toLowerCase()
  partCode = partCode.toLowerCase()
  partName = partName.toLowerCase()
  switch (type) {
    case 'electric, battery box':
    case 'reg':
      if (partName.indexOf('sticker') === 0) {
        return SetPieceType.Sticker
      }
      if (commonStickerIndicators.find((x) => partName.indexOf(x) > -1)) {
        return SetPieceType.Stickered
      }
      return SetPieceType.Regular
    case 'extra':
      return SetPieceType.Extra
    case 'alternate':
      return SetPieceType.Alternate
    case 'instructions':
      return SetPieceType.Instructions
    case 'minifig':
      return SetPieceType.Minifigure
    case 'sticker':
      return SetPieceType.Sticker
    case 'counter':
      const isPatterned = partCode.indexOf('p') > -1
      if (isPatterned) {
        return SetPieceType.Stickered
      }
      const isAssembly = partCode.indexOf('c') > -1
      if (isAssembly) {
        return SetPieceType.Assembly
      }
      return SetPieceType.Counter
    default:
      return SetPieceType.Unknown
  }
}

export interface SetPiece {
  _id?: string
  _rev?: string
  id: number
  set_id: number
  color_id: number
  part_id: number
  piece_id: number
  parent_piece_id?: number
  type: SetPieceType
  quantity: number
  hasInventory: boolean
  name?: string
  relying_set_piece_id?: number[]
}
