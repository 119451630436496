import { Part } from '@/models'
import PartService from '@/services/parts'
import { ShareDataSourceRecord, SupportedDataTypes } from '../Context'
import { Reducer } from '../reducers'

type SetImageDataRecord = ShareDataSourceRecord<Part, number>

function isPart(
  record: ShareDataSourceRecord<any>
): record is SetImageDataRecord {
  return record.dependencySignature.type === SupportedDataTypes.Part
}

export const PartReducer: Reducer<Part, number> = (record) => {
  return {
    handled: isPart(record),
    resolve: (signature) => {
      return PartService.getById(signature.identifier)
    }
  }
}
