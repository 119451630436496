interface OfflineOperationSource<T> {
  operation: string
  provider: (payload: any) => Promise<T[]>
}

interface OfflineOperationListener<T> {
  operation: string
  match: (payload: any) => boolean
  callback: (data: T[]) => void
}

export interface CacheResolver<T> {
  resolve: (operation: string, payload: any) => Promise<T[]>
  cache: (operation: string, payload: any, data: T[]) => void
}

interface SendRequest {
  operation: string
  payload: any
}

type PipelineResult<T> = Promise<T[]> & {
  cacheResolver?: Promise<T[]>
}

export abstract class OfflineService<T> {
  constructor(cacheResolver: CacheResolver<T>) {
    this.queue = new Map<SendRequest, Promise<T[]>>()
    this.cacheResolver = cacheResolver
    this.sources = []
    this.listeners = []
    this.resolver = (offline, source) => offline.concat(source)
  }

  name: string
  cacheResolver: CacheResolver<T>
  sources: OfflineOperationSource<T>[]
  listeners: OfflineOperationListener<T>[]
  resolver: (sourceA: T[], sourceB: T[]) => T[]

  registerSource(
    operation: string,
    provider: (payload: any) => Promise<T[]>
  ): OfflineService<T> {
    this.sources.push({
      operation: operation,
      provider: provider
    })

    return this
  }

  resolveWith(
    conflictResolver: (offlineSource: T[], source: T[]) => T[]
  ): OfflineService<T> {
    this.resolver = conflictResolver
    return this
  }

  private executePipeline(operation: string, payload: any): PipelineResult<T> {
    const cache = this.cacheResolver
      .resolve(operation, payload)
      .then((data) => {
        this.emit(operation, payload, data)
        return data
      })

    const requests = this.sources.filter(
      (source) => source.operation === operation
    )

    let requestHandle: PipelineResult<T> = cache
    for (let req of requests) {
      requestHandle = requestHandle.then((offline) => {
        return req
          .provider(payload)
          .then((data) => {
            let resolved = this.resolver(offline, data)
            this.emit(operation, payload, resolved)
            return resolved
          })
          .catch((error) => {
            if (!error) {
              return Promise.reject(
                new Error('No offline value available either')
              )
            }
            console.error(error)
            return offline
          })
      })
    }
    requestHandle['cacheResolver'] = cache
    return requestHandle
  }

  queue: Map<SendRequest, Promise<T[]>>

  private checkInQueue(req: SendRequest): boolean {
    for (const existing of this.queue.keys()) {
      if (
        req.operation == existing.operation &&
        req.payload == existing.payload
      ) {
        return true
      }
    }
    return false
  }

  private chainRequest(req: SendRequest): Promise<T[]> {
    for (const [existing, promise] of this.queue) {
      if (
        req.operation == existing.operation &&
        req.payload == existing.payload
      ) {
        return new Promise((resolve, reject) => {
          promise
            .then((r) => {
              resolve(r)
              return r
            })
            .catch((e) => {
              reject(e)
              throw e
            })
        })
      }
    }
    return Promise.resolve([])
  }

  sendRequest(operation: string, payload: any): Promise<T[]> {
    const r: SendRequest = {
      operation: operation,
      payload: payload
    }

    if (this.checkInQueue(r)) {
      return this.chainRequest(r)
    }

    let requestHandle = this.executePipeline(operation, payload)
    this.queue.set(r, requestHandle)

    return requestHandle.then((data) => {
      this.cacheResolver.cache(operation, payload, data)
      this.queue.delete(r)
      return data
    })
  }

  private emit(operation: string, payload: any, data: T[]): void {
    this.listeners
      .filter(
        (listener) =>
          listener.operation === operation && listener.match(payload)
      )
      .map((source) => source.callback(data))
  }

  on(
    operation: string,
    match: (payload: any) => boolean,
    callback: (data: T[]) => void
  ) {
    this.listeners.push({
      operation: operation,
      match: match,
      callback: callback
    })
  }

  once(
    operation: string,
    match: (payload: any) => boolean,
    callback: (data: T[]) => void
  ) {
    const wrappedCallback = (data: T[]) => {
      this.off(operation, match, wrappedCallback)
      callback(data)
    }
    this.listeners.push({
      operation: operation,
      match: match,
      callback: wrappedCallback
    })
  }

  off(
    operation: string,
    match: (payload: any) => boolean,
    callback: (data: T[]) => void
  ) {
    const index = this.listeners.findIndex((listener) => {
      return (
        listener.operation === operation &&
        listener.match === match &&
        listener.callback === callback
      )
    })

    this.listeners.splice(index, 1)
  }
}
