import * as React from 'react'
import { Inspection, SetRecord, InspectionInventory } from '@/models'
import {
  default as InsInvSvc,
  InspectionInventoryOperations
} from '@/services/inspection_inventory'
import { LoadingOutlined } from '@ant-design/icons'

export interface LoadedInventoryProps {
  Inspection: Inspection
  Set: SetRecord
  Inventory: InspectionInventory[]
}

interface MissingPiecesLoaderProps {
  Inspection: Inspection
  Set: SetRecord
  render(loadedState: LoadedInventoryProps): JSX.Element
}

interface MissingPiecesState {
  loading: boolean
  inventory: InspectionInventory[]
}

export class MissingPiecesLoader extends React.Component<
  MissingPiecesLoaderProps,
  MissingPiecesState
> {
  ifInspection: (payload: any) => boolean
  updateInventory: (payload: InspectionInventory[]) => void

  constructor(props: MissingPiecesLoaderProps) {
    super(props)
    this.state = {
      loading: false,
      inventory: []
    }
    this.ifInspection = (payload: string) =>
      payload === this.props.Inspection._id

    this.updateInventory = (payload: InspectionInventory[]) => {
      this.setState({
        inventory: payload
      })
    }
  }

  componentDidUpdate(prevProps: MissingPiecesLoaderProps) {
    const { _id: id } = this.props.Inspection
    const { _id: oldId } = prevProps.Inspection
    if (id !== oldId) {
      this.getInventory(id)
    }
  }

  private async getInventory(inspectionId: string) {
    this.setState({
      loading: true
    })
    await InsInvSvc.sendRequest(
      InspectionInventoryOperations.GetByInspectionId,
      inspectionId
    ).catch((e) => e)
  }

  componentDidMount() {
    InsInvSvc.on(
      InspectionInventoryOperations.GetByInspectionId,
      this.ifInspection,
      this.updateInventory
    )
    this.getInventory(this.props.Inspection._id)
  }

  componentWillUnmount() {
    InsInvSvc.off(
      InspectionInventoryOperations.GetByInspectionId,
      this.ifInspection,
      this.updateInventory
    )
  }

  render() {
    const loaded = !!this.state.inventory
    const Inventory = this.state.inventory
    const { Set, Inspection } = this.props
    return loaded ? (
      this.props.render({
        Inventory,
        Inspection,
        Set
      })
    ) : (
      <div>
        <LoadingOutlined /> Loading Inventory
      </div>
    )
  }
}
