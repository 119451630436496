import { OfflineService, CacheResolver } from '@/services/interfaces'
import Api from '@/services/api'
import PouchDB from 'pouchdb'
import { PieceImage, PieceImageDoc } from '@/models/PieceImage'
import pouchFind from 'pouchdb-find'
import { installIndexOnProperty } from '@/services/indexer'
PouchDB.plugin(pouchFind)

export enum PieceImageOperations {
  Get = 'Get',
  Add = 'Add'
}

class PieceImageResolver implements CacheResolver<PieceImage> {
  db: PouchDB.Database<PieceImage>

  constructor() {
    this.db = new PouchDB<PieceImage>('piece_images', { revs_limit: 1 })

    this.db.getIndexes().then((result) => {
      installIndexOnProperty(this.db, result.indexes, 'piece_id')
    })
  }

  async resolve(operation: string, payload: any): Promise<Array<PieceImage>> {
    if (operation == PieceImageOperations.Get) {
      const params = payload as {
        pieceId: string
      }
      const data = await this.db.find({
        selector: {
          piece_id: params.pieceId
        }
      })

      const images = data.docs.map((d) => {
        return this.db.getAttachment(d._id, d.src).then((blob) => {
          const PieceImage: PieceImage = {
            _id: d._id,
            piece_id: d.piece_id,
            url: URL.createObjectURL(blob),
            blob: blob,
            src: d.src,
            contentType: d.contentType
          }
          return PieceImage
        })
      })
      return Promise.all(images)
    }

    if (operation === PieceImageOperations.Add) {
      const image = payload as PieceImage
      const file = await Api.fetchFile(image.src)
      image.contentType = file.contentType
      image.blob = file.blob
      image.url = URL.createObjectURL(file.blob)

      await this.cache(PieceImageOperations.Get, image.piece_id, [image])
      return this.resolve(PieceImageOperations.Get, image.piece_id)
    }

    return Promise.resolve([])
  }

  async cache(operation: string, _: any, data: PieceImage[]) {
    if (operation === PieceImageOperations.Get) {
      for (let image of data) {
        const existing = await this.db
          .get(image.piece_id.toString(), {
            attachments: true
          })
          .catch(() => null)

        // Downgrade interface
        const doc: PieceImageDoc = {
          piece_id: image.piece_id,
          src: image.src,
          contentType: image.contentType
        }

        if (existing) {
          doc._id = existing._id
          doc._rev = existing._rev
        } else {
          doc._id = image.piece_id.toString()
        }
        const updated = await this.db.put(doc)
        await this.db.putAttachment(
          doc._id,
          doc.src,
          updated.rev,
          image.blob,
          doc.contentType
        )
      }
    }
  }
}

class pieceImageService extends OfflineService<PieceImage> {
  constructor() {
    super(new PieceImageResolver())
    this.name = 'PieceImageService'
    this.registerSource(
      PieceImageOperations.Get,
      ({ pieceId, source }: { pieceId: string; source?: string }) =>
        Api.getPieceImage(pieceId, source)
    )
    this.resolveWith((_, online) => online)
  }

  public async getPieceImage(pieceId: string, source?: string) {
    const [result] = await this.sendRequest(PieceImageOperations.Get, {
      pieceId,
      source
    })
    return result
  }
}

const PieceImageService = new pieceImageService()

export default PieceImageService
