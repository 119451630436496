import { CollectionCard } from '@/components/CollectionCard'
import { useCollectionsContext } from '@/providers/Collections/hooks'
import { DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import { AddCollectionCard } from './AddCollectionCard'

interface Props {
  limit?: number
}

export const CollectionViewer: React.FC<Props> = ({ limit }) => {
  const [showMore, setShowMore] = React.useState<boolean>(false)
  const collectionsContext = useCollectionsContext()
  const isInitialState = !collectionsContext.collections

  React.useEffect(() => {
    if (isInitialState) {
      collectionsContext.populate()
    }
  }, [isInitialState])

  const sorted = collectionsContext.collections?.sort(
    (a, b) => b.collection.createdOn - a.collection.createdOn
  )

  return (
    <Content style={{ padding: '1rem' }}>
      <Row gutter={16}>
        <Col sm={12} md={8} lg={6} xl={4}>
          <AddCollectionCard />
        </Col>
        {isInitialState ? (
          <LoadingOutlined />
        ) : (
          sorted
            .slice(0, limit || collectionsContext.collections.length)
            .map(({ collection }) => {
              return (
                <Col
                  sm={12}
                  md={8}
                  lg={6}
                  xl={4}
                  key={`collection-card-${collection._id}`}
                  style={{ marginBottom: '1rem' }}
                >
                  <CollectionCard collection={collection} />
                </Col>
              )
            })
        )}
      </Row>
      <Row gutter={16}>
        {isInitialState ? (
          <LoadingOutlined />
        ) : showMore ? (
          sorted
            .slice(limit || collectionsContext.collections.length)
            .map(({ collection }) => {
              return (
                <Col
                  span={24}
                  md={8}
                  lg={6}
                  xl={4}
                  key={`collection-card-${collection._id}`}
                  style={{ marginBottom: '1rem' }}
                >
                  <CollectionCard collection={collection} />
                </Col>
              )
            })
        ) : null}
      </Row>
      <Row justify="center">
        <Button type="text" onClick={() => setShowMore(!showMore)}>
          {showMore ? <UpOutlined /> : <DownOutlined />} Show{' '}
          {showMore ? 'Less' : 'More'}{' '}
          {showMore ? <UpOutlined /> : <DownOutlined />}
        </Button>
      </Row>
    </Content>
  )
}
