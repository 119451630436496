import * as React from 'react'
import { InputNumber, Button, Dropdown, Tag, Row, Col, Popover } from 'antd'
import { default as InspectionInventoryService } from '@/services/inspection_inventory'
import { InspectionInventoryStatus, SetPieceType } from '@/models'
import {
  titleCase,
  normalizeName,
  getTypeTag,
  getNormalizedColorName,
  getActiveInventoryIdFromRoute
} from './inventoryItemUtils'
import { InventoryItemThumbnail } from './InventoryItemThumbnail'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { MissingSetPieceMenu } from './MissingSetPieceMenu'
import { InventoryNoteModal } from './InventoryNoteModal'
import { SubpieceMissingButton } from './SubpieceMissingButton'
import { InspectionInventoryItem } from '@/loaders/InspectionInventoryLoader'
import { CheckOutlined, DownOutlined, FormOutlined } from '@ant-design/icons'

interface InventoryListItemProps
  extends InspectionInventoryItem,
    RouteComponentProps {
  nextInspectionItemId?: string
}

interface InventoryListItemState {
  noteModalVisible: boolean
  removing: boolean
}

class inventoryListItem extends React.Component<
  InventoryListItemProps,
  InventoryListItemState
> {
  private completeButtonRef: React.RefObject<HTMLElement>

  constructor(props: InventoryListItemProps) {
    super(props)
    this.completeButtonRef = React.createRef<HTMLElement>()
    this.state = {
      noteModalVisible: false,
      removing: false
    }
  }

  handleChange(evt: React.ReactText) {
    this.setMissingQuantity(parseInt(evt.toString()) || 0)
  }

  setMissingQuantity(qty: number) {
    if (this.props.inventory.status !== InspectionInventoryStatus.Incomplete) {
      this.setState({
        removing: true
      })
    }

    InspectionInventoryService.setMissingPiece(
      this.props.inventory._id,
      qty
    ).then(() => {
      this.tryRouteToNext()
    })
  }

  setComplete() {
    if (this.props.inventory.status !== InspectionInventoryStatus.Complete) {
      this.setState({
        removing: true
      })
    }

    InspectionInventoryService.setComplete(this.props.inventory._id).then(
      () => {
        this.tryRouteToNext()
      }
    )
  }

  tryRouteToNext() {
    if (this.props.nextInspectionItemId) {
      setTimeout(() => {
        this.props.history.replace(
          this.props.location.pathname +
            `?inventory_id=${this.props.nextInspectionItemId}`
        )
      }, 300)
    }
  }

  render() {
    const activeInventoryId = getActiveInventoryIdFromRoute(this.props)
    const isActive = this.props.inventory._id === activeInventoryId
    const colorName = getNormalizedColorName(this.props.color.common)
    const tag = getTypeTag(this.props.inventory.type)
    const noteAnchor = (
      <a onClick={() => this.setState({ noteModalVisible: true })}>
        {this.props.inventory.note && <FormOutlined />}{' '}
        {this.props.inventory.note ? 'Edit' : 'Add'} Note
      </a>
    )
    return (
      <Row
        className={`inventoryItemRow ${
          isActive ? 'inventoryItemRow--active' : ''
        } ${this.state.removing ? 'inventoryItemRow--fadeout' : ''}`}
        role="row"
      >
        <Col role="gridcell" tabIndex={-1}>
          <InventoryItemThumbnail
            {...this.props}
            onclick={() => {
              this.props.history.replace(
                this.props.history.location.pathname +
                  '?inventory_id=' +
                  this.props.inventory._id
              )
            }}
          />
        </Col>
        <Col className="inventoryItemQuantity" role="gridcell" tabIndex={-1}>
          &times;
          {this.props.setPiece.quantity}
        </Col>
        <Col
          flex="auto"
          style={{ maxWidth: 'calc(100% - 190px)' }}
          role="gridcell"
          tabIndex={-1}
        >
          <div>
            <h4>
              {titleCase(colorName)} {normalizeName(this.props.part.name)}{' '}
              {tag.include && <Tag color={tag.color}>{tag.text}</Tag>}
              {this.props.setPiece.id} / {this.props.setPiece.parent_piece_id}
            </h4>
          </div>
          <Row align="middle" gutter={10} style={{ width: '100%' }}>
            <Col>
              <Button
                size="small"
                type="primary"
                ref={this.completeButtonRef}
                onClick={() => this.setComplete()}
              >
                <CheckOutlined />
                Complete
              </Button>
            </Col>
            <Col>
              {this.props.setPiece.quantity > 1 ? (
                <Dropdown
                  overlay={
                    <MissingSetPieceMenu
                      SetPiece={this.props.setPiece}
                      onClick={(i) => this.setMissingQuantity(i)}
                    />
                  }
                >
                  <Button size="small" danger>
                    Missing <DownOutlined />
                  </Button>
                </Dropdown>
              ) : (
                <Button
                  size="small"
                  danger
                  onClick={() => this.setMissingQuantity(1)}
                >
                  Missing
                </Button>
              )}
            </Col>
            <Col>
              {this.props.inventory.status ==
                InspectionInventoryStatus.Incomplete && (
                <InputNumber
                  value={this.props.inventory.missing_quantity}
                  onChange={(evt) => this.handleChange(evt)}
                  min={0}
                  max={this.props.setPiece.quantity}
                  step={1}
                  placeholder="Qty Needed"
                />
              )}
            </Col>
            <Col>
              {this.props.setPiece.hasInventory &&
                this.props.setPiece.relying_set_piece_id?.length &&
                this.props.inventory.type !== SetPieceType.Assembly &&
                this.props.inventory.status ===
                  InspectionInventoryStatus.Unknown && (
                  <SubpieceMissingButton
                    {...this.props}
                    inspectionId={this.props.inventory.inspection_id}
                    onMissingCreated={() => this.setComplete()}
                  />
                )}
            </Col>
            <Col
              style={{
                flexGrow: 1,
                textAlign: 'right',
                paddingRight: '10px'
              }}
            >
              <InventoryNoteModal
                {...this.props}
                initialNote={this.props.inventory.note}
                visible={this.state.noteModalVisible}
                onNoteChange={(note) => {
                  if (note !== this.props.inventory.note) {
                    InspectionInventoryService.updateNote(
                      this.props.inventory._id,
                      note
                    )
                  }
                  this.setState({
                    noteModalVisible: false
                  })
                }}
                onCancel={() =>
                  this.setState({
                    noteModalVisible: false
                  })
                }
              />
              {this.props.inventory.note ? (
                <Popover
                  content={this.props.inventory.note}
                  placement="topRight"
                >
                  {noteAnchor}
                </Popover>
              ) : (
                noteAnchor
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export const InventoryListItem = withRouter(inventoryListItem)
