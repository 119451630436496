import * as React from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { InspectionLoader } from '@/components/Inspection/InspectionLoader'
import { InspectionPane } from '@/components/Inspection/InspectionPane'
import { FullPage } from '@/components/layouts/FullPage'
import { Link } from 'react-router-dom'
import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons'
import { InspectionMeta } from '@/components/Inspection/InspectionMeta'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

interface InspectionRouteMatch {
  id: string
}

export function InspectionFullPage(
  props: RouteComponentProps<InspectionRouteMatch>
) {
  const screens = useBreakpoint()
  const width = screens.lg ? '400px' : screens.md ? '25vw' : 'auto'
  const history = useHistory()
  const id = props.match.params.id
  return (
    <InspectionLoader
      id={id}
      render={({ Inspection, Set }) => {
        return (
          <FullPage
            menuItems={[
              history.length && screens.sm && (
                <a onClick={() => history.goBack()}>
                  <ArrowLeftOutlined />
                  Back
                </a>
              ),
              <div
                style={{
                  minWidth: width,
                  lineHeight: '1.5rem',
                  minHeight: '64px',
                  paddingTop: '10px'
                }}
              >
                <InspectionMeta inspection={Inspection} set={Set} />
              </div>,
              <Link to={`/print/inspection/${id}`}>
                <PrinterOutlined /> Print Missing
              </Link>
            ]}
          >
            <InspectionPane inspection={Inspection} set={Set} hideMeta={true} />
          </FullPage>
        )
      }}
    />
  )
}
