import * as React from 'react'
import { Button, Modal } from 'antd'
import { SetPiece, InspectionInventory } from '@/models'
import SetPieceService, { SetPieceOperations } from '@/services/set_pieces'
import { SubPieceMissingItem } from './SubPieceMissingItem'
import {
  createInspectionInventory,
  InspectionInventoryStatus
} from '@/models/InspectionInventory'
import InspectionInventoryService from '@/services/inspection_inventory'
import { InspectionInventoryItem } from '@/loaders/InspectionInventoryLoader'
import { SelectOutlined } from '@ant-design/icons'

interface Props extends InspectionInventoryItem {
  inspectionId: string
  onMissingCreated(): void
}

interface SubPieceState {
  visible: boolean
  subPieces: SetPiece[]
  loading: boolean
  queue: InspectionInventory[]
}

export class SubpieceMissingButton extends React.Component<
  Props,
  SubPieceState
> {
  ifParentSetPieceId: (pieces: number[]) => boolean
  updateSetPieces: (pieces: SetPiece[]) => void

  constructor(props: Props) {
    super(props)
    this.state = {
      visible: false,
      subPieces: [],
      loading: true,
      queue: []
    }

    this.ifParentSetPieceId = (pieces) => {
      const watching = this.props.setPiece.relying_set_piece_id
      return pieces.every((p) => watching.indexOf(p) !== -1)
    }

    this.updateSetPieces = (pieces) => {
      this.setState({
        subPieces: pieces
      })
    }
  }

  componentDidMount() {
    SetPieceService.on(
      SetPieceOperations.GetMany,
      this.ifParentSetPieceId,
      this.updateSetPieces
    )
  }

  componentWillUnmount() {
    SetPieceService.off(
      SetPieceOperations.GetMany,
      this.ifParentSetPieceId,
      this.updateSetPieces
    )
  }

  handleOpenModal() {
    this.setState({
      visible: true
    })
    SetPieceService.getByIds(this.props.setPiece.relying_set_piece_id)
      .catch(() => {})
      .then(() => {
        this.setState({
          loading: false
        })
      })
  }

  render() {
    return (
      <div>
        <Modal
          title="Subpiece Missing"
          width={960}
          visible={this.state.visible}
          okButtonProps={{
            disabled: this.state.queue.length === 0
          }}
          okText={`Mark Missing (${this.state.queue.length})`}
          onOk={() => {
            this.setState({
              visible: false
            })
            this.tryCreateInspectionInventory()
          }}
          onCancel={() => {
            this.setState({
              visible: false
            })
          }}
        >
          <div style={{ maxHeight: '66vh', overflow: 'auto' }}>
            {this.state.subPieces.map((piece) => {
              let item = this.state.queue.find(
                (ii) => ii.set_piece_id === piece.id
              )
              return (
                <SubPieceMissingItem
                  key={`missing-subpiece-${piece.id}`}
                  setPiece={piece}
                  inspectionInventory={item}
                  tryQueue={(setPieceId, qty) => this.tryQueue(setPieceId, qty)}
                />
              )
            })}
          </div>
        </Modal>
        <Button
          icon={<SelectOutlined />}
          onClick={() => this.handleOpenModal()}
        >
          Mark Partially Missing
        </Button>
      </div>
    )
  }

  private tryQueue(setPieceId: number, qty: number) {
    if (!this.state.subPieces) return

    if (qty === 0) return this.tryRemoveQueue(setPieceId)

    let item = this.state.queue.find((ii) => ii.set_piece_id === setPieceId)
    if (!item) {
      const subPiece = this.state.subPieces.find((p) => p.id === setPieceId)
      item = createInspectionInventory(this.props.inspectionId, subPiece)
    }
    item.missing_quantity = qty
    item.status = InspectionInventoryStatus.Incomplete
    item.note = 'Missing from ' + this.props.setPiece.name
    this.setState({
      queue: [
        ...this.state.queue.filter((ii) => ii.set_piece_id !== setPieceId),
        item
      ]
    })
  }

  private tryRemoveQueue(setPieceId: number) {
    this.setState({
      queue: [
        ...this.state.queue.filter((ii) => ii.set_piece_id !== setPieceId)
      ]
    })
  }

  private async tryCreateInspectionInventory() {
    await InspectionInventoryService.addMany(this.state.queue)
    this.setState({
      queue: []
    })
    this.props.onMissingCreated()
  }
}
