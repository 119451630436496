import * as React from 'react'
import { LoadedInventoryProps } from '@/components/Inspection/MissingPiecesLoader'
import { MissingPieceListItem } from '@/components/Inspection/MissingPieceListItem'
import { Row, Col, Result } from 'antd'
import { InspectionInventoryLoader } from '@/loaders/InspectionInventoryLoader'
import { InspectionInventory } from '@/models'

enum SortMethod {
  color
}

interface MissingPieceListProps extends LoadedInventoryProps {
  sortMethod?: SortMethod
}

export function sortByColor(a: InspectionInventory, b: InspectionInventory) {
  if (a.color_id > b.color_id) {
    return -1
  }
  if (a.color_id < b.color_id) {
    return 1
  }
  return 0
}

export function MissingPiecesList(props: MissingPieceListProps) {
  const missingInventory = props.Inventory.filter((inv) => {
    return inv.missing_quantity > 0
  })
  if (!missingInventory.length) {
    return <Result title="No Missing Pieces Marked" />
  }
  const pageItemSize = 8
  const pages: { missingInventory: InspectionInventory[] }[] = []
  const pageCount = Math.ceil(missingInventory.length / pageItemSize)
  for (let i = 0; i < pageCount; i++) {
    pages.push({
      missingInventory: missingInventory.slice(
        i * pageItemSize,
        (i + 1) * pageItemSize
      )
    })
  }
  return (
    <>
      {pages.map(({ missingInventory }, index) => {
        return (
          <MisssingPiecesPage
            setNumber={props.Inspection.setNumber}
            key={`missing-inv-page-${index}`}
            missingInventory={missingInventory}
          />
        )
      })}
    </>
  )
}

const MisssingPiecesPage: React.FC<{
  setNumber: string
  missingInventory: InspectionInventory[]
}> = ({ missingInventory, setNumber }) => {
  return (
    <>
      <Row
        gutter={10}
        style={{
          marginRight: 0,
          marginLeft: 0
        }}
        className="printMissingPiecePage"
      >
        {missingInventory
          .sort((a, b) => {
            return sortByColor(a, b)
          })
          .map((item) => {
            return (
              <Col
                key={`missing-item-${item._id}`}
                span={12}
                className="printSafeItem"
              >
                <InspectionInventoryLoader
                  inventory_item_id={item._id}
                  inventory={item}
                >
                  {(data) => (
                    <MissingPieceListItem
                      inspectionInventoryItem={data}
                      setNumber={setNumber}
                    />
                  )}
                </InspectionInventoryLoader>
              </Col>
            )
          })}
      </Row>
    </>
  )
}
