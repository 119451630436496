import * as React from 'react'
import { InputNumber, Button, Row, Col, Popover, Form, message } from 'antd'
import { default as InspectionInventoryService } from '@/services/inspection_inventory'
import { InspectionInventoryStatus, SetPieceType } from '@/models'
import { InventoryNoteModal } from './InventoryNoteModal'
import { SubpieceMissingButton } from './SubpieceMissingButton'
import { InspectionInventoryItem } from '@/loaders/InspectionInventoryLoader'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {
  CheckOutlined,
  CloseOutlined,
  FormOutlined,
  MessageOutlined
} from '@ant-design/icons'

interface InventoryListItemProps
  extends InspectionInventoryItem,
    RouteComponentProps {
  nextInspectionItemId?: string
  submitOnBlur?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
}

interface InventoryListItemState {
  noteModalVisible: boolean
  missingCount: number
  lastUpdate: number
}

export class _InventoryItemDetailsActions extends React.Component<
  InventoryListItemProps,
  InventoryListItemState
> {
  private inputRef: React.RefObject<HTMLInputElement>

  constructor(props: InventoryListItemProps) {
    super(props)
    this.inputRef = props.inputRef || React.createRef<HTMLInputElement>()
    this.state = {
      missingCount: this.props.inventory.missing_quantity || 0,
      noteModalVisible: false,
      lastUpdate: Date.now()
    }
  }

  render() {
    const noteAnchor = (
      <Button
        onClick={() => this.setState({ noteModalVisible: true })}
        icon={
          this.props.inventory.note ? <FormOutlined /> : <MessageOutlined />
        }
      >
        {this.props.inventory.note ? 'Edit Note' : 'Add Note'}
      </Button>
    )

    return (
      <Form onFinish={(evt) => this.trySubmit(evt)}>
        <Row align="middle" gutter={10} style={{ width: '100%' }}>
          <Col>
            <Button
              disabled={this.state.missingCount > 0}
              type="primary"
              size="large"
              onClick={() => this.setComplete()}
              icon={<CheckOutlined />}
            >
              Complete
            </Button>
          </Col>
          <Col>
            <Button
              disabled={this.state.missingCount <= 0}
              danger
              size="large"
              onClick={() => this.setMissingQuantity()}
              icon={<CloseOutlined />}
            >
              Missing
            </Button>
          </Col>
          <Col>
            <InputNumber
              ref={this.inputRef}
              value={this.state.missingCount}
              onChange={(evt) => this.handleChange(evt)}
              onBlur={(evt) => {
                this.props.inventory.missing_quantity !==
                  this.state.missingCount &&
                  this.props.submitOnBlur &&
                  this.trySubmit(evt)
              }}
              style={{ width: '150px' }}
              min={0}
              max={this.props.setPiece.quantity}
              precision={0}
              step={1}
              placeholder="Qty Needed"
            />
          </Col>
          <Col>
            {this.props.setPiece.hasInventory &&
              this.props.setPiece.relying_set_piece_id?.length &&
              this.props.inventory.type !== SetPieceType.Assembly &&
              this.props.inventory.status ===
                InspectionInventoryStatus.Unknown && (
                <SubpieceMissingButton
                  {...this.props}
                  inspectionId={this.props.inventory.inspection_id}
                  onMissingCreated={() => this.setComplete()}
                />
              )}
          </Col>
          <Col
            style={{
              paddingRight: '10px'
            }}
          >
            <InventoryNoteModal
              {...this.props}
              initialNote={this.props.inventory.note}
              visible={this.state.noteModalVisible}
              onNoteChange={(note) => {
                if (note !== this.props.inventory.note) {
                  InspectionInventoryService.updateNote(
                    this.props.inventory._id,
                    note
                  )
                }
                this.setState({
                  noteModalVisible: false
                })
              }}
              onCancel={() =>
                this.setState({
                  noteModalVisible: false
                })
              }
            />
            {this.props.inventory.note ? (
              <Popover content={this.props.inventory.note} placement="topRight">
                {noteAnchor}
              </Popover>
            ) : (
              noteAnchor
            )}
          </Col>
        </Row>
      </Form>
    )
  }

  componentDidMount() {
    this.inputRef.current.focus()
  }

  setComplete() {
    InspectionInventoryService.setComplete(this.props.inventory._id).then(
      () => {
        this.tryRouteToNext()
      }
    )
  }

  tryRouteToNext() {
    const targetId = this.props.nextInspectionItemId
    if (targetId) {
      this.props.history.replace(
        this.props.location.pathname + `?inventory_id=${targetId}`
      )
    }
  }

  handleChange(evt: React.ReactText) {
    this.setState({ missingCount: parseInt(evt.toString()) || 0 })
  }

  setMissingQuantity() {
    InspectionInventoryService.setMissingPiece(
      this.props.inventory._id,
      this.state.missingCount
    ).then(() => {
      this.tryRouteToNext()
    })
  }

  trySubmit(evt: React.FormEvent) {
    evt.cancelable && evt.preventDefault()

    const now = Date.now()
    if (now - this.state.lastUpdate < 500) {
      message.warning('Double submit prevention triggered.')
      return
    }

    if (this.state.missingCount) {
      this.setMissingQuantity()
    } else {
      this.setComplete()
    }
    this.setState({
      lastUpdate: now
    })
  }
}

export const InventoryItemDetailsActions = withRouter(
  _InventoryItemDetailsActions
)
