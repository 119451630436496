import { Part } from '@/models/Part'
import { OfflineService, CacheResolver } from '@/services/interfaces'
import { IDatabase } from '@/database/interface'
import { createIndexForProperty } from '@/services/indexer'
import { Database } from '@/database/indexedDB'
import { KeyStore } from './keystore'
import api from './api'

export enum PartOperations {
  Get = 'Get',
  GetManyByIds = 'GetManyByIds',
  Add = 'Add',
  BulkAdd = 'BulkAdd',
  FindByCode = 'FindByCode'
}

export const PartsDB = new Database<Part>('parts', {
  install: (store) => {
    createIndexForProperty(store, 'id')
    createIndexForProperty(store, 'code')
  }
})

class PartResolver implements CacheResolver<Part> {
  db: IDatabase<Part>
  store: KeyStore<number, Part> = new KeyStore<number, Part>()

  constructor() {
    this.db = PartsDB
  }

  async resolve(operation: string, payload: any): Promise<Part[]> {
    if (operation === PartOperations.Get) {
      const partId = payload as number
      return this.store.get(partId).catch(() => {
        return this.db
          .find({
            selector: {
              id: partId
            }
          })
          .then((data) => {
            return data.docs.map((d) => d as Part)
          })
      })
    }

    if (operation === PartOperations.GetManyByIds) {
      const partIds = payload as number[]
      return this.db
        .find({
          selector: {
            id: {
              $in: partIds
            }
          }
        })
        .then((data) => data.docs.map((d) => d as Part))
    }

    if (operation === PartOperations.Add) {
      const Part = payload as Part
      Part._rev = Part._rev || (1).toString()
      await this.cache(PartOperations.Get, Part.id, [Part])
      return this.resolve(PartOperations.Get, Part.id)
    }

    if (operation === PartOperations.BulkAdd) {
      const parts = payload as Part[]
      await this.cache(PartOperations.BulkAdd, null, parts)
    }

    if (operation === PartOperations.FindByCode) {
      const Part_code = payload as number
      return this.db
        .find({
          selector: {
            code: Part_code
          }
        })
        .then((data) => data.docs.map((d) => d as Part))
    }

    return Promise.resolve([])
  }

  async cache(operation: string, payload: any, data: Part[]) {
    if (!data) {
      return
    }

    if (operation === PartOperations.BulkAdd) {
      const part_ids = data.map((p) => p.id.toString())
      const existingDocs = await this.db.allDocs({
        keys: part_ids
      })

      const documents = data.map((part) => {
        part._id = part.id.toString()
        const match = existingDocs.rows.find((row) => row.id === part._id)
        if (match && !part._rev) {
          part._rev = match.value.rev
        }
        part._rev = part._rev || (1).toString()
        return part
      })

      await this.db.bulkDocs(documents)
    }

    if (operation === PartOperations.Get) {
      for (let part of data) {
        const Part_id = payload as number
        this.store.set(Part_id, part)
        const docs = await this.db
          .find({
            selector: {
              id: Part_id
            }
          })
          .then((data) => data.docs)

        const [existing] = docs

        if (existing) {
          part._id = existing._id
          part._rev = existing._rev
        } else {
          part._id = Part_id.toString()
        }

        this.db.put(part)
      }
    }

    if (operation === PartOperations.GetManyByIds) {
      for (let part of data) {
        this.store.set(part.id, part)
        const docs = await this.db
          .find({
            selector: {
              id: part.id
            }
          })
          .then((data) => data.docs)

        const [existing] = docs

        if (existing) {
          part._id = existing._id
          part._rev = existing._rev
        } else {
          part._id = part.id.toString()
        }

        this.db.put(part)
      }
    }
  }
}

class Parts extends OfflineService<Part> {
  constructor() {
    super(new PartResolver())
    this.name = 'PartService'
    this.resolveWith((cache, live) => {
      if (cache instanceof Array && cache.length <= live.length) {
        return live
      }
      return cache
    })
    this.registerSource(PartOperations.Get, async (partId: number) => {
      const part = await api.getPartById(partId)
      return [part]
    })
    this.registerSource(
      PartOperations.GetManyByIds,
      async (partIds: number[]) => {
        return await api.getManyParts(partIds)
      }
    )
  }

  getById(partId: number) {
    return this.sendRequest(PartOperations.Get, partId).then(([x]) => x)
  }

  getManyById(partIds: number[]) {
    return this.sendRequest(PartOperations.GetManyByIds, partIds)
  }
}

const PartService = new Parts()

export default PartService
