import { CollectionsDB } from '@/services/collections'
import { InspectionsDb } from '@/services/inspections'
import { InspectionInventoryDB } from '@/services/inspection_inventory'
import { CheckCircleTwoTone, LoadingOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import * as React from 'react'
import { useAsyncCallback } from 'react-async-hook'

interface Props {
  children?: (dataUri: string) => React.ReactNode | React.ReactNodeArray
}

interface ExportState {
  inspectionsCount?: number
  collectionsCount?: number
  inspectionInventoryCount?: number
}

export const ExportsLoader: React.FC<Props> = () => {
  const [state, setState] = React.useState<ExportState>({})

  const loader = useAsyncCallback(async () => {
    const inspections = await InspectionsDb.allDocs({ keys: null })
    setState((currentState) => ({
      ...currentState,
      inspectionsCount: inspections.rows.length
    }))
    const collections = await CollectionsDB.allDocs({ keys: null })
    setState((currentState) => ({
      ...currentState,
      collectionsCount: collections.rows.length
    }))
    const inspectionInventories = await InspectionInventoryDB.allDocs({
      keys: null
    })
    setState((currentState) => ({
      ...currentState,
      inspectionInventoryCount: inspectionInventories.rows.length
    }))
    return {
      inspections,
      collections,
      inspectionInventories
    }
  })

  const dataUri = React.useMemo(() => {
    if (!loader.result) {
      return ''
    }

    const headers: Record<string, number> = {}

    const formatted = {
      headers,
      inspections: loader.result.inspections.rows.map((x) =>
        makeCompactRecord(x.value, headers)
      ),
      collections: loader.result.collections.rows.map((x) =>
        makeCompactRecord(x.value, headers)
      ),
      inspection_inventories: loader.result.inspectionInventories.rows.map(
        (x) => makeCompactRecord(x.value, headers)
      )
    }

    const blob = new Blob([JSON.stringify(formatted)], {
      type: 'application/json'
    })
    return URL.createObjectURL(blob)
  }, [loader.result])

  if (loader.loading) {
    return (
      <>
        <LoadingOutlined />
        {state.inspectionsCount && (
          <div>{state.inspectionsCount} Inspections Exported</div>
        )}
        {state.collectionsCount && (
          <div>{state.collectionsCount} Collections Exported</div>
        )}
        {state.inspectionInventoryCount && (
          <div>
            {state.inspectionInventoryCount} Inspection Inventory Records
            Exported
          </div>
        )}
      </>
    )
  }

  if (!loader.result && !loader.error) {
    return (
      <>
        <h2>Export Inspection Data</h2>
        <p>
          The following will trigger the download of a file that can be imported
          into a new browser or client.
        </p>
        <Button
          onClick={() => {
            loader.execute()
          }}
        >
          Start Export
        </Button>
      </>
    )
  }

  if (loader.error) {
    return <>Could Not Load Data</>
  }

  return (
    <>
      <CheckCircleTwoTone style={{ fontSize: '64px' }} twoToneColor="#52c41a" />
      <h2>Export Ready</h2>
      <a download="Brick_Inspector-export.json" href={dataUri}>
        Download Export Now
      </a>
    </>
  )
}

function makeCompactRecord<T>(x: T, headers: Record<string, number>) {
  const record: Record<number, any> = {}
  for (const property in x) {
    if (!headers.hasOwnProperty(property)) {
      headers[property] = Object.keys(headers).length
    }
    const key = property as keyof T
    record[headers[property]] = x[key]
  }
  return record
}
