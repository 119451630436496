import { SetPieceType, SetPiece } from './SetPiece'

export enum InspectionInventoryStatus {
  Complete = 'complete',
  Incomplete = 'incomplete',
  Unknown = 'unknown'
}
export interface InspectionInventory {
  _id: string
  _rev?: string
  inspection_id: string
  set_piece_id: number
  status: InspectionInventoryStatus
  type: SetPieceType
  piece_id: number
  part_id: number
  color_id: number
  parent_piece_id?: number
  expected_quantity?: number
  missing_quantity?: number
  relying_set_piece_ids?: number[]
  reduce_relying_quantity: boolean
  note?: string
  expanded?: boolean
  updated?: number
}

export function createInspectionInventory(
  inspection_id: string,
  set_piece: SetPiece
): InspectionInventory {
  return {
    _id: [inspection_id, set_piece.id, Date.now()].join(':'),
    inspection_id: inspection_id,
    set_piece_id: set_piece.id,
    part_id: set_piece.part_id,
    piece_id: set_piece.piece_id,
    color_id: set_piece.color_id,
    parent_piece_id: set_piece.parent_piece_id,
    status: InspectionInventoryStatus.Unknown,
    expected_quantity: set_piece.quantity,
    type: set_piece.type,
    relying_set_piece_ids: set_piece.relying_set_piece_id,
    reduce_relying_quantity: false,
    note: '',
    expanded: false,
    updated: Date.now()
  }
}
