import { Piece } from '@/models'
import PieceService from '@/services/pieces'
import { ShareDataSourceRecord, SupportedDataTypes } from '../Context'
import { Reducer } from '../reducers'

type SetImageDataRecord = ShareDataSourceRecord<Piece, number>

function isPiece(
  record: ShareDataSourceRecord<any>
): record is SetImageDataRecord {
  return record.dependencySignature.type === SupportedDataTypes.Piece
}

export const PieceReducer: Reducer<Piece, number> = (record) => {
  return {
    handled: isPiece(record),
    resolve: (signature) => {
      return PieceService.getById(signature.identifier)
    }
  }
}
