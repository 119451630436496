import * as React from 'react'
import { Inspection as inspectionModel } from '@/models/Inspection'
import {
  default as InspectionService,
  InspectionOperations
} from '@/services/inspections'
import { SetRecord } from '@/models/SetRecord'
import { SetOperations, default as SetService } from '@/services/sets'
import { LoadingOutlined } from '@ant-design/icons'
import { withRouter, RouteComponentProps } from 'react-router'

interface InspectionProps extends RouteComponentProps {
  id: string
  render(loadedState: InspectionState): JSX.Element
}

interface InspectionState {
  Inspection?: inspectionModel
  Set?: SetRecord
}

class _InspectionLoader extends React.Component<
  InspectionProps,
  InspectionState
> {
  ifInspection: (payload: any) => boolean
  updateInspection: (payload: inspectionModel[]) => void

  ifSet: (payload: number) => boolean
  updateSet: (payload: SetRecord[]) => void

  constructor(props: InspectionProps) {
    super(props)

    this.state = {}

    this.ifInspection = (payload: any) => {
      const _id = payload as string
      return this.props.id === _id
    }

    this.updateInspection = (payload: inspectionModel[]) => {
      const [inspecting] = payload
      if (!inspecting) {
        this.props.history.push('/dashboard')
        return
      }

      SetService.sendRequest(SetOperations.GetById, inspecting.setId)
      this.setState({
        Inspection: inspecting
      })
    }

    this.ifSet = (payload: number) => {
      return this.state.Inspection.setId === payload
    }
    this.updateSet = (payload: SetRecord[]) => {
      const [set] = payload

      this.setState({
        Set: set
      })
    }
  }

  componentDidMount() {
    InspectionService.on(
      InspectionOperations.Get,
      this.ifInspection,
      this.updateInspection
    )
    SetService.on(SetOperations.GetById, this.ifSet, this.updateSet)

    InspectionService.sendRequest(InspectionOperations.Get, this.props.id)
  }

  componentDidUpdate(prevProps: InspectionProps) {
    const { id: oldId } = prevProps
    if (this.props.id !== oldId) {
      InspectionService.sendRequest(InspectionOperations.Get, this.props.id)
    }
  }

  componentWillUnmount() {
    InspectionService.off(
      InspectionOperations.Get,
      this.ifInspection,
      this.updateInspection
    )
    SetService.off(SetOperations.GetById, this.ifSet, this.updateSet)
  }

  render() {
    const loaded = this.state.Inspection && this.state.Set
    return loaded ? this.props.render(this.state) : <LoadingOutlined />
  }
}

export const InspectionLoader = withRouter(_InspectionLoader)
