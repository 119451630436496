import * as React from 'react'
import { InspectionInventory } from '@/models/InspectionInventory'
import { InventoryItemThumbnail } from './InventoryItemThumbnail'
import { normalizeName, getTypeTag } from './inventoryItemUtils'
import { InspectionInventoryLoader } from '@/loaders/InspectionInventoryLoader'
import { Tag, Row, Col } from 'antd'

interface InventoryMiniListProps {
  inventory: InspectionInventory[]
}

export class InventoryMiniList extends React.Component<InventoryMiniListProps> {
  constructor(props: InventoryMiniListProps) {
    super(props)
  }

  renderItem(inventory: InspectionInventory) {
    return (
      <InspectionInventoryLoader
        key={`inv-mini-list-${inventory._id}`}
        inventory={inventory}
        inventory_item_id={inventory._id}
      >
        {(item) => {
          const tag = getTypeTag(item.inventory.type)
          return (
            <Col span={4}>
              <div style={{ textAlign: 'center' }}>
                <InventoryItemThumbnail
                  {...item}
                  className="inventoryItemThumbnail--small"
                />
              </div>
              <div>
                {item.inventory.expected_quantity} x{' '}
                {normalizeName(item.piece.name)}{' '}
                {tag.include && <Tag color={tag.color}>{tag.text}</Tag>}
              </div>
            </Col>
          )
        }}
      </InspectionInventoryLoader>
    )
  }

  render() {
    return (
      <Row>
        {this.props.inventory.map((i) => {
          return this.renderItem(i)
        })}
      </Row>
    )
  }
}
