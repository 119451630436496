import PouchDB from 'pouchdb'
import { IDatabase, Index } from '@/database/interface'

export function installIndexOnProperty(
  db: PouchDB.Database,
  indexes: Index[],
  propertyName: string
) {
  if (!indexes.find((i) => i.name === propertyName)) {
    db.createIndex({
      index: {
        name: propertyName,
        fields: [propertyName]
      }
    })
  }
}

export function createIndexForProperty(
  store: IDBObjectStore,
  propertyName: string
) {
  store.createIndex(propertyName, propertyName)
}
