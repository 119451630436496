export class KeyStore<T, DataType> {
  private map = new Map<T, DataType>()
  get(key: T) {
    if (this.map.has(key)) {
      if (!this.map.get(key)) {
        console.log('ooops')
      }
      return Promise.resolve([this.map.get(key)])
    }
    return Promise.reject(new Error('not here'))
  }
  set(key: T, data: DataType) {
    this.map.set(key, data)
  }
}
