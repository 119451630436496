import { Color } from '@/models'
import ColorService from '@/services/color'
import { ShareDataSourceRecord, SupportedDataTypes } from '../Context'
import { Reducer } from '../reducers'

type SetImageDataRecord = ShareDataSourceRecord<Color, number>

function isColor(
  record: ShareDataSourceRecord<any>
): record is SetImageDataRecord {
  return record.dependencySignature.type === SupportedDataTypes.Color
}

export const ColorReducer: Reducer<Color, number> = (record) => {
  return {
    handled: isColor(record),
    resolve: (signature) => {
      return ColorService.get(signature.identifier)
    }
  }
}
