import * as React from 'react'
import { Color } from '@/models/Color'
import ColorService, { ColorOperations } from '@/services/color'
import { Switch, Row, Col } from 'antd'

export interface ColorFilterProps {
  allSelected: boolean
  ColorId: number
  Color: Color
  selectedColors: number[]
  onChange: (colorId: number, checked: boolean) => void
}

export class ColorFilter extends React.Component<ColorFilterProps> {
  ifColorId: (payload: number) => boolean
  updateColor: (payload: Color[]) => void

  constructor(props: ColorFilterProps) {
    super(props)

    this.ifColorId = (colorId) => this.props.ColorId === colorId
    this.updateColor = (colors) => {
      const [color] = colors
      this.setState({
        Color: color
      })
    }
  }

  render() {
    const isSelected =
      !this.props.allSelected &&
      this.props.selectedColors.indexOf(this.props.ColorId) > -1
    return (
      <Row justify="space-between">
        <Col span={7}>{this.props.Color.common}</Col>
        <Col span={5}>
          <Switch
            checked={isSelected}
            onChange={(checked) =>
              this.props.onChange(this.props.ColorId, checked)
            }
          ></Switch>
        </Col>
      </Row>
    )
  }
}
