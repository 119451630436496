import * as React from 'react'
import { SetPieceItem } from '@/loaders/SetPieceLoader'

interface InventoryItemThumbnailProps extends SetPieceItem {
  className?: string
  onclick?(): void
}

export function InventoryItemThumbnail(props: InventoryItemThumbnailProps) {
  return (
    <div
      className={`inventoryItemThumbnail ${props.className}`}
      role="button"
      onClick={() => props.onclick && props.onclick()}
      style={{
        borderColor: `#${props.color && props.color.hex}`
      }}
    >
      <img src={props.piece && props.piece.image_url} />
    </div>
  )
}
