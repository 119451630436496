import { useSetImage } from '@/providers/SharedDataStore/hooks'
import { Image, Skeleton } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import * as React from 'react'

interface Props {
  setId: number
}

export const SetImage: React.FC<Props> = ({ setId }) => {
  const image = useSetImage(setId)
  if (!image) {
    return <Skeleton.Avatar size="large" shape="square" />
  }
  return <Avatar src={image.src} size="large" shape="square" />
}
