import { Inspection } from '@/models/Inspection'
import { OfflineService, CacheResolver } from '@/services/interfaces'
import { createIndexForProperty } from '@/services/indexer'
import { IDatabase } from '@/database/interface'
import { Database } from '@/database/indexedDB'
import InspectionInventoryService from './inspection_inventory'
import { completeInspection, startInspection } from '@/services/analytics'

export enum InspectionQueues {
  Pending = 'Pending',
  Completed = 'Completed'
}
export enum InspectionOperations {
  GetQueue = 'GetQueue',
  AddInspection = 'AddInspection',
  Get = 'Get',
  Put = 'Put',
  Patch = 'Patch',
  Delete = 'Delete'
}

export const InspectionsDb = new Database<Inspection>('inspections', {
  install: (store) => {
    createIndexForProperty(store, 'name')
    createIndexForProperty(store, 'completedOn')
  },
  onUpdateNeeded: async ({ oldVersion }, store) => {
    if (oldVersion < 12) {
      createIndexForProperty(store, 'collectionId')
    }
    if (oldVersion < 18) {
      await Database.AddNewDefaultIndexValue(store, 'collectionId', '')
    }
    if (oldVersion < 19) {
      createIndexForProperty(store, 'setNumber')
    }
  }
})

class InspectionResolver implements CacheResolver<Inspection> {
  db: IDatabase<Inspection>

  constructor() {
    this.db = InspectionsDb
  }

  async resolve(operation: string, payload: any) {
    if (operation === InspectionOperations.GetQueue) {
      let selector: any = {
        completedOn: -1
      }
      if (payload === InspectionQueues.Completed) {
        selector = {
          completedOn: {
            $gte: 0
          }
        }
      }

      return this.db
        .find({
          selector: selector
        })
        .then((data) => data.docs.map((d) => d as Inspection))
    }

    if (operation === InspectionOperations.AddInspection) {
      return this.db.put(payload).then(() => [payload])
    }

    if (operation === InspectionOperations.Get) {
      const _id = payload as string
      const inspection: Inspection = await this.db.get(_id)
      return Promise.resolve([inspection])
    }

    if (operation === InspectionOperations.Put) {
      const inspection = payload as Inspection
      if (!inspection._id) {
        return Promise.reject(new Error('Missing required _id field'))
      }
      await this.db.put(payload)
    }

    if (operation === InspectionOperations.Patch) {
      const inspection = payload as Partial<Inspection>
      if (!inspection._id) {
        return Promise.reject(new Error('Missing required _id field'))
      }
      const existing = await this.db.get(inspection._id)
      if (inspection.statusQty > 0 && existing.statusQty === 0) {
        startInspection(existing.setNumber)
      }
      if (
        inspection.statusQty >= inspection.totalQty &&
        !existing.canComplete
      ) {
        inspection.canComplete = true
        completeInspection(inspection.totalQty, existing.setNumber)
      }
      const updated = Object.assign(existing, inspection)
      await this.db.put(updated)
    }

    if (operation === InspectionOperations.Delete) {
      const inspection_id = payload as string
      if (!inspection_id) {
        return Promise.reject(new Error('Missing an inspection id field'))
      }
      const [document] = await this.resolve(
        InspectionOperations.Get,
        inspection_id
      )
      if (document) {
        await this.db.remove(document)
      }
    }

    return Promise.resolve([])
  }

  cache(operation: string, payload: any, data: any) {}
}

class inspections extends OfflineService<Inspection> {
  constructor() {
    super(new InspectionResolver())
    this.name = 'InspectionService'
    this.resolveWith((_, online) => online)
  }

  addInspection(inspection: Inspection) {
    this.sendRequest(InspectionOperations.AddInspection, inspection).then(
      () => {
        this.sendRequest(
          InspectionOperations.GetQueue,
          InspectionQueues.Pending
        )
        this.sendRequest(InspectionOperations.Get, inspection._id)
      }
    )
  }

  delete(inspection_id: string) {
    this.sendRequest(InspectionOperations.Delete, inspection_id).then(() => {
      InspectionInventoryService.deleteForInspection(inspection_id)
      this.sendRequest(InspectionOperations.GetQueue, InspectionQueues.Pending)
    })
  }

  updateWithCollection(inspection: Inspection, collectionId: string) {
    if (!collectionId) {
      throw new Error('collection id is required')
    }
    return this.sendRequest(InspectionOperations.Patch, {
      _id: inspection._id,
      collectionId
    })
  }
}

const InspectionService = new inspections()

export default InspectionService
