import { FullPage } from '@/components/layouts/FullPage'
import { CollectionLoader } from '@/loaders/CollectionLoader'
import * as React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import { DefaultCollectionPage } from './Collection/DefaultView'
import { CollectionReportPage } from './Collection/ReportView'

interface RoutProps {
  collectionId: string
}

export const CollectionPage: React.FC<RouteComponentProps<RoutProps>> = ({
  match
}) => {
  const { collectionId } = match.params
  return (
    <FullPage>
      <CollectionLoader collectionId={collectionId}>
        {(record) => (
          <Switch>
            <Route path={`/collection/view/${collectionId}`} exact>
              <DefaultCollectionPage collectionRecord={record} />
            </Route>
            <Route path={`/collection/view/${collectionId}/report`}>
              <CollectionReportPage collectionRecord={record} />
            </Route>
          </Switch>
        )}
      </CollectionLoader>
    </FullPage>
  )
}
