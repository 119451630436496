import { SetRecord } from '@/models/SetRecord'
import { SetImage } from '@/models/SetImage'
import { InventoryCollection, SetPiece, Color, Part, Piece } from '@/models'
import { parseSetPieceType } from '@/models/SetPiece'
import { PieceImage } from '@/models/PieceImage'
import { PiecePriceGuide } from '@/models/PriceGuide'

interface File {
  src?: string
  blob: Blob
  contentType: string
}

interface CacheResult {
  resolving?: Promise<Response>
  response?: Response
  expires: number
}

class ApiCache {
  private map: Map<string, CacheResult>

  constructor() {
    this.map = new Map<string, CacheResult>()
    setInterval(() => this.releaseExpired(), 1000)
  }

  fetch(url: string, noCors: boolean = false): Promise<Response> {
    if (url.startsWith('//') && window.location.protocol === 'file:') {
      url = 'https:' + url
    }

    let result = this.map.get(url)

    if (result) {
      if (result.response) {
        return Promise.resolve(result.response.clone())
      }

      if (result.resolving) {
        return new Promise((resolve, reject) => {
          result.resolving = result.resolving
            .then((res) => {
              let result = this.map.get(url)
              resolve(result.response.clone())
              return result.response.clone()
            })
            .catch((er) => {
              reject(er)
              throw er
            })
        })
      }
    }

    let promise = fetch(url, { mode: noCors ? 'no-cors' : 'cors' }).then(
      (response) => {
        let cached = this.map.get(url)
        cached.response = response.clone()
        this.map.set(url, cached)
        return response.clone()
      }
    )

    this.map.set(url, {
      expires: Date.now() + 86400000,
      resolving: promise
    })

    return promise
  }

  releaseExpired() {
    let expired = []
    for (let [url, result] of this.map) {
      if (result.expires < Date.now()) {
        expired.push(url)
      }
    }

    for (let url of expired) {
      this.map.delete(url)
    }
  }

  remove(url: string) {
    return this.map.delete(url)
  }
}

class ApiService {
  private cache: ApiCache
  private apiOrigin: string = process.env.API_URL

  public constructor() {
    this.cache = new ApiCache()
  }

  public doSearch(input: string): Promise<Array<SetRecord>> {
    return this.cache
      .fetch(`${this.apiOrigin}/sets?setNameOrNumber=${input}`)
      .then((req) => req.json())
      .then((data) => {
        return data.sets.map((s: object) => s as SetRecord)
      })
  }

  public tryGetSet(input: string): Promise<SetRecord> {
    return this.cache
      .fetch(`${this.apiOrigin}/sets/${input}/findAndUpdate`)
      .then((req) => req.json())
      .then((data) => {
        return data.set as SetRecord
      })
  }

  public getManyById(...ids: string[]) {
    const search = new URLSearchParams(ids.map((x) => ['id', x]))
    return this.cache
      .fetch(`${this.apiOrigin}/sets?${search.toString()}`)
      .then((req) => req.json())
      .then((data) => {
        return (data.sets as any[]).map((s) => s as SetRecord)
      })
  }

  async getSetImage(input: number): Promise<Array<SetImage>> {
    const data = await this.cache
      .fetch(`${this.apiOrigin}/images/set/${input}`)
      .then((req) => req.json())

    return this.cache.fetch(data.src).then(async (res) => {
      const blob = await res.blob()
      return [
        {
          _id: '',
          url: URL.createObjectURL(blob),
          setId: input,
          contentType: res.headers.get('content-type'),
          src: data.src,
          blob: blob
        } as SetImage
      ]
    })
  }

  public getColorById(id: number) {
    return this.cache
      .fetch(`${this.apiOrigin}/colors/${id}`)
      .then((req) => req.json())
      .then((data) => {
        return (data.color || data.obj) as Color
      })
  }

  public getPartById(id: number) {
    return this.cache
      .fetch(`${this.apiOrigin}/parts/${id}`)
      .then((req) => req.json())
      .then((data) => {
        return (data.part || data.obj) as Part
      })
  }

  public getPieceById(id: number) {
    return this.cache
      .fetch(`${this.apiOrigin}/pieces/${id}`)
      .then((req) => req.json())
      .then((data) => {
        return (data.piece || data.obj) as Piece
      })
  }

  async getPieceImage(
    input: string,
    source?: string
  ): Promise<Array<PieceImage>> {
    let query = !source ? '' : `?source=${source}`

    const sourceRequest = source
      ? fetch(`${this.apiOrigin}/images/piece/${input}`, {
          method: 'post',
          body: JSON.stringify({
            source
          })
        })
      : this.cache.fetch(`${this.apiOrigin}/images/piece/${input}${query}`)

    const data = await sourceRequest.then((req) => req.json())

    return this.cache
      .fetch(data.src, true)
      .then(async (res) => {
        const blob = await res.blob()
        const ext = data.src.split('.').pop()
        return [
          {
            _id: '',
            url: URL.createObjectURL(blob),
            piece_id: input,
            contentType:
              res.headers.get('content-type') || extensionToContentType(ext),
            src: data.src,
            blob: blob
          } as PieceImage
        ]
      })
      .catch((e) => {
        e.src = data.src
        throw e
      })
  }

  async fetchFile(url: string): Promise<File> {
    return this.cache.fetch(url).then(async (res) => {
      const blob = await res.blob()

      const file: File = {
        src: url,
        contentType: res.headers.get('content-type'),
        blob: blob
      }

      return file
    })
  }

  async fetchInventory(
    setNumber: string,
    force: boolean = false
  ): Promise<Omit<InventoryCollection, 'inspectionInventories'>> {
    const inventoryURL = `${this.apiOrigin}/sets/${setNumber}/inventory`
    const fetchURL = `${this.apiOrigin}/sets/${setNumber}/fetchInventory`
    let data: any
    if (!force) {
      data = await this.cache.fetch(inventoryURL).then((res) => res.json())
    }

    if (force || !data.inventory || !data.inventory.length) {
      // clear cache
      this.cache.remove(inventoryURL)
      this.cache.remove(fetchURL)
      // Fetch the inventory
      await this.cache.fetch(fetchURL).then((res) => res.json())
      // Retry call
      data = await this.cache.fetch(inventoryURL).then((res) => res.json())
    }

    const inventoryData: Omit<InventoryCollection, 'inspectionInventories'> = {
      setPieces: new Map<number, SetPiece>(),
      colors: new Map<number, Color>(),
      pieces: new Map<number, Piece>(),
      parts: new Map<number, Part>()
    }

    for (let inv of data.inventory) {
      const color: Color = inv.Color
      const piece: Piece = {
        id: inv.Piece.id,
        color_id: inv.Piece.color_id,
        element_id: inv.Piece.element_id,
        hasImage: inv.Piece.hasImage,
        hasInventory: inv.Piece.hasInventory,
        image_url: inv.Piece.image_url,
        name: inv.Piece.name,
        part_id: inv.Piece.part_id
      }
      const part: Part = inv.Part

      if (!inventoryData.colors.has(color.id)) {
        inventoryData.colors.set(color.id, color)
      }

      if (!inventoryData.pieces.has(piece.id)) {
        inventoryData.pieces.set(piece.id, piece)
      }

      if (!inventoryData.parts.has(part.id)) {
        inventoryData.parts.set(part.id, part)
      }

      const setPiece: SetPiece = {
        id: inv.id,
        set_id: inv.set_id,
        color_id: inv.color_id,
        part_id: inv.part_id,
        piece_id: inv.piece_id,
        parent_piece_id: inv.parent_piece_id,
        type: parseSetPieceType(inv.type, part.code, inv.Part.name),
        quantity: inv.quantity,
        hasInventory: inv.Piece.hasInventory,
        name: inv.Part.name
      }

      inventoryData.setPieces.set(setPiece.id, setPiece)
    }

    return inventoryData
  }

  async getSetPiece(setPieceId: number) {
    return this.cache
      .fetch(`${this.apiOrigin}/setPieces/${setPieceId}`)
      .then((req) => req.json())
      .then((data) => {
        return data.setPiece as SetPiece
      })
  }

  async getManySetPieces(setPieceIds: number[]) {
    const countLimit = 100
    let setPieces: SetPiece[] = []
    const requests = Math.ceil(setPieceIds.length / countLimit)

    for (let i = 0; i < requests; i++) {
      const urlParams = new URLSearchParams()
      setPieceIds.slice(i * countLimit, (1 + i) * countLimit).forEach((x) => {
        urlParams.append('id', x.toString())
      })
      const pagedResults = await this.cache
        .fetch(`${this.apiOrigin}/setPieces?${urlParams.toString()}`)
        .then((req) => req.json())
        .then((data) => {
          return data.setPieces as SetPiece[]
        })
      setPieces = setPieces.concat(...pagedResults)
    }

    return setPieces
  }

  async getPiecesByIds(pieceIds: number[]) {
    const countLimit = 50
    let pieces: Piece[] = []
    const requests = Math.ceil(pieceIds.length / countLimit)

    for (let i = 0; i < requests; i++) {
      const urlParams = new URLSearchParams()
      pieceIds.slice(i * countLimit, (1 + i) * countLimit).forEach((x) => {
        urlParams.append('id', x.toString())
      })
      const pagedResults = await this.cache
        .fetch(`${this.apiOrigin}/pieces?${urlParams.toString()}`)
        .then((req) => req.json())
        .then((data) => {
          return data.pieces as Piece[]
        })
      pieces = pieces.concat(...pagedResults)
    }

    return pieces
  }

  async getColorsByIds(colorIds: number[]) {
    const countLimit = 50
    let colors: Color[] = []
    const requests = Math.ceil(colorIds.length / countLimit)

    for (let i = 0; i < requests; i++) {
      const urlParams = new URLSearchParams()
      colorIds.slice(i * countLimit, (1 + i) * countLimit).forEach((x) => {
        urlParams.append('id', x.toString())
      })
      const pagedResults = await this.cache
        .fetch(`${this.apiOrigin}/colors?${urlParams.toString()}`)
        .then((req) => req.json())
        .then((data) => {
          return data.colors as Color[]
        })
      colors = colors.concat(...pagedResults)
    }

    return colors
  }

  async getManyParts(
    partIds: number[],
    options?: {
      onPageLoaded?: (priceGuides: Part[]) => void
    }
  ) {
    const countLimit = 50
    let parts: Part[] = []
    const requests = Math.ceil(partIds.length / countLimit)

    for (let i = 0; i < requests; i++) {
      const urlParams = new URLSearchParams()
      partIds.slice(i * countLimit, (1 + i) * countLimit).forEach((x) => {
        urlParams.append('id', x.toString())
      })
      const pagedResults = await this.cache
        .fetch(`${this.apiOrigin}/parts?${urlParams.toString()}`)
        .then((req) => req.json())
        .then((data) => {
          return data.parts as Part[]
        })
      parts = parts.concat(...pagedResults)
      if (options?.onPageLoaded && i < requests) {
        options.onPageLoaded(parts)
      }
    }
    return parts
  }

  async getManyPriceGuidesByPieceIds(
    pieceIds: number[],
    options?: {
      guideType?: 'sold' | 'stock'
      condition?: 'N' | 'U'
      onPageLoaded?: (priceGuides: PiecePriceGuide[]) => void
    }
  ) {
    const countLimit = 10
    let piecePriceGuides: PiecePriceGuide[] = []
    const requests = Math.ceil(pieceIds.length / countLimit)

    for (let i = 0; i < requests; i++) {
      const urlParams = new URLSearchParams({
        guide_type: options?.guideType || 'sold',
        new_or_used: options?.condition || 'U',
        country_code: 'US'
      })
      pieceIds.slice(i * countLimit, (1 + i) * countLimit).forEach((x) => {
        urlParams.append('id', x.toString())
      })
      const pagedResults = await this.cache
        .fetch(`${this.apiOrigin}/pieces/priceGuide?${urlParams.toString()}`)
        .then((req) => req.json())
        .then((data) => {
          return data.price_guides as PiecePriceGuide[]
        })
      piecePriceGuides = piecePriceGuides.concat(...pagedResults)
      if (options?.onPageLoaded && i < requests) {
        options.onPageLoaded(piecePriceGuides)
      }
    }
    return piecePriceGuides
  }
}

function extensionToContentType(ext: string) {
  switch (ext.toLowerCase()) {
    case 'png':
      return 'image/png'
    case 'gif':
      return 'image/gif'
    default:
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg'
  }
}
export default new ApiService()
