import {
  executeSort,
  InventorySetPiece,
  SortMethods,
  sortMethodToFunction
} from '@/components/Inventory/inventoryQueueFilterSort'
import { InspectionInventory, Part, SetPiece } from '@/models'
import { Inspection } from '@/models/Inspection'
import { PriceGuidePricing } from '@/models/PriceGuide'
import { parseSetPieceType } from '@/models/SetPiece'
import { ExpandedCollection } from '@/providers/Collections/Context'
import api from '@/services/api'
import { InspectionsDb } from '@/services/inspections'
import { InspectionInventoryDB } from '@/services/inspection_inventory'
import SetPieceService from '@/services/set_pieces'
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  LoadingOutlined
} from '@ant-design/icons'
import { Card, Col, Row, Timeline } from 'antd'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

enum DataLoadSteps {
  Initial,
  InspectionsLoaded,
  InspectionInventoryLoaded,
  SetPiecesLoaded,
  PartsLoaded,
  PriceGuidesLoaded,
  Finalized
}

interface StepState {
  type: DataLoadSteps
  error?: any
  counter?: number
  expectedCount?: number
}

export type LoadedInventorySetPiece = InventorySetPiece & {
  inspection: Inspection
  part: Part
}

export type LoadedInventorySetPieceWithPriceGuides = InventorySetPiece & {
  inspection: Inspection
  part: Part
  guides: PriceGuidePricing | null
}

interface Props extends RouteComponentProps {
  children(
    inventorySetPieces: LoadedInventorySetPieceWithPriceGuides[]
  ): React.ReactNode | React.ReactNodeArray
  collectionRecord: ExpandedCollection
  sortByInspection?: boolean
}

interface State {
  skipPriceGuides?: boolean
  step: StepState
  inspections?: Inspection[]
  inspectionMap?: Map<string, Inspection>
  inventory?: InspectionInventory[]
  inventorySetPiece?: InventorySetPiece[]
  setPieceMap?: Map<number, SetPiece>
  partsMap?: Map<number, Part>
  priceGuideMap?: Map<number, PriceGuidePricing>
  loaded?: LoadedInventorySetPieceWithPriceGuides[]
}

export class _ReportDataLoader extends React.Component<Props, State> {
  public state: State

  public constructor(props: Props) {
    super(props)

    const params = new URLSearchParams(this.props.location.search)
    const includePrices = params.get('include_prices')

    this.state = {
      step: { type: DataLoadSteps.Initial },
      skipPriceGuides: false.toString() === includePrices,
      priceGuideMap: new Map<number, PriceGuidePricing>()
    }
  }

  public render() {
    if (this.state.step.type === DataLoadSteps.Finalized) {
      return this.props.children(this.state.loaded)
    }
    const step = this.state.step
    return (
      <Row gutter={16}>
        <Col offset={6} span={12}>
          <Card>
            <Timeline>
              <Timeline.Item
                dot={
                  step.type === DataLoadSteps.Initial ? (
                    <LoadingOutlined />
                  ) : step.type > DataLoadSteps.Initial ? (
                    <CheckCircleFilled />
                  ) : null
                }
              >
                Loading Inspections
              </Timeline.Item>
              <Timeline.Item
                dot={
                  step.type === DataLoadSteps.InspectionsLoaded ? (
                    <LoadingOutlined />
                  ) : step.type > DataLoadSteps.InspectionsLoaded ? (
                    <CheckCircleFilled />
                  ) : null
                }
              >
                Loading Missing Set Pieces
              </Timeline.Item>
              <Timeline.Item
                dot={
                  step.type === DataLoadSteps.InspectionInventoryLoaded ? (
                    <LoadingOutlined />
                  ) : step.type > DataLoadSteps.InspectionInventoryLoaded ? (
                    <CheckCircleFilled />
                  ) : null
                }
              >
                Loading Set Pieces Details
              </Timeline.Item>
              <PriceGuideLoaderItem
                stepState={step}
                expectedStepType={DataLoadSteps.SetPiecesLoaded}
              >
                Loading Part Details
              </PriceGuideLoaderItem>
              <PriceGuideLoaderItem
                stepState={step}
                expectedStepType={DataLoadSteps.PartsLoaded}
              >
                Loading Price Guides
              </PriceGuideLoaderItem>
              <Timeline.Item
                dot={
                  step.type === DataLoadSteps.PriceGuidesLoaded ? (
                    <LoadingOutlined />
                  ) : step.type > DataLoadSteps.PriceGuidesLoaded ? (
                    <CheckCircleFilled />
                  ) : null
                }
              >
                Sorting Missing Parts
              </Timeline.Item>
            </Timeline>
          </Card>
        </Col>
      </Row>
    )
  }

  public async componentDidMount() {
    const { docs: inspections } = await InspectionsDb.find({
      selector: {
        collectionId: this.props.collectionRecord.collection._id
      }
    })

    const inspectionMap: Map<string, Inspection> = inspections.reduce(
      (map, inspection) => {
        map.set(inspection._id, inspection)
        return map
      },
      new Map<string, Inspection>()
    )

    this.setState(
      {
        inspections,
        inspectionMap,
        step: { type: DataLoadSteps.InspectionsLoaded }
      },
      () => this.loadInspectionInventory()
    )
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.sortByInspection !== this.props.sortByInspection) {
      this.sortInventory()
    }
  }

  private async loadInspectionInventory() {
    const inspectionIds = this.state.inspections.map((x) => x._id)
    if (!inspectionIds.length) {
      this.setState({ step: { type: DataLoadSteps.Finalized }, loaded: [] })
      return
    }

    const { docs: inventory } = await InspectionInventoryDB.find({
      selector: {
        inspection_id: {
          $in: inspectionIds
        }
      }
    })

    this.setState(
      {
        inventory,
        step: { type: DataLoadSteps.InspectionInventoryLoaded }
      },
      () => this.mapToInventorySetPiece()
    )
  }

  private async mapToInventorySetPiece() {
    const setPieceIds = deduplicateArray(
      this.state.inventory.map((x) => x.set_piece_id)
    )
    if (!setPieceIds.length) {
      this.setState({ step: { type: DataLoadSteps.Finalized }, loaded: [] })
      return
    }
    const setPieces = await SetPieceService.getByIds(setPieceIds)
    const setPieceMap: Map<number, SetPiece> = setPieces.reduce(
      (map, setPiece) => {
        map.set(setPiece.id, setPiece)
        return map
      },
      new Map<number, SetPiece>()
    )

    const inventorySetPiece: InventorySetPiece[] = this.state.inventory.map(
      (x) => {
        return {
          Inventory: x,
          SetPiece: setPieceMap.get(x.set_piece_id)
        }
      }
    )
    this.setState(
      {
        inventorySetPiece,
        setPieceMap,
        step: { type: DataLoadSteps.SetPiecesLoaded }
      },
      () => this.loadParts()
    )
  }

  private async loadParts() {
    const partIds = deduplicateArray(
      this.state.inventorySetPiece.map((x) => x.SetPiece.part_id)
    )
    this.setState((state) => ({
      ...state,
      step: {
        ...state.step,
        counter: 0,
        expectedCount: partIds.length
      }
    }))
    const parts = await api.getManyParts(partIds, {
      onPageLoaded: (parts) => {
        this.setState((state) => ({
          ...state,
          step: {
            ...state.step,
            counter: parts.length,
            expectedCount: partIds.length
          }
        }))
      }
    })
    const partsMap: Map<number, Part> = parts.reduce((map, part) => {
      map.set(part.id, part)
      return map
    }, new Map<number, Part>())

    this.setState(
      {
        step: {
          type: this.state.skipPriceGuides
            ? DataLoadSteps.PriceGuidesLoaded
            : DataLoadSteps.PartsLoaded
        },
        partsMap
      },
      () => {
        this.state.skipPriceGuides
          ? this.sortInventory()
          : this.loadPriceGuides()
      }
    )
  }

  private async loadPriceGuides() {
    const pieceIds = deduplicateArray(
      this.state.inventorySetPiece
        .filter((x) => x.Inventory.missing_quantity > 0)
        .map((x) => x.SetPiece.piece_id)
    )

    this.setState((state) => ({
      ...state,
      step: {
        ...state.step,
        counter: 0,
        expectedCount: pieceIds.length
      }
    }))

    const priceGuideMap: Map<number, PriceGuidePricing> = new Map<
      number,
      PriceGuidePricing
    >()
    try {
      const defaultResults = await api.getManyPriceGuidesByPieceIds(pieceIds, {
        onPageLoaded: (guides) => {
          this.setState((state) => ({
            ...state,
            step: {
              ...state.step,
              counter: guides.length
            }
          }))
        }
      })
      const noResultIds: number[] = []
      for (const defaultResult of defaultResults) {
        if (defaultResult.PriceGuide.avg_price) {
          priceGuideMap.set(defaultResult.id, defaultResult.PriceGuide)
        } else {
          noResultIds.push(defaultResult.id)
        }
      }

      if (noResultIds.length) {
        const inStockPriceGuides = await api.getManyPriceGuidesByPieceIds(
          noResultIds,
          {
            guideType: 'stock'
          }
        )
        for (const inStockPriceGuide of inStockPriceGuides) {
          if (inStockPriceGuide.PriceGuide.avg_price) {
            priceGuideMap.set(
              inStockPriceGuide.id,
              inStockPriceGuide.PriceGuide
            )
          }
        }
      }

      this.setState(
        {
          step: { type: DataLoadSteps.PriceGuidesLoaded },
          priceGuideMap
        },
        () => this.sortInventory()
      )
    } catch (error) {
      this.setState((state) => ({
        ...state,
        step: {
          ...state.step,
          error
        }
      }))
    }
  }

  private async sortInventory() {
    const sortOrdering = [
      SortMethods.SortBySize,
      SortMethods.SortByColor,
      SortMethods.SortByName
    ]
    let sorted = executeSort(this.state.inventorySetPiece, sortOrdering)

    sorted = sorted.sort((a, b) => {
      const { type: typeA } = this.state.partsMap.get(a.part_id)
      const { type: typeB } = this.state.partsMap.get(b.part_id)

      if (typeA > typeB) {
        return -1
      }
      if (typeA < typeB) {
        return 1
      }

      return 0
    })

    if (this.props.sortByInspection) {
      sorted = sorted.sort((a, b) => {
        const { setId: setIdA } = this.state.inspectionMap.get(a.inspection_id)
        const { setId: setIdB } = this.state.inspectionMap.get(b.inspection_id)

        if (setIdA > setIdB) {
          return -1
        }
        if (setIdA < setIdB) {
          return 1
        }
        return 0
      })
    }

    const sortedInventorySetPieces = sorted
      .map((x) => {
        return {
          Inventory: x,
          SetPiece: this.state.setPieceMap.get(x.set_piece_id)
        }
      })
      .sort(sortMethodToFunction.get(SortMethods.SortByType))

    const loaded = this.finalizeLoaded(sortedInventorySetPieces)

    this.setState({
      inventory: sorted,
      inventorySetPiece: sortedInventorySetPieces,
      loaded,
      step: { type: DataLoadSteps.Finalized }
    })
  }

  private finalizeLoaded(sortedInventorySetPieces: InventorySetPiece[]) {
    const loaded = sortedInventorySetPieces.map((x) => {
      const part = this.state.partsMap.get(x.SetPiece.part_id)
      return {
        Inventory: {
          ...x.Inventory,
          type: parseSetPieceType(x.SetPiece.type, part.code, part.name)
        },
        SetPiece: {
          ...x.SetPiece,
          type: parseSetPieceType(x.SetPiece.type, part.code, part.name)
        },
        inspection: this.state.inspectionMap.get(x.Inventory.inspection_id),
        part,
        guides: this.state.priceGuideMap.get(x.SetPiece.piece_id)
      }
    })
    return loaded
  }
}

export const ReportDataLoader = withRouter(_ReportDataLoader)

function deduplicateArray<T>(data: T[]) {
  return data.reduce((uniqueArray, item) => {
    if (uniqueArray.indexOf(item) === -1) {
      uniqueArray.push(item)
    }
    return uniqueArray
  }, [] as T[])
}

const PriceGuideLoaderItem: React.FC<{
  expectedStepType: DataLoadSteps
  stepState: StepState
}> = ({ stepState, expectedStepType, children }) => {
  if (stepState.type < expectedStepType) {
    return <Timeline.Item>{children}</Timeline.Item>
  }
  if (stepState.type > expectedStepType) {
    return (
      <Timeline.Item dot={<CheckCircleFilled />}>
        Loading Price Guides
      </Timeline.Item>
    )
  }
  if (stepState.error) {
    return (
      <Timeline.Item dot={<ExclamationCircleFilled color="red" />}>
        {children}{' '}
        {stepState.expectedCount && (
          <span>
            ({stepState.counter}/{stepState?.expectedCount} Loaded)
          </span>
        )}
      </Timeline.Item>
    )
  }
  return (
    <Timeline.Item dot={<LoadingOutlined />}>
      {children}{' '}
      {stepState.expectedCount && (
        <span>
          ({stepState.counter}/{stepState?.expectedCount} Loaded)
        </span>
      )}
    </Timeline.Item>
  )
}
