import { Piece } from '@/models/Piece'
import { OfflineService, CacheResolver } from '@/services/interfaces'
import { createIndexForProperty } from '@/services/indexer'
import { IDatabase } from '@/database/interface'
import { Database } from '@/database/indexedDB'
import { KeyStore } from './keystore'
import api from './api'

export enum PieceOperations {
  Get = 'Get',
  GetManyByIds = 'GetManyByIds',
  Add = 'Add',
  BulkAdd = 'BulkAdd',
  Update = 'Update'
}

class PieceResolver implements CacheResolver<Piece> {
  db: IDatabase<Piece>
  store: KeyStore<number, Piece> = new KeyStore<number, Piece>()

  constructor() {
    this.db = new Database<Piece>('pieces', {
      install: (store) => {
        createIndexForProperty(store, 'id')
        createIndexForProperty(store, 'color_id')
        createIndexForProperty(store, 'part_id')
      }
    })
  }

  async resolve(operation: string, payload: any): Promise<Piece[]> {
    if (operation === PieceOperations.Get) {
      const Piece_id = payload as number
      return this.store.get(Piece_id).catch(() => {
        return this.db
          .find({
            selector: {
              id: Piece_id
            }
          })
          .then((data) => data.docs.map((d) => d as Piece))
      })
    }

    if (operation === PieceOperations.GetManyByIds) {
      const pieceIds = payload as number[]
      return this.db
        .find({
          selector: {
            id: {
              $in: pieceIds
            }
          }
        })
        .then((data) => data.docs.map((d) => d as Piece))
    }

    if (operation === PieceOperations.Add) {
      const Piece = payload as Piece
      Piece._rev = Piece._rev || (1).toString()
      await this.cache(PieceOperations.Get, Piece.id, [Piece])
      return this.resolve(PieceOperations.Get, Piece.id)
    }

    if (operation === PieceOperations.BulkAdd) {
      const pieces = payload as Piece[]
      await this.cache(PieceOperations.BulkAdd, null, pieces)
    }

    if (operation === PieceOperations.Update) {
      const piece = payload as { id: number } & Partial<Piece>
      const [stale] = await this.resolve(PieceOperations.Get, piece.id)
      const Piece = {
        ...stale,
        ...piece
      }
      await this.cache(PieceOperations.Get, Piece.id, [Piece])
      return this.resolve(PieceOperations.Get, Piece.id)
    }

    return Promise.resolve([])
  }

  async cache(operation: string, payload: any, data: Piece[]) {
    if (!data) {
      return
    }

    if (operation === PieceOperations.BulkAdd) {
      const piece_ids = data.map((p) => p.id.toString())
      const existingDocs = await this.db.allDocs({
        keys: piece_ids
      })

      const documents = data.map((piece) => {
        piece._id = piece.id.toString()
        piece._rev = piece._rev || (1).toString()
        const match = existingDocs.rows.find((row) => row.id === piece._id)
        if (match) {
          piece._rev = match.value.rev
        }
        return piece
      })

      await this.db.bulkDocs(documents)
    }

    if (operation === PieceOperations.Get) {
      for (let piece of data) {
        const Piece_id = payload as number
        this.store.set(Piece_id, piece)
        const docs = await this.db
          .find({
            selector: {
              id: Piece_id
            }
          })
          .then((data) => data.docs)

        const [existing] = docs

        if (existing) {
          piece._id = existing._id
          piece._rev = existing._rev
        } else {
          piece._id = Piece_id.toString()
        }

        this.db.put(piece)
      }
    }

    if (operation === PieceOperations.GetManyByIds) {
      for (let piece of data) {
        this.store.set(piece.id, piece)
        const docs = await this.db
          .find({
            selector: {
              id: piece.id
            }
          })
          .then((data) => data.docs)

        const [existing] = docs

        if (existing) {
          piece._id = existing._id
          piece._rev = existing._rev
        } else {
          piece._id = piece.id.toString()
        }

        this.db.put(piece)
      }
    }
  }
}

class Pieces extends OfflineService<Piece> {
  constructor() {
    super(new PieceResolver())
    this.name = 'PieceService'
    this.resolveWith((cache, live) => {
      if (cache instanceof Array && cache.length <= live.length) {
        return live
      }
      return cache
    })

    this.registerSource(PieceOperations.Get, async (pieceId: number) => {
      const piece = await api.getPieceById(pieceId)
      return [piece]
    })

    this.registerSource(PieceOperations.GetManyByIds, (pieceIds: number[]) =>
      api.getPiecesByIds(pieceIds)
    )
  }

  public update(pieceId: number, partial: Partial<Piece>) {
    return this.sendRequest(PieceOperations.Update, {
      id: pieceId,
      ...partial
    })
  }

  public getById(pieceId: number) {
    return this.sendRequest(PieceOperations.Get, pieceId).then(([x]) => x)
  }

  public getManyById(pieceIds: number[]) {
    return this.sendRequest(PieceOperations.GetManyByIds, pieceIds)
  }
}

const PieceService = new Pieces()

export default PieceService
