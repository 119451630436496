import * as React from 'react'

export enum SupportedDataTypes {
  SetImage,
  SetRecord,
  Piece,
  Part,
  Color
}

export interface DataSignature<T, TIdentifier = number | string> {
  type: SupportedDataTypes | (new () => T)
  identifier: TIdentifier
}

export interface SharedDataSourceRecordSubscriber {
  unsubscribe: () => void
}

export interface ShareDataSourceRecord<T, TIdentifier = number | string> {
  dependencySignature: DataSignature<T, TIdentifier>
  data: T
  subscribers: SharedDataSourceRecordSubscriber[]
}

export interface ShareDataStore {
  [identifier: string]: ShareDataSourceRecord<any>
}

interface SharedDataStoreContext {
  store: ShareDataStore
  subscribeToDataRecord<T>(signature: DataSignature<T>): () => void
  getFromSignature<T>(signature: DataSignature<T>): T
}

export const SharedDataStoreContext = React.createContext<SharedDataStoreContext>(
  null
)
