import { Query } from '@/database/interface'
import { Inspection, SetRecord } from '@/models'
import * as React from 'react'

export interface ExpandedInspection {
  inspection: Inspection
  set: SetRecord
}

export interface StatefulInspectionsContext {
  inspections?: ExpandedInspection[]
  loading?: boolean
}

export interface InspectionsContext extends StatefulInspectionsContext {
  populate(query: Query, forceReload?: boolean): Promise<void>
  reset(): void
  delete(inspectionId: string): void
}

export const defaulInspectionsContext: StatefulInspectionsContext = {
  loading: false
}

function NoProvider(): any {
  throw new Error('No provider mounted for InspectionsContext')
}

export const InspectionsContext = React.createContext<InspectionsContext>({
  ...defaulInspectionsContext,
  populate: NoProvider,
  reset: NoProvider,
  delete: NoProvider
})
