import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  titleCase,
  normalizeName,
  getTypeTag,
  getNormalizedColorName
} from './inventoryItemUtils'
import { Alert, Tag } from 'antd'
import {
  default as InspectionInventoryService,
  InspectionInventoryOperations
} from '@/services/inspection_inventory'
import { InspectionInventory } from '@/models/InspectionInventory'
import { InventoryMiniList } from './InventoryMiniList'
import { SetPieceType } from '@/models'
import { InspectionInventoryItem } from '@/loaders/InspectionInventoryLoader'
import { InventoryItemDetailsActions } from './InventoryItemDetailsActions'
import { PieceImageViewer } from './PieceImageViewer'

interface InventoryItemDetailProps
  extends RouteComponentProps,
    InspectionInventoryItem {
  nextInventory?: InspectionInventory
  submitOnBlur?: boolean
}

interface InventoryItemState {
  dependentInventory: InspectionInventory[]
}

export class InventoryItemDetails extends React.Component<
  InventoryItemDetailProps,
  InventoryItemState
> {
  private inputRef: React.RefObject<HTMLInputElement>

  ifInspectionInventoryFound = (payload: any) => {
    const setPieceId = payload['set_piece_id']
    const inspectionId = payload['inspection_id']
    return (
      this.props.inventory &&
      this.props.inventory.inspection_id === inspectionId &&
      this.props.inventory.relying_set_piece_ids &&
      !!this.props.inventory.relying_set_piece_ids.find(
        (id) => id === setPieceId
      )
    )
  }
  updateInspectionInventory = (payload: InspectionInventory[]) => {
    const dependentInventory = [...this.state.dependentInventory]
    payload.map((inv) => {
      const currentIndex = dependentInventory.findIndex(
        (dependent) => dependent._id === inv._id
      )
      if (currentIndex > -1) {
        dependentInventory.splice(currentIndex, 1, inv)
      } else {
        dependentInventory.push(inv)
      }
    })
    this.setState({
      dependentInventory
    })
  }

  constructor(props: InventoryItemDetailProps) {
    super(props)
    this.state = {
      dependentInventory: []
    }
    this.inputRef = React.createRef()
  }

  componentDidMount() {
    InspectionInventoryService.on(
      InspectionInventoryOperations.GetBySetPieceId,
      this.ifInspectionInventoryFound,
      this.updateInspectionInventory
    )
    if (this.props.inventory.relying_set_piece_ids) {
      this.props.inventory.relying_set_piece_ids.map((id) => {
        InspectionInventoryService.sendRequest(
          InspectionInventoryOperations.GetBySetPieceId,
          {
            inspection_id: this.props.inventory.inspection_id,
            set_piece_id: id
          }
        )
      })
    }
  }

  componentWillUnmount() {
    InspectionInventoryService.off(
      InspectionInventoryOperations.GetBySetPieceId,
      this.ifInspectionInventoryFound,
      this.updateInspectionInventory
    )
  }

  render() {
    const colorName = getNormalizedColorName(this.props.color.common)
    const tag = getTypeTag(this.props.setPiece.type)
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          borderBottom: '1px solid #CCC'
        }}
      >
        <div
          style={{
            padding: '10px'
          }}
        >
          <PieceImageViewer
            onUpdate={() => this.focusInput()}
            pieceId={this.props.piece.id}
            hexColor={this.props.color.hex}
            src={this.props.piece.image_url}
          />
        </div>
        <div
          style={{
            flex: '1',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="inventoryItemDetailQuantity">
              x {this.props.inventory.expected_quantity}
            </div>
            <div style={{ flex: '1', paddingLeft: '10px' }}>
              <h3>
                {titleCase(colorName)} {normalizeName(this.props.part.name)}{' '}
                {tag.include && <Tag color={tag.color}>{tag.text}</Tag>}
              </h3>
            </div>
          </div>
          {this.props.inventory.type === SetPieceType.Sticker && (
            <Alert
              message="Please review before marking complete or incomplete."
              description="Stickers are not guaranteed to have corresponding applied sticker inventory items although they may be present."
              type="warning"
            />
          )}
          {this.state.dependentInventory.length > 0 && (
            <div>
              <p>This Part is Linked With:</p>
              <InventoryMiniList inventory={this.state.dependentInventory} />
            </div>
          )}
          <InventoryItemDetailsActions
            {...this.props}
            submitOnBlur={this.props.submitOnBlur}
            inputRef={this.inputRef}
            nextInspectionItemId={
              this.props.nextInventory && this.props.nextInventory._id
            }
          />
        </div>
      </div>
    )
  }

  private focusInput() {
    this.inputRef.current.focus()
  }
}
