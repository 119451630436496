import { OfflineService, CacheResolver } from '@/services/interfaces'
import Api from '@/services/api'
import PouchDB from 'pouchdb'
import { SetRecord } from '@/models/SetRecord'
import { createIndexForProperty } from '@/services/indexer'
import pouchFind from 'pouchdb-find'
import { Database } from '@/database/indexedDB'
import { IDatabase } from '@/database/interface'
PouchDB.plugin(pouchFind)

export enum SetOperations {
  Search = 'Search',
  GetById = 'GetById',
  GetBySetNumber = 'GetBySetNumber',
  GetManyByIds = 'GetManyByIds'
}

function SetRecordsEqual(a: SetRecord, b: SetRecord) {
  return (
    a.id == b.id &&
    a.description == b.description &&
    a.has_inventory == b.has_inventory &&
    a.minifigures == b.minifigures &&
    a.msrp == b.msrp &&
    a.name == b.name &&
    a.pieces == b.pieces &&
    a.set_number == b.set_number &&
    a.theme == b.theme &&
    a.weight == b.weight &&
    a.year == b.year
  )
}

export const SetsDb = new Database<SetRecord>('sets', {
  install: (store) => {
    createIndexForProperty(store, 'set_number')
    createIndexForProperty(store, 'name')
    createIndexForProperty(store, 'id')
  }
})

class SetResolver implements CacheResolver<SetRecord> {
  db: IDatabase<SetRecord>

  constructor() {
    this.db = SetsDb
  }

  resolve(operation: string, payload: any) {
    if (
      operation == SetOperations.Search ||
      operation == SetOperations.GetBySetNumber
    ) {
      const search: string = payload
      return this.db
        .find({
          selector: {
            set_number: {
              $regex: search
            }
          }
        })
        .then((data) => data.docs.map((d) => d as SetRecord))
    }

    if (operation == SetOperations.GetById) {
      const set_id: number = payload
      return this.db
        .find({
          selector: {
            id: set_id
          }
        })
        .then((data) => data.docs.map((d) => d as SetRecord))
    }

    if (operation === SetOperations.GetManyByIds) {
      const set_ids: string[] = payload
      return this.db
        .find({
          selector: {
            id: { $in: set_ids }
          }
        })
        .then((data) => data.docs.map((d) => d as SetRecord))
    }

    return Promise.resolve([])
  }

  async cache(operation: string, payload: any, data: SetRecord[]) {
    if (
      operation === SetOperations.GetById ||
      operation === SetOperations.GetManyByIds
    ) {
      for (let set of data) {
        const set_id: number =
          operation === SetOperations.GetManyByIds ? set.id : payload
        const docs = await this.db
          .find({
            selector: {
              id: set_id
            }
          })
          .then((data) => data.docs)

        const [existing] = docs

        if (existing) {
          set._id = existing._id
          set._rev = existing._rev
        } else {
          set._id = set_id.toString()
        }

        this.db.put(set)
      }
    }

    if (
      operation == SetOperations.Search ||
      operation == SetOperations.GetBySetNumber
    ) {
      const setIds = data.map((d) => d.id)
      if (!setIds.length) {
        return
      }

      const existing = await this.db
        .find({
          selector: {
            id: {
              $in: setIds
            }
          }
        })
        .then((result) => result.docs)

      let updates = []
      for (let record of data) {
        let match = existing.find((e) => e.id === record.id)

        if (match && !SetRecordsEqual(match, record)) {
          let updatedRecord = record as any
          updatedRecord._id = match._id
          updatedRecord._rev = match._rev
          updates.push(updatedRecord)
        }

        if (!match) {
          let createRecord = record as any
          createRecord._id = this.getKey(record)
          updates.push(createRecord)
        }
      }

      if (updates) {
        this.db.bulkDocs(updates)
      }
    }
  }

  getKey(set: SetRecord): string {
    return set.id.toString()
  }
}

class setService extends OfflineService<SetRecord> {
  constructor() {
    super(new SetResolver())
    this.name = 'SetService'
    this.registerSource(SetOperations.Search, (input: string) =>
      Api.doSearch(input)
    )
    this.registerSource(SetOperations.GetBySetNumber, (input: string) =>
      Api.doSearch(input)
    )
    this.registerSource(SetOperations.GetManyByIds, (input: string[]) =>
      Api.getManyById(...input)
    )
    this.registerSource(SetOperations.GetById, (input: string) =>
      Api.getManyById(input)
    )
    this.resolveWith((local, online) => {
      if (local.length) {
        return local
      }
      return online
    })
  }

  search(query: string) {
    return this.sendRequest(SetOperations.GetBySetNumber, query)
  }

  getBySetNumber(setNumber: string) {
    return this.sendRequest(SetOperations.GetBySetNumber, setNumber)
  }

  getById(id: number) {
    return this.sendRequest(SetOperations.GetById, id)
  }

  getManyByIds(ids: number[]) {
    return this.sendRequest(SetOperations.GetManyByIds, ids)
  }
}

const SetService = new setService()

export default SetService
