import { InventoryQueueProps, InventoryQueueState } from './InventoryQueue'
import { SetPiece, InspectionInventory } from '@/models'
import { SetPieceType } from '@/models/SetPiece'

export enum SortMethods {
  SortByUpdated,
  SortByName,
  SortBySize,
  SortByType,
  SortByColor
}

export interface InventorySetPiece {
  Inventory: InspectionInventory
  SetPiece: SetPiece
}

export function executeFilterSort(
  props: InventoryQueueProps,
  state: InventoryQueueState,
  sortOrder?: SortMethods[]
) {
  const filtered = executeFilter(props, state)
  const list = executeSort(filtered, sortOrder)
  return {
    list,
    hiddenItems: props.Inventory.length - filtered.length
  }
}

function executeFilter(props: InventoryQueueProps, state: InventoryQueueState) {
  const { setPieces, colorFilters, nameFilter } = state
  const { Inventory } = props
  const usedSetPieces = setPieces.filter((setPiece) => {
    if (colorFilters.indexOf(setPiece.color_id) === -1) {
      return false
    }

    if (
      nameFilter &&
      setPiece.name.toLowerCase().indexOf(nameFilter.toLowerCase()) === -1
    ) {
      return false
    }

    return true
  })

  const usedSetPieceMap = usedSetPieces.reduce(
    (map: Map<number, SetPiece>, setPiece) => {
      map.set(setPiece.id, setPiece)
      return map
    },
    new Map<number, SetPiece>()
  )

  return Inventory.reduce((list: InventorySetPiece[], inventory) => {
    if (usedSetPieceMap.has(inventory.set_piece_id)) {
      list.push({
        Inventory: inventory,
        SetPiece: usedSetPieceMap.get(inventory.set_piece_id)
      } as InventorySetPiece)
    }
    return list
  }, [] as InventorySetPiece[])
}

function extractSize(input: string) {
  const expression = /([0-9]+)([^0-9]*\sx\s)([0-9]+)([^0-9]*\sx\s)?([0-9]+)?/gm
  const match = expression.exec(input)
  if (match) {
    try {
      let size = parseInt(match[1]) * parseInt(match[3])
      if (match[5]) {
        size = size * parseInt(match[5])
      }
      return size
    } catch (e) {}
  }
  return 0
}

const typeUpSortOrder = [
  SetPieceType.Instructions,
  SetPieceType.Minifigure,
  SetPieceType.Stickered
]
const typeDownSortOrder = [
  SetPieceType.Sticker,
  SetPieceType.Extra,
  SetPieceType.Alternate
]
function getTypeIndex(type: SetPieceType) {
  let index = typeUpSortOrder.reverse().indexOf(type)
  if (index < 0) {
    const downIndex = typeDownSortOrder.indexOf(type)
    if (downIndex >= 0) {
      index = index - 1 - downIndex
    }
  }
  return index
}

type SortFunction = (a: InventorySetPiece, b: InventorySetPiece) => number
export const sortMethodToFunction = new Map<SortMethods, SortFunction>([
  [
    SortMethods.SortByName,
    (a, b) => {
      const { name: nameA } = a.SetPiece
      const { name: nameB } = b.SetPiece

      if (nameA > nameB) {
        return 1
      }

      if (nameA < nameB) {
        return -1
      }

      return 0
    }
  ],
  [
    SortMethods.SortBySize,
    (a, b) => {
      const { name: nameA } = a.SetPiece
      const { name: nameB } = b.SetPiece
      const sizeA = extractSize(nameA)
      const sizeB = extractSize(nameB)

      if (sizeA > sizeB) {
        return -1
      }

      if (sizeA < sizeB) {
        return 1
      }

      return 0
    }
  ],
  [
    SortMethods.SortByType,
    (a, b) => {
      const { type: typeA } = a.SetPiece
      const { type: typeB } = b.SetPiece
      const typeIndexA = getTypeIndex(typeA)
      const typeIndexB = getTypeIndex(typeB)

      if (typeIndexA > typeIndexB) {
        return -1
      }
      if (typeIndexA < typeIndexB) {
        return 1
      }

      return 0
    }
  ],
  [
    SortMethods.SortByColor,
    (a, b) => {
      const { color_id: colorA } = a.SetPiece
      const { color_id: colorB } = b.SetPiece

      if (colorA > colorB) {
        return -1
      }
      if (colorA < colorB) {
        return 1
      }

      return 0
    }
  ],
  [
    SortMethods.SortByUpdated,
    (a, b) => {
      const { updated: updatedA } = a.Inventory
      const { updated: updatedB } = b.Inventory

      if (updatedA > updatedB) {
        return -1
      }
      if (updatedA < updatedB) {
        return 1
      }
      return 0
    }
  ]
])

export function executeSort(
  list: InventorySetPiece[],
  sortOrder?: SortMethods[]
) {
  sortOrder = sortOrder || [
    SortMethods.SortByName,
    SortMethods.SortBySize,
    SortMethods.SortByColor,
    SortMethods.SortByType
  ]

  return sortOrder
    .reduce((sortedList, sortMethod) => {
      return sortedList.sort(sortMethodToFunction.get(sortMethod))
    }, list)
    .map((list) => list.Inventory)
}
