import * as React from 'react'
import { Loader } from '@/loaders/Loader'
import { InspectionInventory, Piece, Part, Color, SetPiece } from '@/models'
import { PieceImage } from '@/models/PieceImage'
import SetPieceService, { SetPieceOperations } from '@/services/set_pieces'
import InspectionInventoryService, {
  InspectionInventoryOperations
} from '@/services/inspection_inventory'
import PieceService, { PieceOperations } from '@/services/pieces'
import ColorService, { ColorOperations } from '@/services/color'
import PartService, { PartOperations } from '@/services/parts'
import { SetPieceItem } from './SetPieceLoader'

export interface InspectionInventoryItem extends SetPieceItem {
  inventory: InspectionInventory
}

interface Props {
  inventory_item_id: string
  inventory?: InspectionInventory
  loadingRenderer?: React.ComponentType
  children(inspectionInventoryItem: InspectionInventoryItem): React.ReactNode
}

interface State {
  loading: boolean
  inventory?: InspectionInventory
  setPiece?: SetPiece
  piece?: Piece
  part?: Part
  color?: Color
  pieceImage?: PieceImage
}

export class InspectionInventoryLoader extends React.Component<Props, State> {
  private unmounting = false

  public state: State = {
    loading: true,
    inventory: this.props.inventory
  }

  public render() {
    const isValid = this.isValidInspectionInventoryItem()
    return (
      <Loader
        loading={this.state.loading || !isValid}
        loadingRenderer={this.props.loadingRenderer}
      >
        {isValid
          ? this.props.children(this.emitInspectionInventoryItem())
          : null}
      </Loader>
    )
  }

  public componentDidMount = () => {
    InspectionInventoryService.on(
      InspectionInventoryOperations.Get,
      this.ifInventory,
      this.updateInventory
    )
    this.fetchUpdatedInventoryItemPiece(this.props.inventory_item_id).then(
      () => {
        this.setState({
          loading: false
        })
        SetPieceService.on(
          SetPieceOperations.Get,
          this.ifSetPiece,
          this.updateSetPiece
        )
        PieceService.on(PieceOperations.Get, this.ifPiece, this.updatePiece)
        PartService.on(PartOperations.Get, this.ifPart, this.updatePart)
        ColorService.on(ColorOperations.Get, this.ifColor, this.updateColor)
      }
    )
  }

  public componentDidUpdate = (prevProps: Props) => {
    const { inventory_item_id } = this.props
    const inventoryStale =
      this.state.inventory && inventory_item_id !== this.state.inventory._id
    const inventoryChange = !inventory_item_id || inventoryStale

    if (inventoryChange && !this.unmounting) {
      this.setState(
        {
          inventory: this.props.inventory || null,
          setPiece: null,
          piece: null,
          part: null,
          color: null
        },
        () => {
          if (inventory_item_id) {
            this.fetchUpdatedInventoryItemPiece(inventory_item_id)
          }
        }
      )
    }
  }

  public componentWillUnmount = () => {
    this.unmounting = true
    InspectionInventoryService.off(
      InspectionInventoryOperations.Get,
      this.ifInventory,
      this.updateInventory
    )
    SetPieceService.off(
      SetPieceOperations.Get,
      this.ifSetPiece,
      this.updateSetPiece
    )
    PieceService.off(PieceOperations.Get, this.ifPiece, this.updatePiece)
    PartService.off(PartOperations.Get, this.ifPart, this.updatePart)
    ColorService.off(ColorOperations.Get, this.ifColor, this.updateColor)
  }

  private fetchUpdatedInventoryItemPiece = (id: string): Promise<void> => {
    if (this.state.inventory && this.state.inventory._id === id) {
      const promises: Promise<any[]>[] = [
        !this.state.setPiece
          ? SetPieceService.sendRequest(
              SetPieceOperations.Get,
              this.state.inventory.set_piece_id
            )
          : Promise.resolve([this.state.setPiece]),
        !this.state.part
          ? PartService.getById(this.state.inventory.part_id).then((x) => [x])
          : Promise.resolve([this.state.part]),
        !this.state.piece
          ? PieceService.sendRequest(
              PieceOperations.Get,
              this.state.inventory.piece_id
            )
          : Promise.resolve([this.state.piece]),
        !this.state.color
          ? ColorService.sendRequest(
              ColorOperations.Get,
              this.state.inventory.color_id
            )
          : Promise.resolve([this.state.color])
      ]
      return Promise.all(promises)
        .then(([[setPiece], [part], [piece], [color]]) => {
          return new Promise<void>((resolve) => {
            if (this.unmounting) {
              return
            }
            if (this.props.inventory.part_id === 535) {
              console.log(this.state.part, { setPiece, part, piece, color })
            }
            this.setState(
              {
                setPiece,
                part,
                piece,
                color
              },
              resolve
            )
          })
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      return InspectionInventoryService.sendRequest(
        InspectionInventoryOperations.Get,
        id
      ).then(() => {})
    }
  }

  private emitInspectionInventoryItem = () => {
    if (!this.isValidInspectionInventoryItem()) {
      throw new Error('Cannot emit an invalid inspection inventory item')
    }
    const { inventory, setPiece, piece, part, color } = this.state

    const out: InspectionInventoryItem = {
      inventory,
      setPiece,
      piece,
      part,
      color
    }
    return out
  }

  private isValidInspectionInventoryItem = () => {
    return !!(
      this.state.inventory &&
      this.state.piece &&
      this.state.setPiece &&
      this.state.part &&
      this.state.color
    )
  }

  private ifInventory = (payload: any) =>
    this.props.inventory_item_id === (payload as string)
  private updateInventory = (data: InspectionInventory[]) => {
    const [inventory] = data
    this.setState(
      (state) => {
        const setPieceChanged =
          this.state.setPiece &&
          inventory.set_piece_id !== this.state.setPiece.id
        const partChanged =
          this.state.part && inventory.part_id !== this.state.part.id
        const pieceChanged =
          this.state.part && inventory.piece_id !== this.state.piece.id
        const colorChanged =
          this.state.part && inventory.color_id !== this.state.color.id
        return {
          inventory,
          setPiece: setPieceChanged ? null : state.setPiece,
          part: partChanged ? null : state.part,
          piece: pieceChanged ? null : state.piece,
          color: colorChanged ? null : state.color,
          pieceImage: null
        }
      },
      () => {
        !this.state.setPiece &&
          SetPieceService.sendRequest(
            SetPieceOperations.Get,
            inventory.set_piece_id
          )
        !this.state.part &&
          PartService.sendRequest(PartOperations.Get, inventory.part_id)
        !this.state.piece &&
          PieceService.sendRequest(PieceOperations.Get, inventory.piece_id)
        !this.state.color &&
          ColorService.sendRequest(ColorOperations.Get, inventory.color_id)
      }
    )
  }

  private ifSetPiece = (payload: any) =>
    this.state.inventory &&
    this.state.inventory.set_piece_id === (payload as number)
  private updateSetPiece = (data: SetPiece[]) => {
    const [setPiece] = data
    if (!this.state.setPiece || this.state.setPiece._rev !== setPiece._rev) {
      this.setState({
        setPiece
      })
    }
  }

  private ifPiece = (payload: any) =>
    this.state.inventory &&
    this.state.inventory.piece_id === (payload as number)
  private updatePiece = (data: Piece[]) => {
    const [piece] = data
    if (!this.state.piece || this.state.piece._rev !== piece._rev) {
      this.setState({
        piece
      })
    }
  }

  private ifPart = (payload: any) =>
    this.state.inventory && this.state.inventory.part_id === (payload as number)
  private updatePart = (data: Part[]) => {
    const [part] = data
    if (!this.state.part || this.state.part._rev !== part._rev) {
      this.setState({
        part
      })
    }
  }

  private ifColor = (payload: any) =>
    this.state.inventory &&
    this.state.inventory.color_id === (payload as number)
  private updateColor = (data: Color[]) => {
    const [color] = data
    if (!this.state.color || this.state.color._rev !== color._rev) {
      this.setState({
        color
      })
    }
  }
}
