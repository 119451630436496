import { Database, uuidv4 } from '@/database/indexedDB'
import { IDatabase, Query } from '@/database/interface'
import { Collection } from '@/models/Collection'
import { createIndexForProperty } from './indexer'

export const CollectionsDB = new Database<Collection>('collections', {
  install: (store) => {
    createIndexForProperty(store, 'sellSubmissionId')
  },
  onUpdateNeeded: ({ oldVersion }, store) => {
    if (oldVersion < 17) {
      createIndexForProperty(store, 'archivedOn')
      createIndexForProperty(store, 'createdOn')
    }
  }
})

class _CollectionsService {
  private db: IDatabase<Collection>

  public constructor() {
    this.db = CollectionsDB
  }

  public getAll() {
    return this.db
      .find({ selector: { archivedOn: -1 } })
      .then(({ docs }) => docs)
  }

  public create(
    collection: Omit<Collection, '_id' | 'archivedOn' | 'createdOn'>
  ) {
    return this.db.put({
      _id: uuidv4(),
      ...collection,
      archivedOn: -1,
      createdOn: Date.now()
    })
  }

  public update(collection: Collection) {
    return this.db.put(collection)
  }

  public delete(collection: Collection) {
    return this.db.remove(collection)
  }

  public find(query: Query) {
    return this.db.find(query)
  }
}

const CollectionsService = new _CollectionsService()

export default CollectionsService
