import { useInspectionsContext } from '@/providers/Inspections/hooks'
import { LoadingOutlined } from '@ant-design/icons'
import { Col, Row, Tabs } from 'antd'
import * as React from 'react'
import InspectionService from '@/services/inspections'
import {
  ExpandedInspection,
  InspectionsContext
} from '@/providers/Inspections/Context'
import { SetSearch } from '@/components/SetSearch'
import { Content, Header } from 'antd/lib/layout/layout'
import { Inspection, SetRecord } from '@/models'
import { InspectionViewerTab } from './InspectionsViewerTab'

interface Props {
  collectionId?: string
  context: InspectionsContext
}

class _InspectionsViewer extends React.Component<Props> {
  private selector = { collectionId: this.props.collectionId || '' }

  private get isInitialState() {
    return !this.props.context.inspections
  }

  public componentDidMount() {
    this.props.context.populate({ selector: this.selector }, true)
  }

  public render() {
    if (this.isInitialState) {
      return <LoadingOutlined />
    }

    const {
      inspectionsAllComplete,
      inspectionsWithMissingPieces,
      inspectionsInProgress
    } = (this.props.context.inspections || []).reduce(
      (carry, inspection) => {
        if (
          inspection.inspection.statusQty === undefined ||
          inspection.inspection.totalQty > inspection.inspection.statusQty
        ) {
          carry.inspectionsInProgress.push(inspection)
        } else if (inspection.inspection.missingQty > 0) {
          carry.inspectionsWithMissingPieces.push(inspection)
        } else {
          carry.inspectionsAllComplete.push(inspection)
        }
        return carry
      },
      {
        inspectionsInProgress: [] as ExpandedInspection[],
        inspectionsAllComplete: [] as ExpandedInspection[],
        inspectionsWithMissingPieces: [] as ExpandedInspection[]
      }
    )

    return (
      <>
        <Header style={{ background: '#40a9ff' }}>
          <Row align="middle" justify="center">
            <Col>
              <div style={{ paddingTop: '1rem' }}>
                <SetSearch onSearch={this.onSearchSubmit} />
              </div>
            </Col>
          </Row>
        </Header>
        <Content style={{ padding: '1rem', background: 'white' }}>
          <Tabs
            defaultActiveKey={
              !inspectionsInProgress.length &&
              inspectionsWithMissingPieces.length
                ? 'missing'
                : 'in-progress'
            }
          >
            <Tabs.TabPane tab="In Progress" key="in-progress">
              <InspectionViewerTab
                collectionId={this.props.collectionId}
                inspections={inspectionsInProgress}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Missing Pieces" key="missing">
              <InspectionViewerTab
                collectionId={this.props.collectionId}
                inspections={inspectionsWithMissingPieces}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Complete" key="complete">
              <InspectionViewerTab
                collectionId={this.props.collectionId}
                inspections={inspectionsAllComplete}
              />
            </Tabs.TabPane>
          </Tabs>
        </Content>
      </>
    )
  }

  private onSearchSubmit = async (setRecord: SetRecord) => {
    let newInspection: Inspection = {
      _id: Date.now() + ':' + setRecord.set_number,
      setNumber: setRecord.set_number,
      setId: setRecord.id,
      queueOn: Date.now(),
      startedOn: -1,
      completedOn: -1,
      lastActivity: -1,
      collectionId: this.props.collectionId || ''
    }

    await InspectionService.addInspection(newInspection)
    this.props.context.populate({ selector: this.selector })
  }
}

export const InspectionsViewer: React.FC<Omit<Props, 'context'>> = (props) => {
  const context = useInspectionsContext()
  return <_InspectionsViewer {...props} context={context} />
}
