import { Query } from '@/database/interface'
import { Collection } from '@/models/Collection'
import CollectionsService from '@/services/collections'
import * as React from 'react'
import { CollectionsContext, StatefulCollectionsContext } from './Context'

interface Props {
  children?: React.ReactNode | React.ReactNodeArray
}

export class CollectionsContextProvider extends React.Component<
  Props,
  StatefulCollectionsContext
> {
  public state = {
    loading: false
  }
  public render() {
    return (
      <CollectionsContext.Provider
        value={{
          ...this.state,
          create: this.create,
          populate: this.populate,
          remove: this.remove,
          import: this.import
        }}
      >
        {this.props.children}
      </CollectionsContext.Provider>
    )
  }

  private populate = async (query?: Query) => {
    this.setState({ loading: true })
    query ? this.refreshWithQuery(query) : this.refreshAllFromService()
  }

  private refreshAllFromService = async () => {
    const queryResult = await CollectionsService.getAll()
    const collections = queryResult.map((collection) => {
      return {
        collection
      }
    })
    this.setState({ loading: false, collections })
  }

  private refreshWithQuery = async (query: Query) => {
    const queryResult = await CollectionsService.find(query)
    const collections = queryResult.docs.map((collection) => {
      return {
        collection
      }
    })
    this.setState({ loading: false, collections })
  }

  private create = async (
    collection: Omit<Collection, '_id' | 'archivedOn' | 'createdOn'>
  ) => {
    const tempCollection: Collection = {
      _id: null,
      archivedOn: null,
      createdOn: null,
      ...collection
    }
    this.setState((state) => {
      const currentCollections = state.collections || []
      return {
        collections: [{ collection: tempCollection }, ...currentCollections]
      }
    })
    await CollectionsService.create(collection)
    this.refreshAllFromService()
  }

  private remove = async (collection: Collection) => {
    await CollectionsService.delete(collection)
    this.refreshAllFromService()
  }

  private import = (collections: Collection[]) => {
    this.setState((state) => {
      const currentCollections = state.collections || []
      return {
        collections: currentCollections.concat(
          collections.map((x) => ({
            collection: x
          }))
        )
      }
    })
  }
}
