export function printInspection(label?: string) {
  gtag('event', 'Printed', {
    event_category: 'Inspection',
    event_label: label,
    value: 0
  })
}

export function startInspection(setName?: string) {
  gtag('event', 'Completed', {
    event_category: 'Inspection',
    event_label: setName,
    value: 0
  })
}

export function completeInspection(partCount: number, setName?: string) {
  gtag('event', 'Completed', {
    event_category: 'Inspection',
    event_label: setName,
    value: partCount
  })
}

export function markInspectionInventory(partCount: number, pieceName?: string) {
  gtag('event', 'Marked', {
    event_category: 'Inspection',
    event_label: pieceName,
    value: partCount
  })
}

export function markInspectionInventoryRestComplete() {
  gtag('event', 'MarkedRestComplete', {
    event_category: 'Inspection'
  })
}
