import * as React from 'react'
import { Color, Part, Piece, SetRecord } from '@/models'
import { SetImage } from '@/models/SetImage'
import {
  DataSignature,
  SharedDataStoreContext,
  SupportedDataTypes
} from './Context'

export const useSharedData = <T>(signature: DataSignature<T>) => {
  const data = React.useContext(SharedDataStoreContext)
  React.useEffect(() => {
    const revoke = data.subscribeToDataRecord(signature)
    return () => {
      setTimeout(revoke, 1000)
    }
  }, [signature])
  return data.getFromSignature(signature)
}

export const useSharedDataDependencies = <T>(
  ...signatures: DataSignature<T>[]
) => {
  const data = React.useContext(SharedDataStoreContext)
  React.useEffect(() => {
    const revokeAllFunctions = signatures.map((dependency) => {
      const revoke = data.subscribeToDataRecord(dependency)
      return () => setTimeout(revoke, 1000)
    })
    return () =>
      revokeAllFunctions.forEach((revoke) => {
        revoke()
      })
  }, signatures)
  return signatures.map((signature) => data.getFromSignature(signature))
}

export function useSetRecord(identifier: number) {
  const signature = React.useMemo(
    () => ({
      type: SupportedDataTypes.SetRecord,
      identifier
    }),
    [identifier]
  )
  return useSharedData<SetRecord>(signature)
}

export function useSetImage(identifier: number) {
  const signature = React.useMemo(
    () => ({
      type: SupportedDataTypes.SetImage,
      identifier
    }),
    [identifier]
  )
  return useSharedData<SetImage>(signature)
}

export function usePiece(identifier: number) {
  const signature = React.useMemo(
    () => ({
      type: SupportedDataTypes.Piece,
      identifier
    }),
    [identifier]
  )
  return useSharedData<Piece>(signature)
}

export function usePart(identifier: number) {
  const signature = React.useMemo(
    () => ({
      type: SupportedDataTypes.Part,
      identifier
    }),
    [identifier]
  )
  return useSharedData<Part>(signature)
}

export function useColor(identifier: number) {
  const signature = React.useMemo(
    () => ({
      type: SupportedDataTypes.Color,
      identifier
    }),
    [identifier]
  )
  return useSharedData<Color>(signature)
}
