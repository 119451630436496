import {
  InspectionInventoryStatus,
  InspectionInventory
} from '@/models/InspectionInventory'
import { OfflineService, CacheResolver } from '@/services/interfaces'
import { createIndexForProperty } from '@/services/indexer'
import {
  default as InspectionService,
  InspectionOperations
} from '@/services/inspections'
import { Inspection } from '@/models'
import { SetPieceType } from '@/models/SetPiece'
import { IDatabase } from '@/database/interface'
import { Database } from '@/database/indexedDB'
import {
  markInspectionInventory,
  markInspectionInventoryRestComplete
} from '@/services/analytics'

export enum InspectionInventoryOperations {
  GetByInspectionId = 'GetByInspectionId',
  Get = 'Get',
  GetBySetPieceId = 'GetBySetPieceId',
  Add = 'Add',
  Put = 'Put',
  Merge = 'Merge',
  BulkAdd = 'BulkAdd',
  PartColorSearch = 'PartColorSearch',
  DeleteByInspectionId = 'DeleteByInspectionId'
}

export const InspectionInventoryDB = new Database<InspectionInventory>(
  'InspectionInventorys',
  {
    revs_limit: 1,
    install: (store) => {
      createIndexForProperty(store, 'inspection_id')
      createIndexForProperty(store, 'set_piece_id')
      createIndexForProperty(store, 'status')
      createIndexForProperty(store, 'part_id')
      createIndexForProperty(store, 'color_id')
    }
  }
)

class InspectionInventoryResolver
  implements CacheResolver<InspectionInventory> {
  db: IDatabase<InspectionInventory>

  constructor() {
    this.db = InspectionInventoryDB
  }

  async resolve(
    operation: string,
    payload: any
  ): Promise<InspectionInventory[]> {
    if (operation === InspectionInventoryOperations.Get) {
      const InspectionInventory_id = payload as string
      const doc: InspectionInventory = await this.db.get(InspectionInventory_id)
      return [doc]
    }

    if (operation === InspectionInventoryOperations.Add) {
      const InspectionInventory = payload as InspectionInventory
      await this.cache(
        InspectionInventoryOperations.Get,
        InspectionInventory._id,
        [InspectionInventory]
      )
    }

    if (operation === InspectionInventoryOperations.BulkAdd) {
      const inspection_inventories = payload as InspectionInventory[]
      await this.cache(
        InspectionInventoryOperations.BulkAdd,
        null,
        inspection_inventories
      )
    }

    if (operation === InspectionInventoryOperations.GetByInspectionId) {
      const inspection_id = payload as string
      return this.db
        .find({
          selector: {
            inspection_id: inspection_id
          }
        })
        .then((data) => data.docs.map((d) => d as InspectionInventory))
    }

    if (operation === InspectionInventoryOperations.GetBySetPieceId) {
      const set_piece_id = payload['set_piece_id'] as number
      const inspection_id = payload['inspection_id'] as string
      return this.db
        .find({
          selector: {
            set_piece_id: set_piece_id,
            inspection_id: inspection_id
          }
        })
        .then((data) => data.docs.map((d) => d as InspectionInventory))
    }

    if (operation === InspectionInventoryOperations.Put) {
      const InspectionInventory = payload as InspectionInventory
      if (!InspectionInventory._id) {
        return Promise.reject(new Error('Missing required _id field'))
      }
      await this.cache(
        InspectionInventoryOperations.Get,
        InspectionInventory._id,
        [InspectionInventory]
      )
    }

    if (operation === InspectionInventoryOperations.Merge) {
      const InspectionInventory = payload as Partial<InspectionInventory>
      if (!InspectionInventory._id) {
        return Promise.reject(new Error('Missing required _id field'))
      }
      const [inspection] = await this.resolve(
        InspectionInventoryOperations.Get,
        InspectionInventory._id
      )
      await this.cache(
        InspectionInventoryOperations.Get,
        InspectionInventory._id,
        [
          {
            ...inspection,
            ...InspectionInventory
          }
        ]
      )
    }

    if (operation === InspectionInventoryOperations.PartColorSearch) {
      const color_id = payload['color_id'] as string
      const part_id = payload['part_id'] as string
      return this.db
        .find({
          selector: {
            part_id,
            color_id
          }
        })
        .then((data) => data.docs.map((d) => d as InspectionInventory))
    }

    if (operation === InspectionInventoryOperations.DeleteByInspectionId) {
      const inspection_id = payload as string
      const result = await this.db.find({
        selector: {
          inspection_id
        }
      })
      const deleteSet = result.docs.map((result) => {
        const deleteResult = {
          ...result,
          _deleted: true
        }
        return deleteResult
      })
      await this.db.bulkDocs(deleteSet)
    }

    return Promise.resolve([])
  }

  async cache(operation: string, payload: any, data: InspectionInventory[]) {
    if (operation === InspectionInventoryOperations.BulkAdd) {
      await this.db.bulkDocs(data)
    }

    if (operation === InspectionInventoryOperations.Get) {
      for (let inspectionInventory of data) {
        const InspectionInventory_id = payload as string
        inspectionInventory._id = InspectionInventory_id.toString()
        this.db.put(inspectionInventory)
      }
    }
  }
}

class InspectionInventorys extends OfflineService<InspectionInventory> {
  constructor() {
    super(new InspectionInventoryResolver())
    this.name = 'InspectionInventoryService'
    this.resolveWith((cache, _) => cache)
  }

  async get(id: string) {
    const [InspectionInventory] = await this.cacheResolver.resolve(
      InspectionInventoryOperations.Get,
      id
    )
    return InspectionInventory
  }

  async setMissingPiece(id: string, qty: number) {
    const [InspectionInventory] = await this.cacheResolver.resolve(
      InspectionInventoryOperations.Get,
      id
    )
    InspectionInventory.updated = Date.now()
    InspectionInventory.missing_quantity = qty
    InspectionInventory.status = InspectionInventoryStatus.Incomplete
    await this.sendRequest(
      InspectionInventoryOperations.Put,
      InspectionInventory
    ).then(() => {
      markInspectionInventory(
        InspectionInventory.expected_quantity,
        InspectionInventory.piece_id.toString()
      )
      this.postUpdate(InspectionInventory)
    })
  }

  async setComplete(id: string) {
    const [InspectionInventory] = await this.cacheResolver.resolve(
      InspectionInventoryOperations.Get,
      id
    )
    InspectionInventory.updated = Date.now()
    InspectionInventory.missing_quantity = 0
    InspectionInventory.status = InspectionInventoryStatus.Complete
    await this.sendRequest(
      InspectionInventoryOperations.Put,
      InspectionInventory
    ).then(() => {
      markInspectionInventory(
        InspectionInventory.expected_quantity,
        InspectionInventory.piece_id.toString()
      )
      this.postUpdate(InspectionInventory)
    })
  }

  async getByInspectionInventory(inspectionId: string) {
    return this.sendRequest(
      InspectionInventoryOperations.GetByInspectionId,
      inspectionId
    )
  }

  async markRestComplete(inspectionId: string) {
    const inventory = await this.getByInspectionInventory(inspectionId)
    let inventoryRecord: InspectionInventory | undefined
    for (inventoryRecord of inventory) {
      if (inventoryRecord.status === InspectionInventoryStatus.Unknown) {
        inventoryRecord.updated = Date.now()
        inventoryRecord.missing_quantity = 0
        inventoryRecord.status = InspectionInventoryStatus.Complete
        await this.sendRequest(
          InspectionInventoryOperations.Put,
          inventoryRecord
        )
      }
    }
    this.updateWeighting(inspectionId)
    markInspectionInventoryRestComplete()
    this.sendRequest(
      InspectionInventoryOperations.GetByInspectionId,
      inspectionId
    )
    if (inventoryRecord) {
      this.sendRequest(InspectionInventoryOperations.Get, inventoryRecord._id)
    }
  }

  async deleteForInspection(inspection_id: string) {
    await this.sendRequest(
      InspectionInventoryOperations.DeleteByInspectionId,
      inspection_id
    )
  }

  async updateNote(id: string, note: string) {
    const [InspectionInventory] = await this.cacheResolver.resolve(
      InspectionInventoryOperations.Get,
      id
    )
    InspectionInventory.note = note
    await this.sendRequest(
      InspectionInventoryOperations.Put,
      InspectionInventory
    ).then(() => {
      this.postUpdate(InspectionInventory)
    })
  }

  async addMany(inventory: InspectionInventory[]) {
    const inspectionId = inventory[0].inspection_id
    await this.sendRequest(InspectionInventoryOperations.BulkAdd, inventory)
    this.sendRequest(
      InspectionInventoryOperations.GetByInspectionId,
      inspectionId
    )
  }

  private async postUpdate(InspectionInventory: InspectionInventory) {
    this.sendRequest(InspectionInventoryOperations.Get, InspectionInventory._id)
    this.updateWeighting(InspectionInventory.inspection_id)
    this.sendRequest(
      InspectionInventoryOperations.GetByInspectionId,
      InspectionInventory.inspection_id
    )
    this.sendRequest(InspectionInventoryOperations.Get, InspectionInventory._id)
  }
  private async updateWeighting(inspectionId: string) {
    this.once(
      InspectionInventoryOperations.GetByInspectionId,
      (payload) => payload === inspectionId,
      (inventory) => {
        let weighting = inventory.reduce(
          (tgt, inventory) => {
            const isNonStandardType =
              inventory.type === SetPieceType.Stickered ||
              inventory.type === SetPieceType.Alternate ||
              inventory.type === SetPieceType.Assembly

            if (
              inventory.type == SetPieceType.Extra ||
              (inventory.status === InspectionInventoryStatus.Unknown &&
                isNonStandardType)
            ) {
              return tgt
            }

            tgt.totalQty = tgt.totalQty + (inventory.expected_quantity || 0)
            if (inventory.status !== InspectionInventoryStatus.Unknown) {
              tgt.statusQty = tgt.statusQty + (inventory.expected_quantity || 0)
            }

            if (inventory.status === InspectionInventoryStatus.Incomplete) {
              tgt.missingQty =
                tgt.missingQty + (inventory.missing_quantity || 0)
            }

            return tgt
          },
          {
            totalQty: 0,
            statusQty: 0,
            missingQty: 0
          }
        )

        InspectionService.sendRequest(InspectionOperations.Patch, {
          _id: inspectionId,
          ...weighting
        } as Partial<Inspection>).then(() => {
          InspectionService.sendRequest(InspectionOperations.Get, inspectionId)
        })
      }
    )
  }
}

const InspectionInventoryService = new InspectionInventorys()

export default InspectionInventoryService
