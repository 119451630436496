import * as React from 'react'
import { Inspection } from '@/models/Inspection'
import { InventoryPane } from '@/components/Inventory/InventoryPane'
import { SetRecord } from '@/models/SetRecord'
import { usePageTitleEffect } from '../usePageTitleEffect'
import { InspectionMeta } from './InspectionMeta'

export interface InspectionPaneProps {
  inspection: Inspection
  set?: SetRecord
  hideMeta?: boolean
}

export const InspectionPane: React.FC<InspectionPaneProps> = (props) => {
  usePageTitleEffect(`${props.set.set_number} ${props.set.name}`)

  return (
    <>
      {!props.hideMeta && (
        <div style={{ padding: '10px 10px 0 10px' }}>
          <InspectionMeta set={props.set} inspection={props.inspection} />
        </div>
      )}
      {props.inspection && <InventoryPane Inspection={props.inspection} />}
    </>
  )
}
