import { Col, Row } from 'antd'
import * as React from 'react'
import { InventorySetPieceCard } from './InventorySetPieceCard'
import { LoadedInventorySetPieceWithPriceGuides } from './ReportDataLoader'

interface Props {
  loadedInventorySetPieces: LoadedInventorySetPieceWithPriceGuides[]
  customerViewEnabled: boolean
}

export const ReportMissingPieceList: React.FC<Props> = ({
  loadedInventorySetPieces,
  customerViewEnabled
}) => {
  const missingInventory = loadedInventorySetPieces.filter(
    (x) => !!x.Inventory.missing_quantity
  )
  const pageItemSize = 8
  const pages: { values: LoadedInventorySetPieceWithPriceGuides[] }[] = []
  const pageCount = Math.ceil(missingInventory.length / pageItemSize)
  for (let i = 0; i < pageCount; i++) {
    pages.push({
      values: missingInventory.slice(i * pageItemSize, (i + 1) * pageItemSize)
    })
  }
  return (
    <>
      {pages.map(({ values }, index) => {
        return (
          <MisssingPiecesPage
            key={`missing-inv-page-${index}`}
            loadedInventorySetPieces={values}
            customerViewEnabled={customerViewEnabled}
          />
        )
      })}
    </>
  )
}
const MisssingPiecesPage: React.FC<Props> = ({
  loadedInventorySetPieces,
  customerViewEnabled
}) => {
  return (
    <>
      <Row
        gutter={10}
        style={{
          marginRight: 0,
          marginLeft: 0
        }}
        className="printMissingPiecePage"
      >
        {loadedInventorySetPieces.map((x) => {
          return (
            <Col
              key={`report-inventory-item-${x.Inventory._id}`}
              span={12}
              className="printSafeItem"
            >
              <InventorySetPieceCard
                customerViewEnabled={customerViewEnabled}
                inventorySetPiece={x}
              />
            </Col>
          )
        })}
      </Row>
    </>
  )
}
