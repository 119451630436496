import * as React from 'react'
import { Link } from 'react-router-dom'
import { Affix, Col, Menu, Row } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import { Header } from 'antd/lib/layout/layout'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import SubMenu from 'antd/lib/menu/SubMenu'

export const TopBar: React.FC = ({ children }) => {
  const screen = useBreakpoint()
  const showText = screen.md ? true : false
  return (
    <Affix offsetTop={0}>
      <Header className="printHide">
        <Row>
          <Col>
            <Menu theme="dark" mode="horizontal">
              <SubMenu
                key="home-submenu"
                title={
                  <Link to="/dashboard">
                    <Avatar src="favicon.ico" size="large" />{' '}
                    {showText && 'Brick Inspector'}
                  </Link>
                }
              >
                <Menu.Item key="top-dashboard">
                  <Link to="/dashboard">Go To Dashboard</Link>
                </Menu.Item>
                <Menu.Item key="export-import">
                  <Link to="/export">Export/Import Data</Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Col>
          <Col flex="auto">{children}</Col>
        </Row>
      </Header>
    </Affix>
  )
}
