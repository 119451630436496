import { InventoryCollection, Part, SetPiece, SetPieceType } from '@/models'

export function computeRelyingSetPieces(Collection: InventoryCollection): void {
  for (let setPiece of Collection.setPieces.values()) {
    const part = Collection.parts.get(setPiece.part_id)
    const piece = Collection.pieces.get(setPiece.piece_id)
    let relyingSetPieces: SetPiece[] = []

    if (setPiece.type === SetPieceType.Stickered) {
      const parentPartCode = part.code.substring(0, part.code.indexOf('pb'))
      const parentPart = findPartCode(Collection.parts, parentPartCode)
      if (parentPart) {
        relyingSetPieces = filterForPartAndColor(
          Collection.setPieces,
          parentPart.id,
          setPiece.color_id
        )
      }
    }

    if (setPiece.type === SetPieceType.Extra) {
      for (let otherSetPiece of Collection.setPieces.values()) {
        if (
          otherSetPiece.id !== setPiece.id &&
          otherSetPiece.piece_id === setPiece.piece_id
        ) {
          otherSetPiece.quantity = otherSetPiece.quantity - setPiece.quantity
          relyingSetPieces.push(otherSetPiece)
        }
      }
    }

    if (piece.hasInventory) {
      relyingSetPieces = filterForParentId(Collection.setPieces, piece.id)
    }

    if (setPiece.type === SetPieceType.Assembly) {
      setPiece.color_id
      const match = part.name.match(/\(([A-z0-9\/\s]+)\)$/)
      if (match) {
        const parts = match[1]
          .split('/')
          .map((str) => findPartCode(Collection.parts, str.trim()))
          .filter(Boolean)

        if (parts[0]) {
          relyingSetPieces = [
            ...filterForPartAndColor(
              Collection.setPieces,
              parts[0].id,
              setPiece.color_id
            )
          ]
        }

        for (let i = 1; i < parts.length; i++) {
          const candidates = findAllByPart(
            Collection.setPieces,
            parts[i].id
          ).reduce((hits, setPiece) => {
            const part = Collection.parts.get(setPiece.part_id)
            const color = Collection.colors.get(setPiece.color_id)
            if (part.name.toLowerCase().indexOf(color.name.toLowerCase())) {
              hits.push(setPiece)
            }
            return hits
          }, [])

          relyingSetPieces = [...candidates, ...relyingSetPieces]
        }
      }
    }

    applyReliesOn(relyingSetPieces, setPiece.id)
    setPiece.relying_set_piece_id = relyingSetPieces.map((inv) => inv.id)
  }
}

function findAllByPart(
  setPieces: Map<number, SetPiece>,
  id: number
): SetPiece[] {
  const list: SetPiece[] = []
  for (let setPiece of setPieces.values()) {
    if (setPiece.part_id === id) {
      list.push(setPiece)
    }
  }
  return list
}

function applyReliesOn(inventory: SetPiece[], id: number): void {
  inventory.forEach((inv) => {
    inv.relying_set_piece_id = inv.relying_set_piece_id || []
    inv.relying_set_piece_id.push(id)
  })
}

function filterForPartAndColor(
  setPieces: Map<number, SetPiece>,
  parentPartId: number,
  colorId: number
): SetPiece[] {
  const list: SetPiece[] = []
  for (let setPiece of setPieces.values()) {
    if (
      setPiece.part_id === parentPartId &&
      !setPiece.parent_piece_id &&
      setPiece.color_id === colorId
    ) {
      list.push(setPiece)
    }
  }
  return list
}

function filterForParentId(
  setPieces: Map<number, SetPiece>,
  parentPieceId: number
): SetPiece[] {
  const list: SetPiece[] = []
  for (let setPiece of setPieces.values()) {
    if (setPiece.parent_piece_id === parentPieceId) {
      list.push(setPiece)
    }
  }
  return list
}

function findPartCode(parts: Map<number, Part>, code: string) {
  for (let part of parts.values()) {
    const partWithoutVarriants = part.code.replace(/[a-z]$/, '')
    if (partWithoutVarriants === code) {
      return part
    }
  }
}
