import * as React from 'react'
import {
  normalizeName,
  getNormalizedColorName,
  getTypeTag
} from '@/components/Inventory/inventoryItemUtils'
import { Card, Row, Col, Tag, message } from 'antd'
import { InspectionInventoryItem } from '@/loaders/InspectionInventoryLoader'
import * as QRCode from 'qrcode.react'
const { Meta } = Card

interface MissingPieceListItemProps {
  disableDefaultAction?: boolean
  inspectionInventoryItem: InspectionInventoryItem
  setNumber: string
  hideQR?: boolean
  actions?: React.ReactNode[]
  titleExtra?: React.ReactNode
}

export function setPieceTypeToBricklinkCatalogType(type: string) {
  switch (type) {
    case 'instructions':
      return 'I'
    case 'minifig':
      return 'M'
    default:
      return 'P'
  }
}

export function MissingPieceListItem(props: MissingPieceListItemProps) {
  const {
    setPiece,
    part,
    inventory,
    color,
    piece
  } = props.inspectionInventoryItem
  const inputRef = React.createRef<HTMLInputElement>()
  const bricklinkType = setPieceTypeToBricklinkCatalogType(setPiece.type)
  let bricklinkPage =
    'https://www.bricklink.com/v2/catalog/catalogitem.page' +
    `?${bricklinkType}=${part.code}`
  if (bricklinkType === 'P') {
    bricklinkPage += `&idColor=${color.bl_id}`
  }

  const tag = getTypeTag(inventory.type)
  return (
    <div>
      <Card
        actions={props.actions}
        onClick={() => {
          if (props.disableDefaultAction) {
            return
          }
          inputRef.current.select()
          document.execCommand('copy')
          message.success('Copied quantity needed to clipboard.')
          window.open(bricklinkPage)
        }}
        hoverable
        cover={
          <Row align="middle">
            <Col span={11}>
              <div
                style={{
                  textAlign: 'center',
                  height: '11vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <img
                  alt={piece.name}
                  src={piece.image_url}
                  style={{ maxWidth: '12vh', maxHeight: '10.5vh' }}
                />
              </div>
            </Col>
            <Col span={12}>
              {inventory.note && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      margin: '0 1rem 0 0',
                      padding: '.25rem',
                      flex: '1',
                      border: '2px solid #f5222d',
                      borderRadius: '.25rem',
                      fontWeight: 'bold'
                    }}
                  >
                    {inventory.note}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        }
      >
        <Meta
          title={
            <span>
              <small>Need</small> <strong>{inventory.missing_quantity}</strong>{' '}
              <small>of {inventory.expected_quantity}</small>
              {tag.include && <Tag color={tag.color}>{tag.text}</Tag>}{' '}
              {props.titleExtra}
            </span>
          }
          description={
            <div
              style={{ minHeight: '6vh', marginRight: '72px' }}
              className="smallerPrintText"
            >
              <b>{getNormalizedColorName(color.name)}</b>{' '}
              {normalizeName(piece.name)}
            </div>
          }
        />
      </Card>
      <div className="printSetNumber">{props.setNumber}</div>
      <div
        style={{
          width: '64px',
          height: '64px',
          position: 'absolute',
          bottom: '1rem',
          right: '1rem'
        }}
      >
        {!props.hideQR && <QRCode size={64} value={bricklinkPage} />}
        <input
          ref={inputRef}
          type="number"
          style={{ opacity: 0 }}
          defaultValue={inventory.missing_quantity}
        />
      </div>
    </div>
  )
}
