import { SetPieceType } from '@/models/SetPiece'
import { RouteComponentProps } from 'react-router'

const dummyTextarea = document.createElement('textarea')

export function getActiveInventoryIdFromRoute(props: RouteComponentProps) {
  const match = props.location.search
    .substring(1)
    .split('&')
    .map((keyValueString) => {
      return keyValueString.split('=')
    })
    .find((keyValue) => {
      return keyValue[0] === 'inventory_id'
    })
  return match && (match[1] as string)
}

export function normalizeName(input: string) {
  dummyTextarea.innerHTML = input
  return dummyTextarea.value
}

export function titleCase(input: string) {
  const inputArray = input.toLowerCase().split(' ')
  for (var i = 0; i < inputArray.length; i++) {
    inputArray[i] =
      inputArray[i].charAt(0).toUpperCase() + inputArray[i].slice(1)
  }
  return inputArray.join(' ')
}

export function getNormalizedColorName(name: string) {
  return name !== 'Not Applicable' ? `${titleCase(name)}: ` : ''
}

interface TypeTag {
  include: boolean
  color?: string
  text?: string
}

export function getTypeTag(type: SetPieceType): TypeTag {
  switch (type) {
    case SetPieceType.Regular:
    case SetPieceType.Instructions:
    case SetPieceType.Minifigure:
      return {
        include: false
      }
    case SetPieceType.Extra:
      return {
        include: true,
        color: 'green',
        text: 'extra'
      }
    case SetPieceType.Assembly:
      return {
        include: true,
        color: 'orange',
        text: 'assembly'
      }
    case SetPieceType.Stickered:
      return {
        include: true,
        color: 'purple',
        text: 'stickered'
      }
    case SetPieceType.Counter:
      return {
        include: true,
        color: 'gold',
        text: 'counter'
      }
    case SetPieceType.Alternate:
      return {
        include: true,
        color: 'cyan',
        text: 'alternate'
      }
    default:
      return {
        include: true,
        color: 'red',
        text: type
      }
  }
}
