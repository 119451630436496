import * as React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

interface Props {
  loading: boolean
  loadingRenderer?: React.ComponentType
  children: React.ReactNode
}

export function Loader(props: Props) {
  if (props.loading && props.loadingRenderer) {
    return <props.loadingRenderer />
  }

  if (props.loading && props.loadingRenderer) {
    return <LoadingOutlined />
  }

  return <>{props.children}</>
}
