import { OfflineService, CacheResolver } from '@/services/interfaces'
import Api from '@/services/api'
import PouchDB from 'pouchdb'
import { SetImage } from '@/models/SetImage'
import pouchFind from 'pouchdb-find'
import { installIndexOnProperty } from '@/services/indexer'
PouchDB.plugin(pouchFind)

export enum SetImageOperations {
  Get = 'Get',
  GetManyBySetId = 'GetManyBySetId'
}

class SetImageResolver implements CacheResolver<SetImage> {
  db: PouchDB.Database<SetImage>

  constructor() {
    this.db = new PouchDB<SetImage>('set_images', { revs_limit: 1 })

    this.db.getIndexes().then((result) => {
      installIndexOnProperty(this.db, result.indexes, 'setId')
    })
  }

  async resolve(operation: string, payload: any) {
    if (operation == SetImageOperations.Get) {
      const setId = payload as number
      const data = await this.db.find({
        selector: {
          setId: setId
        }
      })

      const images = data.docs.map((d) => {
        return this.db.getAttachment(d._id, d.src).then((blob) => {
          const setImage: SetImage = {
            _id: d._id,
            setId: setId,
            url: URL.createObjectURL(blob),
            blob: blob,
            src: d.src,
            contentType: d.contentType
          }
          return setImage
        })
      })
      return Promise.all(images)
    }

    if (operation === SetImageOperations.GetManyBySetId) {
      const setIds: number[] = payload
      const data = await this.db.find({
        selector: {
          setId: { $in: setIds }
        }
      })

      const images = data.docs.map((d) => {
        return this.db.getAttachment(d._id, d.src).then((blob) => {
          const setImage: SetImage = {
            _id: d._id,
            setId: d.setId,
            url: URL.createObjectURL(blob),
            blob: blob,
            src: d.src,
            contentType: d.contentType
          }
          return setImage
        })
      })
      return Promise.all(images)
    }

    return Promise.resolve([])
  }

  async cache(operation: string, payload: any, data: SetImage[]) {
    if (operation === SetImageOperations.Get) {
      for (let setImage of data) {
        const existing = await this.db
          .get(setImage.setId.toString(), {
            attachments: true
          })
          .catch(() => null)

        let dummyDoc: any = {
          _id: setImage.setId.toString(),
          setId: setImage.setId,
          src: setImage.src,
          contentType: setImage.contentType
        }

        if (existing) {
          dummyDoc._id = existing._id
          dummyDoc._rev = existing._rev
        }
        const updated = await this.db.put(dummyDoc)
        await this.db.putAttachment(
          dummyDoc._id,
          setImage.src,
          updated.rev,
          setImage.blob,
          setImage.contentType
        )
      }
    }
  }
}

class setImageService extends OfflineService<SetImage> {
  constructor() {
    super(new SetImageResolver())
    this.name = 'SetImageService'
    this.registerSource(SetImageOperations.Get, (input: number) =>
      Api.getSetImage(input)
    )
    this.resolveWith((_, online) => online)
  }

  getBySetId(id: number) {
    return this.sendRequest(SetImageOperations.Get, id)
  }

  getManyBySetIds(ids: number[]) {
    return this.sendRequest(SetImageOperations.GetManyBySetId, ids)
  }
}

const SetImageService = new setImageService()

export default SetImageService
