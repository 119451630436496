import * as React from 'react'
import { Menu, Input, Tag, Button } from 'antd'
import { Inspection, InspectionInventoryStatus } from '@/models'
import { SortDrawer, SortItemProps } from './SortDrawer'
import { FilterDrawer } from '@/components/Inspection/FilterDrawer'
import {
  CheckOutlined,
  FilterOutlined,
  SortAscendingOutlined
} from '@ant-design/icons'
import InspectionInventoryService from '@/services/inspection_inventory'

const { Item } = Menu

interface InventoryQueueMenuProps {
  activeQueue?: InspectionInventoryStatus
  hiddenItems?: number
  Inspection: Inspection
  currentFilterName?: string
  filterByName(terms: string): void
  ColorIds: number[]
  colorFilters: number[]
  onColorFilterChange(color: number, checked: boolean): void
  sortOrder: SortItemProps[]
  onSortChange(order: SortItemProps[]): void
  resetFilters(): void
}

interface MenuState {
  FilterPaneOpen: boolean
  SortPaneOpen: boolean
  filterInput?: string
}

export class InventoryQueueMenu extends React.Component<
  InventoryQueueMenuProps,
  MenuState
> {
  constructor(props: InventoryQueueMenuProps) {
    super(props)
    this.state = {
      filterInput: props.currentFilterName || '',
      FilterPaneOpen: false,
      SortPaneOpen: false
    }
  }

  render() {
    return (
      <div>
        <Menu mode="horizontal" selectable={false} onSelect={() => null}>
          <Item key="sort-options">
            <a
              onClick={() => {
                this.setState({
                  SortPaneOpen: true
                })
              }}
            >
              <SortAscendingOutlined />
              Sort By
            </a>
          </Item>
          <Item key="filter-options">
            <a
              onClick={() => {
                this.setState({
                  FilterPaneOpen: true
                })
              }}
            >
              <span>
                <FilterOutlined />
                Filters
              </span>
            </a>
          </Item>
          <Item key="search-options">
            <Input.Search
              style={{ verticalAlign: 'middle' }}
              value={this.state.filterInput}
              onChange={(evt) =>
                this.setState({ filterInput: evt.target.value })
              }
              placeholder="Filter By Name"
              onSearch={this.props.filterByName}
            />
          </Item>
          {!!this.props.hiddenItems && (
            <Item key="clear-filters-option">
              <Tag closable onClose={() => this.props.resetFilters()}>
                {this?.props.hiddenItems !== 1
                  ? this.props.hiddenItems + ' Items '
                  : ' Item '}{' '}
                Filtered
              </Tag>
            </Item>
          )}
          <Item key="mark-rest-complete" style={{ marginLeft: 'auto' }}>
            <Button
              type="text"
              onClick={() => {
                InspectionInventoryService.markRestComplete(
                  this.props.Inspection._id
                )
              }}
            >
              <CheckOutlined /> Mark Rest Complete
            </Button>
          </Item>
        </Menu>
        <FilterDrawer
          visible={this.state.FilterPaneOpen}
          onClose={() => {
            this.setState({
              FilterPaneOpen: false
            })
          }}
          colorOptions={this.props.ColorIds}
          colorFilters={this.props.colorFilters}
          onColorFilterChange={this.props.onColorFilterChange}
        />
        <SortDrawer
          activeQueue={this.props.activeQueue}
          order={this.props.sortOrder}
          visible={this.state.SortPaneOpen}
          onClose={() => {
            this.setState({
              SortPaneOpen: false
            })
          }}
          onChange={this.props.onSortChange}
        />
      </div>
    )
  }

  componentDidUpdate(prevProps: InventoryQueueMenuProps) {
    if (prevProps.currentFilterName !== this.props.currentFilterName) {
      this.setState({
        filterInput: this.props.currentFilterName
      })
    }
  }
}
