import { DataSignature, ShareDataSourceRecord } from './Context'
import { ColorReducer } from './Reducers/color'
import { PartReducer } from './Reducers/part'
import { PieceReducer } from './Reducers/piece'
import { SetImageReducer } from './Reducers/setImage'

export type Reducer<T = any, TIdentifier = any> = (
  record: ShareDataSourceRecord<any>
) => {
  handled: boolean
  resolve: (signature: DataSignature<T, TIdentifier>) => Promise<T>
}

const reducers: Reducer[] = [
  SetImageReducer,
  PieceReducer,
  PartReducer,
  ColorReducer
]

export function executeReducers(
  record: ShareDataSourceRecord<any>
): Promise<any> {
  for (let reducer of reducers) {
    const { handled, resolve } = reducer(record)
    if (handled) {
      return resolve(record.dependencySignature)
    }
  }
}
