import { SetPiece } from '@/models/SetPiece'
import { OfflineService, CacheResolver } from '@/services/interfaces'
import { createIndexForProperty } from '@/services/indexer'
import { IDatabase } from '@/database/interface'
import { Database } from '@/database/indexedDB'
import { KeyStore } from './keystore'
import Api from './api'

export enum SetPieceOperations {
  GetBySetId = 'GetBySetId',
  Get = 'Get',
  Add = 'Add',
  BulkAdd = 'BulkAdd',
  GetMany = 'GetMany',
  DeleteBySetId = 'DeleteBySetId'
}

export const SetPieceDB = new Database<SetPiece>('set_pieces', {
  install: (store) => {
    createIndexForProperty(store, 'id')
    createIndexForProperty(store, 'set_id')
    createIndexForProperty(store, 'color_id')
    createIndexForProperty(store, 'part_id')
    createIndexForProperty(store, 'piece_id')
    createIndexForProperty(store, 'parent_piece_id')
    createIndexForProperty(store, 'type')
  }
})

class SetPieceResolver implements CacheResolver<SetPiece> {
  db: IDatabase<SetPiece>
  store: KeyStore<number, SetPiece> = new KeyStore<number, SetPiece>()

  constructor() {
    this.db = SetPieceDB
  }

  async resolve(operation: string, payload: any): Promise<SetPiece[]> {
    if (operation === SetPieceOperations.Get) {
      const SetPiece_id = payload as number
      return this.store.get(SetPiece_id).catch(() => {
        return this.db
          .find({
            selector: {
              id: SetPiece_id
            }
          })
          .then((data) => data.docs.map((d) => d as SetPiece))
      })
    }

    if (operation === SetPieceOperations.GetBySetId) {
      const Set_id = payload as number
      return this.db
        .find({
          selector: {
            set_id: Set_id
          }
        })
        .then((data) => data.docs.map((d) => d as SetPiece))
    }

    if (operation === SetPieceOperations.Add) {
      const SetPiece = payload as SetPiece
      SetPiece._rev = SetPiece._rev || (1).toString()
      await this.cache(SetPieceOperations.Get, SetPiece.id, [SetPiece])
    }

    if (operation === SetPieceOperations.BulkAdd) {
      const set_pieces = payload as SetPiece[]
      await this.cache(SetPieceOperations.BulkAdd, null, set_pieces)
    }

    if (operation === SetPieceOperations.GetMany) {
      const setPieceIds = payload as number[]
      return await this.db
        .find({
          selector: {
            id: {
              $in: setPieceIds
            }
          }
        })
        .then((data) => data.docs.map((d) => d as SetPiece))
    }

    if (operation === SetPieceOperations.DeleteBySetId) {
      const set_id = payload as string
      if (!set_id) {
        return Promise.reject(new Error('Missing a set id field'))
      }
      const result = await this.db.find({
        selector: {
          set_id
        }
      })
      const deleteSet = result.docs.map((result) => {
        const deleteResult = {
          ...result,
          _deleted: true
        }
        return deleteResult
      })
      await this.db.bulkDocs(deleteSet)
    }

    return Promise.resolve([])
  }

  async cache(operation: string, payload: any, data: SetPiece[]) {
    if (
      operation === SetPieceOperations.BulkAdd ||
      operation === SetPieceOperations.GetBySetId
    ) {
      const set_piece_ids = data.map((sp) => sp.id.toString())
      const existingDocs = await this.db.allDocs({
        keys: set_piece_ids
      })

      const documents = data.map((set_piece) => {
        set_piece._id = set_piece.id.toString()
        const match = existingDocs.rows.find((row) => row.id === set_piece._id)
        if (match && !set_piece._rev) {
          set_piece._rev = match.value.rev
        }
        set_piece._rev = set_piece._rev || (1).toString()
        return set_piece
      })

      await this.db.bulkDocs(documents)
    }

    if (operation === SetPieceOperations.Get) {
      for (let setPiece of data) {
        const SetPiece_id = payload as number
        this.store.set(SetPiece_id, setPiece)
        const docs = await this.db
          .find({
            selector: {
              id: SetPiece_id
            }
          })
          .then((data) => data.docs)

        const [existing] = docs

        if (existing) {
          setPiece._id = existing._id
          setPiece._rev = existing._rev
        } else {
          setPiece._id = SetPiece_id.toString()
        }
        this.db.put(setPiece)
      }
    }
  }
}

class SetPieces extends OfflineService<SetPiece> {
  constructor() {
    super(new SetPieceResolver())
    this.name = 'SetPieceService'
    this.resolveWith((cache, live) => {
      if (live.length > 0) {
        const usedFromCache = new Set<number>()
        const cacheMap = this.listToMap(cache)
        const merged = live.map((x) => {
          const cacheEntry = cacheMap.get(x.id)
          if (cacheEntry) {
            usedFromCache.add(x.id)
            return Object.assign({}, cacheEntry, x)
          } else {
            return x
          }
        })

        const staleSetPieceIds = new Set<number>()
        for (let cacheValue of cacheMap.values()) {
          if (!usedFromCache.has(cacheValue.id)) {
            staleSetPieceIds.add(cacheValue.id)
          }
        }
        /** @todo Trigger a bulk delete to clean up the cache */

        return merged
      }
      return cache
    })

    this.registerSource(
      SetPieceOperations.GetBySetId,
      async (input: number) => {
        const [set] = await Api.getManyById(input.toString())
        const result = await Api.fetchInventory(set.set_number)
        const setPieces: SetPiece[] = []
        const setPiecesValues = result.setPieces.values()
        for (const setPiece of setPiecesValues) {
          setPieces.push(setPiece)
        }
        return setPieces
      }
    )

    this.registerSource(SetPieceOperations.Get, async (input: number) => {
      const result = await Api.getSetPiece(input)
      return [result]
    })

    this.registerSource(SetPieceOperations.GetMany, async (input: number[]) => {
      return Api.getManySetPieces(input)
    })
  }

  getByIds(setPieceIds: number[]) {
    return this.sendRequest(SetPieceOperations.GetMany, setPieceIds)
  }

  deleteBySetId(setId: number) {
    return this.sendRequest(SetPieceOperations.DeleteBySetId, setId)
  }

  private listToMap(list: SetPiece[]): Map<number, SetPiece> {
    return new Map(list.map((x) => [x.id, x]))
  }
}

const SetPieceService = new SetPieces()

export default SetPieceService
