import { Inspection } from '@/models/Inspection'
import { useInspectionsContext } from '@/providers/Inspections/hooks'
import { initializeInventory } from '@/services/initializeInventory'
import InspectionService from '@/services/inspections'
import SetPieceService from '@/services/set_pieces'
import {
  DeleteOutlined,
  PrinterOutlined,
  SyncOutlined
} from '@ant-design/icons'
import { Col, Dropdown, Menu, Row } from 'antd'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

const { Item } = Menu

const forceReloadInventory = async (
  evt: React.MouseEvent,
  inspection: Inspection
) => {
  evt.stopPropagation()
  evt.preventDefault()
  await InspectionService.delete(inspection._id)
  inspection._id = Date.now() + ':' + inspection.setNumber
  await InspectionService.addInspection(inspection)
  await SetPieceService.deleteBySetId(inspection.setId)
  await initializeInventory(inspection, true)
}

const renderSecondaryActions = (
  inspection: Inspection,
  extraItems?: React.ReactNode[]
) => {
  const inspectionsContext = useInspectionsContext()
  return (
    <Menu>
      <Item key={`inspection-delete-${inspection._id}`}>
        <a
          role="button"
          onClick={() => {
            inspectionsContext.delete(inspection._id)
          }}
        >
          <Row justify="space-between">
            <Col>Delete</Col>
            <Col>
              <DeleteOutlined />
            </Col>
          </Row>
        </a>
      </Item>
      <Item key={`inspection-print-${inspection._id}`}>
        <a
          role="button"
          onClick={(evt) => forceReloadInventory(evt, inspection)}
        >
          <Row justify="space-between">
            <Col style={{ paddingRight: '10px' }}>Reload Inventory</Col>
            <Col>
              <SyncOutlined />
            </Col>
          </Row>
        </a>
      </Item>
      {extraItems &&
        extraItems.map((node, index) => (
          <Item key={`inspection-context-extra-item-${index}`}>{node}</Item>
        ))}
    </Menu>
  )
}

export const InspectionContextMenu: React.FC<{
  inspection: Inspection
  extraContextItems?: React.ReactNode[]
}> = ({ inspection, extraContextItems }) => {
  const history = useHistory()
  const { statusQty, totalQty } = inspection
  const done = totalQty > 0 && statusQty >= totalQty
  return (
    <Dropdown.Button
      type={done ? 'primary' : undefined}
      className="darkDropdownButton"
      key={`inspection-menu-${inspection._id}`}
      onClick={() => history.push(`/print/inspection/${inspection._id}`)}
      overlay={renderSecondaryActions(inspection, extraContextItems)}
    >
      <PrinterOutlined /> Print
    </Dropdown.Button>
  )
}
