import { Collection } from '@/models/Collection'
import { useCollectionsContext } from '@/providers/Collections/hooks'
import { PlusSquareOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import Modal from 'antd/lib/modal/Modal'
import * as React from 'react'

interface Props {
  currentCollectionId?: string
  onCancel: () => void
  onSelect: (collectionId: string) => void
}

export const CollectionsModal: React.FC<Props> = ({
  onCancel,
  onSelect,
  currentCollectionId
}) => {
  const collectionsContext = useCollectionsContext()
  const [selectedCollectionId, setSelectedCollection] = React.useState<string>(
    currentCollectionId
  )
  const isInitialState = !collectionsContext.collections

  React.useEffect(() => {
    if (isInitialState) {
      collectionsContext.populate()
    }
  }, [isInitialState])

  return (
    <Modal
      closable
      visible
      okType={selectedCollectionId ? 'primary' : 'default'}
      confirmLoading={isInitialState}
      onCancel={onCancel}
      onOk={() => onSelect(selectedCollectionId)}
      title={
        <>
          <PlusSquareOutlined /> Add To Collection
        </>
      }
    >
      <Content style={{ maxHeight: 'calc(75vh - 170px)', overflow: 'auto' }}>
        {collectionsContext.collections && (
          <Menu selectable>
            {collectionsContext.collections
              .sort((a, b) => b.collection.createdOn - a.collection.createdOn)
              .map(({ collection }) => (
                <Menu.Item
                  onClick={() => setSelectedCollection(collection._id)}
                >
                  Sell Submission {collection.sellSubmissionId}
                </Menu.Item>
              ))}
          </Menu>
        )}
      </Content>
    </Modal>
  )
}
