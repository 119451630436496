import { usePageTitleEffect } from '@/components/usePageTitleEffect'
import { ExpandedCollection } from '@/providers/Collections/Context'
import { Button, Checkbox, PageHeader } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import { useHistory } from 'react-router'
import { InspectionsViewer } from '../Dashboard/InspectionsViewer'

interface Props {
  collectionRecord: ExpandedCollection
}

const ReportButton: React.FC<{ collectionId: string }> = ({ collectionId }) => {
  const history = useHistory()
  const [computePrices, setComputePrices] = React.useState(false)
  return (
    <>
      <Checkbox
        onChange={(value) => {
          setComputePrices(value.target.checked)
        }}
      >
        <span title="This option may take multiple minutes to load.">
          Compute Prices
        </span>
      </Checkbox>
      <Button
        type="primary"
        onClick={() =>
          history.push(
            `/collection/view/${collectionId}/report?include_prices=${computePrices}`
          )
        }
      >
        Seller Report
      </Button>
    </>
  )
}

export const DefaultCollectionPage: React.FC<Props> = ({
  collectionRecord
}) => {
  const history = useHistory()
  const { collection } = collectionRecord
  usePageTitleEffect(`Sell Submission ${collection.sellSubmissionId}`)
  return (
    <>
      <Content>
        <PageHeader
          onBack={() =>
            history.length > 0 ? history.goBack() : history.push('/dashboard')
          }
          title={`Sell Submission ${collection.sellSubmissionId}`}
          extra={<ReportButton collectionId={collection._id} />}
        />
      </Content>
      <InspectionsViewer collectionId={collection._id} />
    </>
  )
}
