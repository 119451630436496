import { Inspection, SetRecord } from '@/models'
import { Card } from 'antd'
import Meta from 'antd/lib/card/Meta'
import * as React from 'react'
import { usePreview } from 'react-dnd-preview'
import { InspectionDropItem } from './CollectionCard'
import { SetImage } from './SetImage'

interface Props {
  inspection: Inspection
  set: SetRecord
}

export const InspectionCardGhost: React.FC<Props> = ({
  children,
  inspection,
  set
}) => {
  const props = usePreview<InspectionDropItem, HTMLDivElement>()
  if (!props.display || props.item?.inspection?._id !== inspection._id) {
    return null
  }

  const { style } = props
  return (
    <div
      ref={props.ref}
      style={style}
      key={`inspection-card-ghost-${inspection._id}`}
    >
      <Card>
        <Meta
          avatar={<SetImage setId={inspection.setId} />}
          title={`${set.set_number}: ${set.name}`}
          description={children}
        ></Meta>
      </Card>
    </div>
  )
}
