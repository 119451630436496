import * as React from 'react'
import { SetRecord } from '@/models/SetRecord'
import { useSetImage } from '@/providers/SharedDataStore/hooks'

export interface SetImageProps extends SetRecord {
  size?: number
}

export const SetImageAvatar: React.FC<SetImageProps> = ({ id, size }) => {
  const setImage = useSetImage(id)
  return <img style={{ maxWidth: size, maxHeight: size }} src={setImage?.url} />
}
