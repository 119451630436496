import { Inspection } from '@/models/Inspection'
import { InspectionsDb } from '@/services/inspections'
import { LoadingOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import * as React from 'react'
import { SetImage } from './SetImage'

interface Props {
  collectionId: string
  updateToken?: any
}

export const CollectionSetsPreview: React.FC<Props> = ({
  collectionId,
  updateToken
}) => {
  const [inspections, setInspections] = React.useState<Inspection[]>(null)
  React.useMemo(async () => {
    const inspections = await InspectionsDb.find({
      selector: {
        collectionId
      },
      limit: 4
    })
    setInspections(inspections.docs)
  }, [collectionId, updateToken])

  if (!inspections) {
    return <LoadingOutlined />
  }
  return (
    <div style={{ minHeight: '40px' }}>
      <Row gutter={4} justify="space-around">
        {inspections.length === 0 && <div>No Inspections</div>}
        {inspections.map((inspection) => {
          return (
            <SetImage
              key={`set-preview-${inspection._id}`}
              setId={inspection.setId}
            />
          )
        })}
      </Row>
    </div>
  )
}
