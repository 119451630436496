import { SetImage } from '@/models/SetImage'
import SetImageService from '@/services/set_images'
import { ShareDataSourceRecord, SupportedDataTypes } from '../Context'
import { Reducer } from '../reducers'

type SetImageDataRecord = ShareDataSourceRecord<SetImage, number>

function isSetImage(
  record: ShareDataSourceRecord<any>
): record is SetImageDataRecord {
  return record.dependencySignature.type === SupportedDataTypes.SetImage
}

export const SetImageReducer: Reducer<SetImage, number> = (record) => {
  return {
    handled: isSetImage(record),
    resolve: (signature) => {
      return SetImageService.getBySetId(signature.identifier).then(([x]) => x)
    }
  }
}
