import { Collection } from '@/models/Collection'
import { useCollectionsContext } from '@/providers/Collections/hooks'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Modal } from 'antd'
import * as React from 'react'
import { AddCollectionForm } from './AddCollectionForm'

export const AddCollectionCard: React.FC = () => {
  const collectionsContext = useCollectionsContext()
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [form] = Form.useForm<
    Omit<Collection, '_id' | 'archivedOn' | 'createdOn'>
  >()

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields()
        collectionsContext.create(values)
        setIsModalVisible(false)
      })
      .catch(() => {})
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  return (
    <>
      <Button
        type="dashed"
        style={{ width: '100%', height: '198px' }}
        icon={<PlusOutlined title="Create Collection" />}
        onClick={showModal}
      >
        Create Collection
      </Button>
      <Modal
        title="Create Collection"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <AddCollectionForm form={form} />
      </Modal>
    </>
  )
}
