import {
  MissingPieceListItem,
  setPieceTypeToBricklinkCatalogType
} from '@/components/Inspection/MissingPieceListItem'
import { useIncludePricesRouteParam } from '@/hooks/useIncludePrices'
import { InspectionInventoryItem } from '@/loaders/InspectionInventoryLoader'
import { useColor, usePiece } from '@/providers/SharedDataStore/hooks'
import {
  DollarCircleOutlined,
  ExclamationCircleFilled,
  LoadingOutlined
} from '@ant-design/icons'
import { List, Popover } from 'antd'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { LoadedInventorySetPieceWithPriceGuides } from './ReportDataLoader'

interface Props {
  inventorySetPiece: LoadedInventorySetPieceWithPriceGuides
  customerViewEnabled: boolean
}

export const InventorySetPieceCard: React.FC<Props> = ({
  inventorySetPiece,
  customerViewEnabled
}) => {
  const includePriceGuides = useIncludePricesRouteParam()
  const { color_id, piece_id } = inventorySetPiece.SetPiece
  const color = useColor(color_id)
  const piece = usePiece(piece_id)
  const item: InspectionInventoryItem = {
    inventory: inventorySetPiece.Inventory,
    setPiece: inventorySetPiece.SetPiece,
    color,
    piece,
    part: inventorySetPiece.part
  }
  if (!color || !piece) {
    return <LoadingOutlined />
  }

  if (!inventorySetPiece.inspection) {
    console.warn('Could not match back an inspection with missing part', item)
    return null
  }

  const bricklinkType = setPieceTypeToBricklinkCatalogType(item.setPiece.type)
  let bricklinkPage =
    'https://www.bricklink.com/v2/catalog/catalogitem.page' +
    `?${bricklinkType}=${item.part.code}`
  if (bricklinkType === 'P') {
    bricklinkPage += `&idColor=${color.bl_id}`
  }

  return (
    <MissingPieceListItem
      disableDefaultAction
      titleExtra={
        includePriceGuides &&
        (inventorySetPiece.guides ? (
          <Popover
            placement="right"
            content={
              <List itemLayout="horizontal" style={{ minWidth: '8rem' }}>
                <List.Item>
                  <List.Item.Meta
                    title="Min Price"
                    description={`$${inventorySetPiece.guides.min_price.toFixed(
                      2
                    )}`}
                  />
                </List.Item>
                <List.Item>
                  <List.Item.Meta
                    title="Avg. Price"
                    description={`$${inventorySetPiece.guides.avg_price.toFixed(
                      2
                    )}`}
                  />
                </List.Item>
                <List.Item>
                  <List.Item.Meta
                    title="Qty. Avg Price"
                    description={`$${inventorySetPiece.guides.avg_price.toFixed(
                      2
                    )}`}
                  />
                </List.Item>
                <List.Item>
                  <List.Item.Meta
                    title="Max Price"
                    description={`$${inventorySetPiece.guides.max_price.toFixed(
                      2
                    )}`}
                  />
                </List.Item>
              </List>
            }
          >
            <DollarCircleOutlined
              className="printHide"
              style={{ color: 'green' }}
            />
          </Popover>
        ) : (
          <Popover content={'No Price Guide Available'}>
            <ExclamationCircleFilled
              className="printHide"
              style={{ color: 'red' }}
            />
          </Popover>
        ))
      }
      actions={
        !customerViewEnabled && [
          <Link
            to={`/inspections/${inventorySetPiece.inspection._id}`}
            onClick={(evt) => evt.stopPropagation()}
          >
            Open Inspection
          </Link>,
          <a href={bricklinkPage} target="_blank">
            Bricklink
          </a>
        ]
      }
      hideQR={customerViewEnabled}
      inspectionInventoryItem={item}
      setNumber={inventorySetPiece.inspection.setNumber}
    ></MissingPieceListItem>
  )
}
