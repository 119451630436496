import * as React from 'react'
import { debounce } from 'ts-debounce'
import { SetRecord } from '@/models/SetRecord'
import { default as SetService, SetOperations } from '@/services/sets'
import { default as Api } from '@/services/api'

import { Menu, Input, Dropdown, Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
const { Search } = Input

interface SearchState {
  inputString?: string
  searching: boolean
  searched: boolean
  matches: Array<SetRecord>
  highlightedMatch?: SetRecord
  autoCompleteVisible: boolean
  addingSet: boolean
}

export interface SetSearchProps {
  onSearch(setRecord: SetRecord): void
}

export class SetSearch extends React.Component<SetSearchProps, SearchState> {
  constructor(props: any) {
    super(props)
    this.state = {
      searching: false,
      searched: false,
      matches: [],
      autoCompleteVisible: false,
      addingSet: false
    }
    this.searchFor = debounce(this.searchFor, 300)
    this.searchComparison = (str) => str === this.state.inputString
    this.updateMatches = (data: SetRecord[]) => {
      this.setState({ matches: data })
    }
  }

  searchComparison: (input: string) => boolean
  updateMatches: (data: SetRecord[]) => void

  public handleChange(input: string): void {
    this.setState({
      inputString: input
    })

    if (input.length < 2) {
      return
    }

    this.searchFor(input)
  }

  public searchFor(input: string): void {
    if (this.state.searching) {
      // turn off the last listener
      SetService.off(
        SetOperations.Search,
        this.searchComparison,
        this.updateMatches
      )
    }

    this.setState({
      searching: true,
      autoCompleteVisible: true
    })
    SetService.on(
      SetOperations.Search,
      this.searchComparison,
      this.updateMatches
    )
    SetService.sendRequest(SetOperations.Search, input).then(() => {
      SetService.off(
        SetOperations.Search,
        this.searchComparison,
        this.updateMatches
      )
      this.setState({
        searching: false
      })
    })
  }

  public handleOnSearch(_: string) {
    let match
    if (this.state.matches.length === 1) {
      match = this.state.matches[0]
    }

    if (this.state.highlightedMatch) {
      match = this.state.highlightedMatch
    }

    if (match) {
      this.selectSetRecord(match)
    }
  }

  public selectSetRecord(match: SetRecord) {
    this.props.onSearch(match)
    this.setState({
      autoCompleteVisible: false,
      inputString: ''
    })
  }

  public highlightNextMatch() {
    if (!this.state.matches.length) return
    let match = this.state.matches[0]

    const currentIndex = this.state.matches.indexOf(this.state.highlightedMatch)
    const nextIndex = currentIndex + 1
    if (currentIndex > -1 && nextIndex < this.state.matches.length) {
      match = this.state.matches[nextIndex]
    }

    this.setState({
      highlightedMatch: match
    })
  }

  public highlightPreviousMatch() {
    if (!this.state.matches.length) return
    let match = this.state.matches[0]

    const currentIndex = this.state.matches.indexOf(this.state.highlightedMatch)
    const prevIndex = currentIndex - 1
    if (currentIndex > 0 && prevIndex < this.state.matches.length) {
      match = this.state.matches[prevIndex]
    }
    if (currentIndex === 0) {
      match = this.state.matches[this.state.matches.length - 1]
    }

    this.setState({
      highlightedMatch: match
    })
  }

  public checkForNav(evt: React.KeyboardEvent<HTMLInputElement>) {
    if (evt.which === 40) {
      this.highlightNextMatch()
    }

    if (evt.which === 38) {
      this.highlightPreviousMatch()
    }
  }

  public attemptToAdd(setNumberString: string) {
    if (this.state.addingSet) {
      return
    }
    this.setState({
      addingSet: true,
      searching: false
    })
    let stateUpdate = {
      addingSet: false
    } as any
    Api.tryGetSet(setNumberString)
      .then((set) => {
        if (set) {
          stateUpdate.searching = true
          this.searchFor(setNumberString)
        }
      })
      .catch((e) => e)
      .then(() => {
        this.setState(stateUpdate)
      })
  }

  public inputSetNumber(): string | null {
    if (this.state.inputString) {
      const pattern = /([0-9]{2,})(\-[0-9]{1,2})?/
      var match = this.state.inputString.match(pattern)
      if (match) {
        let setNumber = match[0]
        if (!match[2]) {
          setNumber += '-1'
        }
        return setNumber
      }
    }
    return null
  }

  get dropdown() {
    const setNumberString = this.inputSetNumber()
    return (
      <Menu>
        {!this.state.matches.length && (
          <Menu.Item key="no-items">
            <h4>No Results</h4>
            {setNumberString && (
              <Button
                onClick={() => this.attemptToAdd(setNumberString)}
                icon={<DownloadOutlined />}
                loading={this.state.addingSet}
              >
                Try to add "{setNumberString}."
              </Button>
            )}
          </Menu.Item>
        )}
        {this.state.matches.map((record) => {
          return (
            <Menu.Item
              key={record.id}
              onClick={() => this.selectSetRecord(record)}
            >
              {record.set_number} {record.name}
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  render() {
    return (
      <Dropdown
        overlay={this.dropdown}
        trigger={['click']}
        visible={this.state.autoCompleteVisible}
      >
        <Search
          value={this.state.inputString}
          placeholder="Enter a set or name"
          onChange={(e) => this.handleChange(e.currentTarget.value)}
          onSearch={(inputText) => this.handleOnSearch(inputText)}
          onKeyDown={(e) => this.checkForNav(e)}
          onBlur={() =>
            setTimeout(() => this.setState({ autoCompleteVisible: false }), 300)
          }
        />
      </Dropdown>
    )
  }
}
