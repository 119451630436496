import * as React from 'react'
import { SetPiece } from '@/models'
import { Menu } from 'antd'
const { Item, SubMenu } = Menu

interface MissingSetPieceMenuProps {
  SetPiece: SetPiece
  onClick(missingCount: number): void
}

interface MissingSubMenu {
  label: string
  options: number[]
}

export const MissingSetPieceMenu = (props: MissingSetPieceMenuProps) => {
  let missingOptions = []
  let maxOption = props.SetPiece.quantity
  for (let i = 0; i < maxOption; i++) {
    missingOptions.push(i + 1)
  }
  let groups = missingOptions.slice(10).reduce((groups, option) => {
    let optionString = option.toString(10)
    let groupIndex = optionString.substr(0, optionString.length - 1) + 'x'
    let foundGroup = groups.find((group) => group.label === groupIndex)
    if (!foundGroup) {
      groups.push({
        label: groupIndex,
        options: [option]
      })
    } else {
      foundGroup.options.push(option)
    }
    return groups
  }, [] as MissingSubMenu[])

  let flatOptions = missingOptions.slice(0, 10)

  return (
    <Menu>
      {flatOptions.map((i) => {
        return (
          <Item
            key={`${props.SetPiece.id}-missing-${i}`}
            onClick={() => props.onClick(i)}
          >
            {i}
          </Item>
        )
      })}
      {groups.map((group) => {
        return (
          <SubMenu
            key={`${props.SetPiece.id}-missing-${group.label}`}
            title={group.label}
          >
            {group.options.map((i) => {
              return (
                <Item
                  key={`${props.SetPiece.id}-missing-${i}`}
                  onClick={() => props.onClick(i)}
                >
                  {i}
                </Item>
              )
            })}
          </SubMenu>
        )
      })}
    </Menu>
  )
}
