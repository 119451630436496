import * as React from 'react'
import { List as VList, AutoSizer, ScrollSync } from 'react-virtualized'
import {
  InspectionInventory,
  InspectionInventoryStatus
} from '@/models/InspectionInventory'
import { InventoryListItem } from './InventoryListItem'
import { InventoryListItemSkeleton } from './InventoryListItemSkeleton'
import { InspectionInventoryLoader } from '@/loaders/InspectionInventoryLoader'
import { Empty } from 'antd'
import {
  BarsOutlined,
  CheckSquareTwoTone,
  SmileTwoTone
} from '@ant-design/icons'

export interface InventoryListProps {
  inventoryListIndex: InspectionInventoryStatus
  inventory: InspectionInventory[]
}

function renderListItem(
  inventory: InspectionInventory[],
  index: number,
  style: React.CSSProperties,
  width: number
) {
  const inventoryItem = inventory[index]
  const nextInventoryItem = inventory[index + 1]
  const rowStyle = Object.assign({}, style, { height: '95px' })
  return (
    <div key={inventoryItem._id} style={rowStyle}>
      <InspectionInventoryLoader
        inventory_item_id={inventoryItem._id}
        inventory={inventoryItem}
        loadingRenderer={InventoryListItemSkeleton}
      >
        {(item) => (
          <InventoryListItem
            {...item}
            nextInspectionItemId={nextInventoryItem && nextInventoryItem._id}
          />
        )}
      </InspectionInventoryLoader>
    </div>
  )
}

function getHeight(
  inventory: InspectionInventory[],
  index: number,
  width: number
) {
  const inventoryItem = inventory[index]
  return 95
}

export function InventoryList(props: InventoryListProps) {
  if (!props.inventory.length) {
    console.log(props.inventoryListIndex)
    switch (props.inventoryListIndex) {
      case InspectionInventoryStatus.Unknown:
        return (
          <Empty
            style={{ marginTop: '2em' }}
            image={
              <CheckSquareTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: '96px' }}
              />
            }
            description="All Done!"
          />
        )
      case InspectionInventoryStatus.Incomplete:
        return (
          <Empty
            style={{ marginTop: '2em' }}
            image={<SmileTwoTone style={{ fontSize: '96px' }} />}
            description="No Missing Pieces!"
          />
        )
      default:
        return (
          <Empty
            style={{ marginTop: '2em' }}
            image={<BarsOutlined style={{ fontSize: '96px' }} />}
            description="No Pieces"
          />
        )
    }
  }
  return (
    <AutoSizer>
      {({ width, height }) => {
        return (
          <ScrollSync>
            {({ onScroll, scrollTop }) => (
              <VList
                overscanRowCount={2}
                onScroll={onScroll}
                scrollTop={scrollTop}
                rowCount={props.inventory.length}
                height={height}
                width={width}
                rowHeight={({ index }) =>
                  getHeight(props.inventory, index, width)
                }
                rowRenderer={({ index, style }) =>
                  renderListItem(props.inventory, index, style, width)
                }
              />
            )}
          </ScrollSync>
        )
      }}
    </AutoSizer>
  )
}
