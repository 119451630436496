import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { FullPage } from '@/components/layouts/FullPage'
import { ExportsLoader } from '@/loaders/ExportLoader'
import { Col, Row } from 'antd'
import { ImportLoader } from '@/loaders/ImportLoader'

export default function ExportPage(_props: RouteComponentProps) {
  return (
    <FullPage>
      <div style={{ maxWidth: '1024px', margin: '2rem auto', padding: '1rem' }}>
        <Row gutter={32}>
          <Col span={11}>
            <ExportsLoader />
          </Col>
          <Col span={12} offset={1}>
            <ImportLoader />
          </Col>
        </Row>
      </div>
    </FullPage>
  )
}
