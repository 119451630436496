import * as React from 'react'
import { Col, Empty, Row, Tabs } from 'antd'
import { ExpandedInspection } from '@/providers/Inspections/Context'
import { InspectionCard } from '@/components/InspectionCard'
import InspectionService from '@/services/inspections'
import { useInspectionsContext } from '@/providers/Inspections/hooks'
import { BarsOutlined } from '@ant-design/icons'

export interface Props {
  collectionId: string
  inspections: ExpandedInspection[]
}

export const InspectionViewerTab: React.FC<Props> = ({
  collectionId,
  inspections
}) => {
  const selector = { collectionId: collectionId || '' }
  const context = useInspectionsContext()
  if (inspections.length === 0) {
    return (
      <Empty
        image={<BarsOutlined style={{ fontSize: '64px' }} />}
        description="No Inspections"
      />
    )
  }
  return (
    <Row gutter={16}>
      {inspections.map(({ set, inspection }) => {
        if (!set) {
          return
        }
        return (
          <Col
            span={24}
            md={8}
            lg={6}
            xl={6}
            xxl={4}
            key={`inspection-viewer-card-${inspection._id}`}
            style={{ marginBottom: '1rem' }}
          >
            <InspectionCard
              inspection={inspection}
              set={set}
              onCollectionDrop={async (_, droppedCollectionId) => {
                await InspectionService.updateWithCollection(
                  inspection,
                  droppedCollectionId
                )
                context.populate({
                  selector
                })
              }}
            >
              {set?.name}
            </InspectionCard>
          </Col>
        )
      })}
    </Row>
  )
}
