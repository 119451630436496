import * as React from 'react'
import { FullPage } from '@/components/layouts/FullPage'
import { InspectionsViewer } from './Dashboard/InspectionsViewer'
import { CollectionViewer } from './Dashboard/CollectionViewer'
import { Divider } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { usePageTitleEffect } from '@/components/usePageTitleEffect'

const DashboardPage: React.FC = () => {
  usePageTitleEffect('Dashboard')
  const screens = useBreakpoint()
  const limit = screens.xl ? 5 : screens.lg ? 3 : screens.md ? 2 : 1
  return (
    <FullPage>
      <CollectionViewer limit={limit} />
      <Divider />
      <InspectionsViewer />
    </FullPage>
  )
}

export default DashboardPage
