import {
  IDatabase,
  DatabaseOptions,
  CreateIndexOptions,
  SelectManyRequest,
  Document,
  Query,
  CreateIndexResponse,
  GetIndexesResponse,
  SelectManyResponse,
  QueryResult
} from '@/database/interface'

const primaryKey = '_id'

function unsafeUUIDv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const uuidv4 = (function () {
  const usedUUIDs: string[] = []
  return function () {
    let value = unsafeUUIDv4()
    while (usedUUIDs.indexOf(value) > -1) {
      value = unsafeUUIDv4()
    }
    usedUUIDs.push(value)
    return value
  }
})()

export const dbVersion = 20

export class Database<T> implements IDatabase<T> {
  private DBResolver: Promise<IDBDatabase>
  private storeName: string
  public constructor(tableName: string, options: DatabaseOptions<T>) {
    if (!window.indexedDB) {
      throw new Error('IndexedDB is not available in this browser')
    }
    this.storeName = `TPB_DB_${tableName}`
    this.DBResolver = new Promise((resolve, reject) => {
      const { storeName } = this
      const request = window.indexedDB.open(storeName, dbVersion)
      let upgrades = Promise.resolve()

      request.onerror = function () {
        reject(new Error('Could not open a database ' + storeName))
      }
      request.onsuccess = function () {
        upgrades.then(() => resolve(this.result))
      }
      request.onupgradeneeded = function ({ oldVersion }) {
        const db = this.result
        if (oldVersion < 3 || !db.objectStoreNames.contains(storeName)) {
          var objStore = db.createObjectStore(storeName, {
            keyPath: primaryKey
          })
          if (options.install) {
            options.install(objStore)
          }
        }
        if (options.onUpdateNeeded) {
          const asyncUpdates = options.onUpdateNeeded(
            { oldVersion },
            request.transaction.objectStore(storeName)
          )
          if (asyncUpdates instanceof Promise) {
            upgrades = asyncUpdates
          }
        }
      }
    })
  }

  public static AddNewDefaultIndexValue = async (
    store: IDBObjectStore,
    index: string,
    value: any
  ) => {
    return new Promise<void>((resolveTransaction, rejectTransaction) => {
      try {
        const queryRequest = store.getAll()
        queryRequest.onerror = rejectTransaction
        queryRequest.onsuccess = () => {
          const records = queryRequest.result
          const updates = records.map(
            (record) =>
              new Promise<void>((resolveUpdate, rejectUpdate) => {
                if (record[index] === undefined) {
                  record[index] = value
                }
                const updateRequest = store.put(record)
                updateRequest.onsuccess = () => resolveUpdate()
                updateRequest.onerror = rejectUpdate
              })
          )
          Promise.all(updates)
            .then(() => resolveTransaction())
            .catch(rejectTransaction)
        }
      } catch (e) {
        rejectTransaction(e)
      }
    })
  }

  public async getIndexes() {
    return new Promise<GetIndexesResponse>(async (resolve, reject) => {
      const store = await this.getStore(reject, 'versionchange')
      const indexes: string[] = []
      for (let i = 0; i < store.indexNames.length; i++) {
        indexes.push(store.indexNames.item(i))
      }
      resolve({
        indexes: indexes.map((i) => ({
          name: i,
          ddoc: null,
          type: '',
          def: {
            fields: []
          }
        }))
      })
    })
  }

  public async createIndex(options?: CreateIndexOptions) {
    return new Promise<CreateIndexResponse>(async (resolve, reject) => {
      const store = await this.getStore(reject)
      try {
        store.createIndex(options.index.name, options.index.fields)
        resolve({
          result: 'OK'
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  public allDocs(request: SelectManyRequest) {
    return new Promise<SelectManyResponse<T>>(async (resolve, reject) => {
      const store = await this.getStore(reject, 'readonly')
      const req = store.getAll(request.keys)
      req.onerror = function (ev) {
        reject(ev)
      }
      req.onsuccess = function () {
        resolve({
          rows: this.result.map((result) => ({
            id: result[primaryKey],
            value: result
          }))
        })
      }
    })
  }

  bulkDocs(documents: Document<T>[]) {
    return new Promise<Document<T>[]>(async (resolve, reject) => {
      const store = await this.getStore(reject, 'readwrite')
      const promises = documents.map((doc) => {
        return new Promise<Document<T>>((resolve, reject) => {
          if (doc._deleted) {
            if (!doc._id) {
              return
            }
            const request = store.delete(doc._id)
            request.onerror = function (error) {
              reject(error)
            }
            request.onsuccess = function () {
              resolve({
                ...doc
              })
            }
            return
          }

          if (!doc._id) {
            doc._id = uuidv4()
          }
          const request = store.put(doc)
          request.onerror = function (error) {
            reject(error)
          }
          request.onsuccess = function () {
            resolve({
              ...doc,
              _id: this.result
            })
          }
        })
      })
      resolve(await Promise.all(promises))
    })
  }

  find(query: Query) {
    return new Promise<QueryResult<T>>(async (resolve, reject) => {
      try {
        const $indexes = Object.keys(query.selector)
        const store = await this.getStore(reject)
        if ($indexes.length === 1) {
          const [$index] = $indexes
          const index = store.index($index)
          const request = index.openCursor(
            toCursorParam(query.selector[$index])
          )
          const docs: Document<T>[] = []
          request.onsuccess = function () {
            if (this.result) {
              let eligible = true
              if (query.selector[$index]?.['$in'] !== undefined) {
                eligible = query.selector[$index]['$in'].includes(
                  this.result.value[$index]
                )
              }
              if (query.selector[$index]?.['$regex'] !== undefined) {
                eligible = this.result.value[$index].match(
                  query.selector[$index]?.['$regex']
                )
              }
              if (eligible) {
                docs.push(this.result.value as Document<T>)
              }
              if (docs.length === query.limit) {
                resolve({
                  docs
                })
                return
              }
              this.result.continue()
            } else {
              resolve({
                docs
              })
            }
          }
          return
        }

        const getAllCountsPromises = $indexes.map((i) => {
          return new Promise<{
            name: string
            index: IDBIndex
            count: number
          }>((resolve, reject) => {
            const index = store.index(i)
            const req = index.count()
            req.onerror = function (error) {
              reject(error)
            }
            req.onsuccess = function () {
              resolve({
                name: i,
                index,
                count: this.result
              })
            }
          })
        })
        const withCounts = await Promise.all(getAllCountsPromises)
        const indexes = withCounts.sort((a, b) => a.count - b.count)

        // Iterate through the indexes.
        const temporaryTableNames: string[] = []
        while (indexes.length) {
          // Move out the first one
          let namedIndex = indexes.shift()
          let index = namedIndex.index
          let docs = await this.getIndexResults(
            index,
            toCursorParam(query.selector[index.name])
          )

          if (!indexes.length || docs.length === 0) {
            temporaryTableNames.map((name) => {
              const req = window.indexedDB.deleteDatabase(name)
              req.onblocked = function () {
                setTimeout(function () {
                  window.indexedDB.deleteDatabase(name)
                }, 10)
              }
            })
            return resolve({
              docs
            })
          }

          // clone table & continue
          const tempTableName = await this.cloneTableAndReplaceNextIndex(
            `temp_${this.storeName}_${namedIndex.name}`,
            docs,
            indexes[0]
          )
          temporaryTableNames.push(tempTableName)
        }
        reject(new Error('not implemented yet'))
      } catch (error) {
        reject(error)
      }
    })
  }

  put(document: Document<T>) {
    return new Promise<Document<T>>(async (resolve, reject) => {
      const store = await this.getStore(reject, 'readwrite')
      if (!document._id) {
        document._id = uuidv4()
      }
      const request = store.put(document)
      request.onerror = function (error) {
        reject(error)
      }
      request.onsuccess = function () {
        resolve({
          ...document,
          _id: this.result
        })
      }
    })
  }

  get(_id: string) {
    return new Promise<Document<T>>(async (resolve, reject) => {
      const store = await this.getStore(reject, 'readonly')
      const request = store.get(_id)
      request.onerror = function (error) {
        reject(error)
      }
      request.onsuccess = function () {
        resolve(this.result)
      }
    })
  }

  public remove(document: Document<T>): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const store = await this.getStore(reject)
      const req = store.delete(document._id)
      req.onerror = function (error) {
        reject(error)
      }
      req.onsuccess = function () {
        resolve()
      }
    })
  }

  private async cloneTableAndReplaceNextIndex(
    name: string,
    docs: Document<T>[],
    indexSymbol: {
      name: string
      index: IDBIndex
      count: number
    }
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const temp = this.createTempTable(name)
        const tempStore = await temp.resolver
        indexSymbol.index = tempStore.createIndex(
          indexSymbol.name,
          indexSymbol.index.keyPath,
          { unique: indexSymbol.index.unique }
        )
        const inserts = Promise.all(
          docs.map((data) => tempStore.add(data))
        ).catch(reject)
        resolve(inserts.then(() => temp.tempName))
      } catch (error) {
        reject(error)
      }
    })
  }

  private createTempTable(
    name: string
  ): {
    tempName: string
    resolver: Promise<IDBObjectStore>
  } {
    const tempName = `${name}_${uuidv4()}`
    const request = window.indexedDB.open(tempName, 1)
    return {
      tempName,
      resolver: new Promise((resolve, reject) => {
        request.onerror = function () {
          reject(new Error('Could not open a new temp database ' + tempName))
        }
        request.onsuccess = function () {
          reject(new Error('Table already temporarily created'))
        }
        request.onupgradeneeded = function () {
          const db = this.result
          var objStore = db.createObjectStore(name, { keyPath: primaryKey })
          resolve(objStore)
        }
      })
    }
  }

  private getIndexResults(
    index: IDBIndex,
    value: string | number | IDBKeyRange | Date | ArrayBufferView | ArrayBuffer
  ): Promise<Document<T>[]> {
    return new Promise((resolve, reject) => {
      const req = index.getAll(value)
      req.onsuccess = function () {
        resolve(this.result)
      }
      req.onerror = function (error) {
        reject(error)
      }
    })
  }

  private async getStore(
    onErrorCallaback: (ev: Event) => void,
    mode: IDBTransactionMode = 'readwrite'
  ) {
    const db = await this.DBResolver
    const tx = db.transaction(this.storeName, mode)
    db.onerror = function (event) {
      onErrorCallaback(event)
    }
    return tx.objectStore(this.storeName)
  }
}

function toCursorParam(value: any) {
  if (value?.['$gte'] !== undefined) {
    return IDBKeyRange.lowerBound(value['$gte'])
  }
  if (value?.['$in'] !== undefined) {
    let sorted: any[]
    if (typeof value['$in'][0] === 'number') {
      sorted = value['$in'].sort(function (a: number, b: number) {
        return a - b
      })
    } else {
      sorted = value['$in'].sort()
    }
    return IDBKeyRange.bound(sorted[0], sorted.slice(-1)[0])
  }
  if (value?.['$regex'] !== undefined) {
    return undefined
  }
  return value
}
