import * as React from 'react'
import { Loader } from '@/loaders/Loader'
import { InspectionInventory, Piece, Part, Color, SetPiece } from '@/models'
import { PieceImage } from '@/models/PieceImage'
import SetPieceService, { SetPieceOperations } from '@/services/set_pieces'
import PieceService, { PieceOperations } from '@/services/pieces'
import ColorService, { ColorOperations } from '@/services/color'
import PartService, { PartOperations } from '@/services/parts'

export interface SetPieceItem {
  setPiece: SetPiece
  piece: Piece
  part: Part
  color: Color
}

interface Props {
  set_piece_id: number
  setPiece?: SetPiece
  loadingRenderer?: React.ComponentType
  children(setPieceItem: SetPieceItem): React.ReactNode
}

interface State {
  setPiece?: SetPiece
  piece?: Piece
  part?: Part
  color?: Color
  pieceImage?: PieceImage
}

export class SetPieceLoader extends React.Component<Props, State> {
  public state: State = {
    setPiece: this.props.setPiece
  }

  public render() {
    const isValid = this.isValidSetPieceItem()
    return (
      <Loader loading={!isValid} loadingRenderer={this.props.loadingRenderer}>
        {isValid ? this.props.children(this.emitSetPieceItem()) : null}
      </Loader>
    )
  }

  public componentDidMount = () => {
    SetPieceService.on(
      SetPieceOperations.Get,
      this.ifSetPiece,
      this.updateSetPiece
    )
    PieceService.on(PieceOperations.Get, this.ifPiece, this.updatePiece)
    PartService.on(PartOperations.Get, this.ifPart, this.updatePart)
    ColorService.on(ColorOperations.Get, this.ifColor, this.updateColor)
    this.fetchUpdatedSetPiece(this.props.set_piece_id)
  }

  public componentDidUpdate = (nextProps: Props) => {
    if (nextProps.set_piece_id !== this.props.set_piece_id) {
      this.setState({
        setPiece: null,
        piece: null,
        part: null,
        color: null
      })
      this.fetchUpdatedSetPiece(nextProps.set_piece_id)
    }
  }

  public componentWillUnmount = () => {
    SetPieceService.off(
      SetPieceOperations.Get,
      this.ifSetPiece,
      this.updateSetPiece
    )
    PieceService.off(PieceOperations.Get, this.ifPiece, this.updatePiece)
    PartService.off(PartOperations.Get, this.ifPart, this.updatePart)
    ColorService.off(ColorOperations.Get, this.ifColor, this.updateColor)
  }

  private fetchUpdatedSetPiece = (id: number) => {
    if (this.state.setPiece && this.state.setPiece.id !== id) {
      !this.state.part &&
        PartService.sendRequest(PartOperations.Get, this.state.setPiece.part_id)
      !this.state.piece &&
        PieceService.sendRequest(
          PieceOperations.Get,
          this.state.setPiece.piece_id
        )
      !this.state.color &&
        ColorService.sendRequest(
          ColorOperations.Get,
          this.state.setPiece.color_id
        )
    }
    SetPieceService.sendRequest(SetPieceOperations.Get, id)
  }

  private emitSetPieceItem = () => {
    if (!this.isValidSetPieceItem()) {
      throw new Error('Cannot emit an invalid set piece item')
    }
    const { setPiece, piece, part, color } = this.state

    const out: SetPieceItem = {
      setPiece,
      piece,
      part,
      color
    }
    return out
  }

  private isValidSetPieceItem = () => {
    return !!(
      this.state.setPiece &&
      this.state.piece &&
      this.state.part &&
      this.state.color
    )
  }

  private ifSetPiece = (payload: any) =>
    this.props.set_piece_id === (payload as number)
  private updateSetPiece = (data: SetPiece[]) => {
    const [setPiece] = data
    this.setState(
      (state) => {
        const partChanged =
          this.state.part && setPiece.part_id !== this.state.part.id
        const pieceChanged =
          this.state.part && setPiece.piece_id !== this.state.piece.id
        const colorChanged =
          this.state.part && setPiece.color_id !== this.state.color.id
        return {
          setPiece,
          part: partChanged ? null : state.part,
          piece: pieceChanged ? null : state.piece,
          color: colorChanged ? null : state.color,
          pieceImage: null
        }
      },
      () => {
        !this.state.part &&
          PartService.sendRequest(PartOperations.Get, setPiece.part_id)
        !this.state.piece &&
          PieceService.sendRequest(PieceOperations.Get, setPiece.piece_id)
        !this.state.color &&
          ColorService.sendRequest(ColorOperations.Get, setPiece.color_id)
      }
    )
  }

  private ifPiece = (payload: any) =>
    this.state.setPiece && this.state.setPiece.piece_id === (payload as number)
  private updatePiece = (data: Piece[]) => {
    const [piece] = data
    this.setState({
      piece
    })
  }

  private ifPart = (payload: any) =>
    this.state.setPiece && this.state.setPiece.part_id === (payload as number)
  private updatePart = (data: Part[]) => {
    const [part] = data
    this.setState({
      part
    })
  }

  private ifColor = (payload: any) =>
    this.state.setPiece && this.state.setPiece.color_id === (payload as number)
  private updateColor = (data: Color[]) => {
    const [color] = data
    this.setState({
      color
    })
  }
}
