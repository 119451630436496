import * as React from 'react'
import { Skeleton } from 'antd'

export function InventoryListItemSkeleton() {
  return (
    <div className="inventoryItemRow">
      <div
        role="gridcell"
        style={{ width: '75px', textAlign: 'center', flexShrink: 0 }}
      >
        <Skeleton
          avatar={{ shape: 'square', size: 'large' }}
          paragraph={false}
          title={false}
        />
      </div>
      <div style={{ width: '75px', flexShrink: 0 }} />
      <div
        role="gridcell"
        style={{
          width: 'auto',
          flexShrink: 0,
          flexGrow: 1,
          paddingRight: '25px'
        }}
      >
        <Skeleton title={false} paragraph={{ rows: 2 }} />
      </div>
    </div>
  )
}
